import * as Yup from 'yup'

export const validationSchema = Yup.object({
  package: Yup.number().required('Required'),
  numUsersAllowed: Yup.number(),
  discount: Yup.number().max(100, 'Discount must be less than 100%'),
  startDate: Yup.date(),
  trial: Yup.boolean(),
  trialEndDate: Yup.date(),
  notes: Yup.string().max(255, 'Must be 255 characters or less')
})

export const initialFormData = {
  package: '',
  numUsersAllowed: '',
  discount: '',
  startDate: new Date(),
  trial: false,
  trialEndDate: new Date(),
  notes: ''
}
