import databaseQueryInterface from '../../database/databaseQueryInterface'
import settings from '../../settings'
import { extractErrorData } from '../helpers'

export async function addNewStatus(statusData, user, openDialog) {
  try {
    const data = await databaseQueryInterface.post(`${settings.baseURL}/api/user-statuses`, user, {
      ...statusData
    })

    // Format status in the way a material ui autocomplete component requires it
    const newStatus = { name: data.name, value: data.id }

    return { success: true, newStatus }
  } catch (err) {
    const data = extractErrorData(err)
    openDialog({
      failedAction: `save new User Status`,
      data
    })
  }
}
