import React, { useEffect, useContext } from 'react'
import { Formik } from 'formik'

import { withStyles, Button, Grid } from '@material-ui/core'

import { validationSchema, initialFormData } from './formSetup'

import { formStyles } from '../../../styles'
import { SingleLineTextField, MultiLineTextField, MultiSelectField } from '../../blocks'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { extractErrorData, formatDataForAutocomplete } from '../../helpers'
import { mapCurrentValuesToForm } from '../../lib/package'

const styles = theme => ({ ...formStyles(theme) })

const PackageForm = ({ classes, user, handleSubmit, currentValues }) => {
  const [allProducts, setAllProducts] = React.useState(null)
  const [initialValues, setInitialValues] = React.useState(initialFormData)

  const { openDialog } = useContext(FunctionContext)

  // Effect to load the list of institutions to populate parent company field
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    // useEffect can only return a function (not an async function),
    // So, we create an async function and call it immediately to avoid errors
    async function fetchProductsList() {
      try {
        const data = await databaseQueryInterface.get(`${settings.baseURL}/api/products`, user)

        const productsList = formatDataForAutocomplete(data)

        // TODO: Check with thanuri on whether this is needed
        // If a package already has products that are deleted, then add those deleted items to the allProducts Array
        // This is so that it's clear those are there, but we cannot add or remove them, since the options are disabled.
        if (currentValues) {
          const deletedProducts = currentValues.products.reduce((formattedProduct, product) => {
            if (product.deletedAt !== null) formattedProduct.push(formatDataForAutocomplete([product])[0])

            return formattedProduct
          }, [])

          if (deletedProducts.length > 0) productsList.push(...deletedProducts)
        }

        if (isMounted) setAllProducts(productsList)
      } catch (err) {
        const data = extractErrorData(err)
        openDialog({
          failedAction: `retrieve list of Institutions for Parent Company field`,
          data
        })
      }
    }

    fetchProductsList()

    return () => {
      isMounted = false
    }
  }, [currentValues, openDialog, user])

  // Effect to update the form with the new values during an edit action
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updateProductData() {
      if (currentValues) {
        const products = formatDataForAutocomplete(currentValues.products)

        const currentData = mapCurrentValuesToForm(currentValues, products)

        if (isMounted) setInitialValues(currentData)
      }
    }

    updateProductData()

    return () => {
      isMounted = false
    }
  }, [currentValues])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
      validationSchema={validationSchema}
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <SingleLineTextField
                fullWidth
                name="name"
                label="Name"
                value={formik.values.name}
                handleChange={formik.handleChange}
                touched={formik.touched.name}
                errors={formik.errors.name}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SingleLineTextField
                fullWidth
                name="price"
                label="Price"
                value={formik.values.price}
                handleChange={formik.handleChange}
                touched={formik.touched.price}
                errors={formik.errors.price}
              />
            </Grid>

            <Grid item xs={12}>
              <MultiSelectField
                name="products"
                fullWidth
                label="Products"
                value={formik.values.products}
                handleChange={selectedOption => {
                  formik.setFieldValue('products', selectedOption)
                }}
                touched={formik.touched.products}
                errors={formik.errors.products}
                items={allProducts || []}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MultiLineTextField
                fullWidth
                name="description"
                label="Description"
                value={formik.values.description}
                handleChange={formik.handleChange}
                touched={formik.touched.description}
                errors={formik.errors.description}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MultiLineTextField
                fullWidth
                name="notes"
                label="Notes"
                value={formik.values.notes}
                handleChange={formik.handleChange}
                touched={formik.touched.notes}
                errors={formik.errors.notes}
              />
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default withStyles(styles)(PackageForm)
