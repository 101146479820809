import React, { useEffect, useState, useContext } from 'react'

import { Container, Grid, makeStyles } from '@material-ui/core'

import { SelectField, AdminButtons } from '../../blocks'
import { FormDialog, ConfirmationDialog } from '../../Dialogs'
import { ProductCategoryForm } from '../../forms'

import { FunctionContext } from '../../contexts'

import { extractErrorData, formatDataForAutocomplete, getDataFromId, addOrEditData, deleteData } from '../../helpers'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { commonStyles, formStyles } from '../../../styles'
import { ShowSkeleton } from '../../utilities'

const useStyles = makeStyles(theme => ({ ...commonStyles(theme), ...formStyles(theme) }))

const CategorySelect = ({
  user,
  userRole,
  selectedCategory,
  handleCategorySelect,
  handleSubcategorySelect,
  showRemoved
}) => {
  const classes = useStyles()
  const { openDialog, openSnackbar } = useContext(FunctionContext)

  const [categoryData, setCategoryData] = useState(null) // Full category data
  const [categorySelectData, setCategorySelectData] = useState(null) // Data for the select component
  const [openForm, setOpenForm] = useState(false) // Boolean to open the add/edit form
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false) // Boolean to open dialog to confirm removing category
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false) // Boolean to open dialog to confirm removing category

  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    // useEffect can only return a function (not an async function),
    // So, we create an async function and call it immediately to avoid errors
    async function fetchCategoriesList() {
      try {
        const getRemoved = showRemoved ? '?showRemoved=true' : ''
        const data = await databaseQueryInterface.get(`${settings.baseURL}/api/product-categories/${getRemoved}`, user)

        if (isMounted) setCategoryData(data)
      } catch (err) {
        const data = extractErrorData(err)
        openDialog({
          failedAction: `retrieve list of Product Categories`,
          data
        })
      }
    }

    fetchCategoriesList()

    return () => {
      isMounted = false
    }
  }, [openDialog, showRemoved, user])

  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updateSelectList() {
      if (isMounted && categoryData) setCategorySelectData(formatDataForAutocomplete(categoryData))
    }

    updateSelectList()

    return () => {
      isMounted = false
    }
  }, [categoryData])

  const handleCategoryFormSubmit = async formData => {
    try {
      const data = await databaseQueryInterface.post(`${settings.baseURL}/api/product-categories`, user, {
        ...formData
      })

      // Close form and add new data to current arrays
      setOpenForm(false)
      setCategoryData(addOrEditData(data, categoryData))

      openSnackbar(`Created Product Category: ${data.name}`)
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({
        failedAction: `create Category`,
        data
      })
    }
  }

  const handleCategoryFormEditSubmit = async (formData, categoryId) => {
    try {
      const data = await databaseQueryInterface.put(`${settings.baseURL}/api/product-categories/${categoryId}`, user, {
        ...formData
      })

      // Close form and add new data to current arrays
      setOpenForm(false)
      setCategoryData(addOrEditData(data, categoryData))

      openSnackbar(`Edited Product Category: ${data.name}`)
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `edit Category`, data })
    }
  }

  const handleRemove = async categoryId => {
    try {
      const { productCategory, subcategoriesToRemove } = await databaseQueryInterface.put(
        `${settings.baseURL}/api/product-categories/${categoryId}/remove`,
        user
      )

      for (const subcategory of subcategoriesToRemove) {
        const { productsToRemove } = await databaseQueryInterface.put(
          `${settings.baseURL}/api/product-subcategories/${subcategory.id}/remove`,
          user
        )

        for (const product of productsToRemove) {
          await databaseQueryInterface.put(`${settings.baseURL}/api/products/${product.id}/remove`, user)
        }
      }

      // Close form and add new data to current arrays
      setOpenRemoveDialog(false)
      setCategoryData(addOrEditData(productCategory, categoryData))
      handleCategorySelect(null)
      handleSubcategorySelect(null)

      openSnackbar(
        `${productCategory.deletedAt === null ? 'Restored' : 'Removed'} Product Category: ${productCategory.name}`
      )
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `${data.deletedAt === null ? 'remove' : 'restore'} Category`, data })
    }
  }

  const handleDelete = async categoryId => {
    try {
      const data = await databaseQueryInterface.destroy(
        `${settings.baseURL}/api/product-categories/${categoryId}`,
        user
      )

      // Close form and add new data to current arrays
      setOpenDeleteDialog(false)
      setCategoryData(deleteData(data, categoryData))
      handleCategorySelect(null)
      handleSubcategorySelect(null)

      openSnackbar(`Deleted Product Category: ${data.name}`)
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `delete Category`, data })
    }
  }

  return (
    <Container>
      <ShowSkeleton variant="rect" height={500} condition={!categorySelectData}>
        <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
          <Grid item xs={6}>
            <SelectField
              name="categorySelect"
              label="Category"
              value={(selectedCategory && selectedCategory.id) || ''}
              handleChange={selectedOption => handleCategorySelect(getDataFromId(selectedOption, categoryData))}
              items={categorySelectData}
            />
          </Grid>

          <Grid item xs={6}>
            <AdminButtons
              userRole={userRole}
              classes={classes}
              item={selectedCategory}
              handleAddClick={() => {
                setOpenForm(true)
                handleCategorySelect(null)
              }}
            />

            {selectedCategory && (
              <AdminButtons
                userRole={userRole}
                classes={classes}
                item={selectedCategory}
                handleEditClick={() => setOpenForm(true)}
                handleRemoveClick={() => setOpenRemoveDialog(true)}
                handleDeleteClick={() => setOpenDeleteDialog(true)}
              />
            )}
          </Grid>
        </Grid>
      </ShowSkeleton>

      <FormDialog
        open={openForm}
        handleClose={() => setOpenForm(false)}
        classes={classes}
        title="Add a new Product Category"
        bodyText="Enter a name for this new Product Category"
      >
        <ProductCategoryForm
          handleCancel={() => setOpenForm(false)}
          handleSubmit={handleCategoryFormSubmit}
          handleEditSubmit={handleCategoryFormEditSubmit}
          currentValues={selectedCategory}
        />
      </FormDialog>

      {selectedCategory && (
        <>
          <ConfirmationDialog
            open={openRemoveDialog}
            closeDialog={() => setOpenRemoveDialog(false)}
            text={`Are you sure you want to ${selectedCategory.deletedAt === null ? 'remove' : 'restore'} "${
              selectedCategory.name
            }"?`}
            handleConfirm={() => handleRemove(selectedCategory.id)}
          />

          <ConfirmationDialog
            open={openDeleteDialog}
            closeDialog={() => setOpenDeleteDialog(false)}
            text={`Are you sure you want to delete "${selectedCategory.name}"?`}
            handleConfirm={() => handleDelete(selectedCategory.id)}
            extraSecurity
          />
        </>
      )}
    </Container>
  )
}

export default CategorySelect
