import React, { Component } from 'react'

import {
  Container,
  Chip,
  CircularProgress,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import { extractErrorData } from '../../helpers'
import { FunctionContext } from '../../contexts'
import settings from '../../../settings'

import { changelogStyles } from '../../../styles'
// import changelogsFromFile from '../../../changelogs's

const styles = theme => ({ ...changelogStyles(theme) })

const logsPerPage = 100
class Changelogs extends Component {
  static contextType = FunctionContext

  state = {
    changelogs: [],
    changelogsDisplayed: [],
    pageNumber: 0
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { pageNumber } = this.state

    try {
      const data = await databaseQueryInterface.get(
        `${settings.baseURL}/api/changelogs`,
        user
      )
      // console.log(data.length)

      this.setState({
        // sort according to date so that most recent logs are on top
        changelogs: data.sort((a, b) => new Date(b.date) - new Date(a.date))
        // changelogs: data.sort((a, b) => b.id - a.id)
      }, () => {
        this.setState({ changelogsDisplayed: data.slice(0, (pageNumber + 1) * logsPerPage) })
      }, () => openSnackbar(`Retrieved changelogs successfully.`))
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve changelogs`,
        data
      })
    }
  }

  // import = async () => {
  //   const { openDialog, openSnackbar } = this.context
  //   const { user } = this.props
  //   let count = 1

  //   try {
  //     // const logsFromBackend = await databaseQueryInterface.get(
  //     //   `${settings.baseURL}/api/migrate/changelogs`,
  //     //   user
  //     // )

  //     // console.log(logsFromBackend)

  //     for (const log of changelogsFromFile) {
  //       const data = await databaseQueryInterface.post(
  //         `${settings.baseURL}/api/changelogs`,
  //         user,
  //         {
  //           ...log
  //         }
  //       )
  //       console.log("Added log id" + data.id + " log " + count + " of " + changelogsFromFile.length)
  //       count++
  //     }
  //     //   console.log(data)
  //   } catch (err) {
  //     console.log(err)
  //     const data = extractErrorData(err)
  //     openDialog({
  //       failedAction: `migrate changelogs`,
  //       data
  //     })
  //   }
  // }

  // delete = async (id) => {
  //   const { user } = this.props

  //   for (let i = id; i > (id-139); i--) {
  //     try {
  //       const data = await databaseQueryInterface.destroy(
  //         `${settings.baseURL}/api/changelogs/${i}`,
  //         user
  //       )
  //       console.log((this.state.changelogs.length-139) + " " + data)
  //     } catch (error) {
  //       console.log(error)
  //     }

  //   }

  // }

  loadMore = () => {
    const { pageNumber } = this.state

    this.setState(
      { pageNumber: pageNumber + 1 },
      () => {
        const { pageNumber, changelogs } = this.state

        this.setState({ changelogsDisplayed: changelogs.slice(0, (pageNumber + 1) * logsPerPage) })
      }
    )
  }

  render() {
    const { classes } = this.props
    const { changelogsDisplayed } = this.state

    return (
      <Container className={classes.container} maxWidth="lg">
        {/* <Button color="primary" variant='contained' onClick={this.import}>Import changelogs</Button> */}
        {
          changelogsDisplayed.length < 1
            ? <CircularProgress size={20} thickness={5} />
            : (
              <>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {
                        changelogsDisplayed.map(({ id, date, user, description }) => (
                          <TableRow
                            key={id}
                          >
                            <TableCell style={{ display: "flex", justifyContent: "flex-end" }}>
                              {/* <Button onClick={() => this.delete(id)}>Delete</Button> */}
                              {
                                user &&
                                <Chip
                                  label={user}
                                  className={classes.chip}
                                />
                              }
                              {/* <Chip
                                label={id}
                                className={classes.chip}
                              /> */}
                              <Chip
                                label={new Date(date).toLocaleTimeString("en-GB", { hour12: true, hour: '2-digit', minute: '2-digit' })}
                                className={classes.chip}
                              />
                              <Chip
                                label={new Date(date).toLocaleDateString("en-GB", { year: 'numeric', month: 'short', day: 'numeric' })}
                                className={classes.chip}
                              />
                            </TableCell>

                            <TableCell>
                              <Typography>{`${description}`}</Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>

                <Button onClick={this.loadMore} variant="contained" color="primary" className={classes.button}>
                  Load More
                </Button>
              </>
            )
        }
      </Container>
    )
  }
}

export default withStyles(styles)(Changelogs)