import React from 'react'

import { Container, Button, withStyles, Typography } from '@material-ui/core'

import { ProductForm } from '../../forms'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { extractErrorData, algoliaDataUpdate } from '../../helpers'

import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

class EditProduct extends React.Component {
  static contextType = FunctionContext

  state = {
    productData: null
  }

  componentDidMount() {
    this.getProductData()
  }

  getProductData = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params }
    } = this.props

    try {
      const data = await databaseQueryInterface.get(`${settings.baseURL}/api/products/${params.id}`, user)

      this.setState({ productData: data }, () => openSnackbar(`Retrieved data for ${data.name}`))
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve user records`,
        data
      })
    }
  }

  handleSubmit = async formData => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params },
      history
    } = this.props
    try {
      const data = await databaseQueryInterface.put(`${settings.baseURL}/api/products/${params.id}`, user, {
        ...formData
      })

      const { id, name } = data
      
      algoliaDataUpdate({
        objectID: id,
        name,
        type: 'products',
        index: 'products',
        operation: 'edit'
      })

      openSnackbar(`Edited Product: ${data.name}`)
      history.goBack()
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `edit product`, data })
    }
  }

  render() {
    const { user, classes, history } = this.props
    const { productData } = this.state

    return (
      <Container>
        <Typography variant="h1">Edit {productData && productData.name}</Typography>

        <br />

        <ProductForm user={user} handleSubmit={this.handleSubmit} currentValues={!!productData && productData} />

        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="secondary" onClick={history.goBack} className={classes.actionButton}>
            Cancel
          </Button>
        </div>
      </Container>
    )
  }
}

export default withStyles(styles)(EditProduct)
