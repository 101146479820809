import _ from 'lodash'

import { formatAsCurrency } from '../helpers'

export function getTotalPackageValue(packages) {
  let total = 0

  packages && packages.forEach(clientPackage => {
    total += clientPackage.price * (1 - clientPackage.PackageDetails.discount / 100)
  })

  // We use the Intl package in JS to format the numbers to a currency string
  return formatAsCurrency(total)
}

export function updateInstitutionList(listOfInstitutions, newInstitutionData, institutionsToDisplay) {
  // Find index of existing package in institutions and replace it with the new data
  const existingPackageIndex = _.findIndex(listOfInstitutions, ['id', newInstitutionData.id])
  listOfInstitutions[existingPackageIndex] = newInstitutionData

  // Find index of existing package in institutionsToDisplay and replace it with the new data, if it exists
  const displayInstitutionIndex = _.findIndex(institutionsToDisplay, ['id', newInstitutionData.id])
  if (displayInstitutionIndex !== -1) {
    institutionsToDisplay[displayInstitutionIndex] = newInstitutionData
  }

  return { listOfInstitutions, listOfInstitutionsToDisplay: institutionsToDisplay }
}

export function mapCurrentValuesToForm(currentValues) {
  return {
    name: currentValues.name,
    industry: currentValues.industry,
    size: currentValues.size || '',
    address: currentValues.address,
    taxNumber: currentValues.taxNumber || '',
    joinDate: currentValues.joinDate,
    parentId: currentValues.parentId || '',
    packages: currentValues.packages,
    notes: currentValues.notes
  }
}
