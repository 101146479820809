import React from 'react'

import { withStyles, TextField } from '@material-ui/core'

import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

const MultiLineTextField = ({ name, label, value, handleChange, touched, errors, fullWidth, classes }) => (
  <TextField
    className={classes.field}
    multiline
    rows={4}
    variant="outlined"
    fullWidth={fullWidth}
    id={name}
    name={name}
    label={label}
    value={value}
    onChange={handleChange}
    error={touched && Boolean(errors)}
    helperText={touched && errors}
  />
)

export default withStyles(styles)(MultiLineTextField)
