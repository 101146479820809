export default function formStyles(theme) {
  return {
    field: {
      margin: '0.1em 1em'
    },
    helperText: {
      margin: '0.1em 1.5em'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '1em'
    },
    actionButton: {
      margin: '1em'
    },
    sectionDivider: {
      margin: '1em 0.1em'
    },
    sectionHeader: {
      margin: '0.5em 0.1em'
    },
    accordionSummaryColumn: {
      flexBasis: '25%'
    },
    accordionDetailColumn: {
      flexBasis: '15%',
      margin: '0.4em'
    },
    accordionDetailContainer: {
      flexDirection: 'column'
    }
  }
}
