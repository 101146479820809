/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import _ from 'lodash'
import axios from 'axios'
import {
  withStyles, Container, TextField, MenuItem, Checkbox, FormControlLabel, Button, Chip, Paper, Avatar, OutlinedInput, InputAdornment, IconButton, FormControl, InputLabel, Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ClearIcon from '@material-ui/icons/Clear'

import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined'

import CSVReviewList from './CSVReviewList'
import ErrorTable from './ErrorTable'
import CSVFieldSelectForm from './CSVFieldSelectForm'
import { mailData } from './mailData'

import settings from '../../../settings'
import { getDateString, filterMailingListCSV, parseMailingListCSV, extractErrorData } from '../../helpers'
import { FunctionContext } from '../../contexts'
import { LinearProgressWithLabel } from '../../blocks'
import { mailStyles, commonStyles } from '../../../styles'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import { ShowSkeleton } from '../../utilities'

import Editor from 'ckeditor5-custom-build'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { formatEditorContent } from '../../helpers'

const styles = theme => ({ ...commonStyles(theme), ...mailStyles(theme) })

class Mail extends Component {
  static contextType = FunctionContext

  state = {
    templateId: '',
    subjects: mailData.subjects,
    subject: mailData.subjects[0].name,
    fromEmail: '',
    fromEmails: [],
    ccEmail: 'frontiermailchecker@gmail.com',
    categories: [],
    selectedCategories: ['frontier-mail'],
    selectedMailingList: { id: '', name: '' },
    mailingLists: [],
    csvData: [],
    content: '',
    attachments: [],
    totalAttachmentsSize: 0,
    isConfirmed: false,
    sendingEmails: false,
    progressText: 'progress',
    sendingErrors: [],
    progress: 0,
    badFormats: [],
    duplicates: [],
    csvStatus: 'Waiting Input',
    csvFileName: '',
    templates: [
      {
        id: 'd-728e600242714b75bdb407b6ba4825b3',
        name: 'Frontier Default',
        categories: [
          mailData.categories.default,
          ...mailData.categories.reports,
          ...mailData.categories.customized,
          ...mailData.categories.curation
        ],
        fromEmails: [mailData.fromEmails.research, mailData.fromEmails.news, mailData.fromEmails.timeTwister]
      }
    ],
    loading: true,
    csvInputResetKey: `csvResetKey${Date.now()}`
  }

  handleInputchange = event => {
    const { target } = event

    const value = target.type === 'checkbox' ? target.checked : target.value
    const { name } = target

    this.setState({
      [name]: value
    })
  }

  handleCategoriesChange = (event, value) => {
    this.setState({
      selectedCategories: value
    })
  }

  // weird behavior in Autocomplete when you use an array of objects -> when field loses focus, field value changes into the option label (name) from actual array element ({ type, name }).
  // the subject array has objects of form { type: "", name: "" } where type is used to group options by.
  // getOptionLabel={option => option.name} gives issues unless you use -> 
  // getOptionLabel={option => option.name || option} -> 
  // bc obviously, it can't access name property after it goes from object to a string
  // because of this, we have to set subject for Sendgrid as just state.subject instead of state.subject.name, 

  // considering the edge case where a user doesn't touch the subject field and sends an email, to avoid getting an object in the email subject, we have to set state.subject to a string
  handleSubjectChange = (event, value) => {
    if(typeof value === 'object' && value !== null){
      this.setState({
        subject: value.name
      })
    }else{
      this.setState({
        subject: value
      })
    }
  
  }

  handleMailistListChange = (event, value) => {
    const { user } = this.props

    this.setState({
      selectedMailingList: value
    }, () => {
      const { selectedMailingList } = this.state

      parseMailingListCSV({
        url: `${settings.baseURL}/api/products/${selectedMailingList.id}/download/mailing`,
        user: user,
        showErrorDialog: this.showErrorDialog,
        setCsvData: this.setCsvData,
        mailingListName: selectedMailingList.name
      })
    })
  }

  setTemplateData = async token => {
    const { openDialog } = this.context

    const sendGridTemplateArray = []

    try {
      const {
        data: { templates }
      } = await axios.get(`${settings.baseURL}/api/sendgrid/templates`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        }
      })

      templates.forEach(template => {
        switch (template.id) {
          case 'd-38c37c1562404d45a58d4355f9eefdfa': {
            sendGridTemplateArray.push({
              ...template,
              categories: [mailData.categories.default, ...mailData.categories.timeTwister],
              fromEmails: [mailData.fromEmails.timeTwister]
            })

            break
          }

          case 'd-e08bfeec42044ad895228078e3d5a7e3': {
            sendGridTemplateArray.push({
              ...template,
              categories: [
                mailData.categories.default,
                ...mailData.categories.curation,
                ...mailData.categories.customized,
                ...mailData.categories.reports
              ],
              fromEmails: [mailData.fromEmails.news]
            })

            break
          }

          default: {
            break
          }
        }
      })

      this.setState(
        prevState => ({
          templates: [...prevState.templates, ...sendGridTemplateArray]
        }),
        () => {
          const { id, fromEmails, categories } = this.state.templates[0]

          this.setState({
            templateId: id,
            fromEmail: fromEmails[0],
            fromEmails,
            categories,
            loading: false
          })
        }
      )
    } catch (err) {
      const data = extractErrorData(err)

      openDialog({
        failedAction: 'retrieve templates',
        data
      })
    }
  }

  setMailingListData = async () => {
    const { openDialog } = this.context
    const { user } = this.props

    try {
      const data = await databaseQueryInterface.get(`${settings.baseURL}/api/products`, user)

      this.setState({
        mailingLists:
          _.sortBy(data, ['name'])
            .map(product => ({
              id: product.id,
              name: product.name
            }))
      }, () => {
        this.setState({ selectedMailingList: this.state.mailingLists[0] })
      })
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({ failedAction: `retrieve product records`, data })
    }
  }

  handleTemplateChange = event => {
    const { templates } = this.state
    const {
      target: { value }
    } = event

    const selectedTemplate = _.find(templates, ['id', value])

    const { id, fromEmails, categories } = selectedTemplate

    this.setState({
      templateId: id,
      fromEmail: fromEmails[0],
      fromEmails,
      categories,
      selectedCategories: ['frontier-mail']
    })
  }

  showErrorDialog = errors => {
    const { openDialog } = this.props

    openDialog({
      title: 'Error found in csv file',
      content: `We ran into an unexpected '${errors[0].type} error' in this csv file. Please check this file for format errors`
    })
  }

  setCsvData = (csvData, csvFileName) => {
    this.setState({
      csvData,
      csvStatus: 'processing',
      csvFileName
    })
  }

  handleCSVFileSelect = event => {
    const {
      target: { files }
    } = event
    const { openDialog, openSnackbar } = this.context

    this.setState({
      badFormats: [],
      duplicates: [],
      sendingErrors: []
    })

    if (!files[0]) {
      openDialog({ title: 'Error retrieving File', content: 'Please choose the file again' })
      return
    }

    openSnackbar('CSV file uploaded')
    parseMailingListCSV({
      file: files[0],
      showErrorDialog: this.showErrorDialog,
      setCsvData: this.setCsvData
    })
  }

  handleCsvDone = csvFieldsOnData => {
    // Format of csvFieldsOnData is {Company: "", 'To and Cc': "", BCc: ""} - each key has the mapped header from the uploaded CSV
    const { openDialog, openSnackbar } = this.context
    const { csvData, ccEmail } = this.state

    this.setState({ csvStatus: 'processed' })

    const { badFormats, duplicates } = filterMailingListCSV(
      csvData,
      ccEmail,
      csvFieldsOnData,
      mailData.validEmailFormat
    )

    this.setState({ csvData }) // We reset csvData, since the filterMailingListCSV function overwrites it.

    if (duplicates.length > 0) {
      this.setState({ duplicates })
      openDialog({
        failedAction: 'filter out duplicates',
        content: `Duplicate records were detected. Please check the error log in the top of the page and resolve this before continuing`
      })
      return
    }

    if (badFormats.length) {
      this.setState({ badFormats })
      openDialog({
        failedAction: 'Could not validate emails',
        content: `Badly formated records were detected. Please check the error log in the top of the page and resolve these errors before continuing`
      })
      return
    }

    openSnackbar('CSV data was filtered automatically')
  }

  handleCsvDiscard = () => {
    this.setState({
      csvStatus: 'Waiting Input',
      csvData: [],
      badFormats: [],
      duplicates: [],
      sendingErrors: []
    })
  }

  handleEditorChange = (e, editor) => {

    this.setState({
      content : formatEditorContent(editor.getData())
    }, () => console.log("content Formatted"))
  }

  handleAttachmentAdd = event => {
    const {
      target: { files }
    } = event
    const { openDialog } = this.context

    Array.from(files).forEach(file => {
      const fileReader = new FileReader()

      fileReader.onloadend = () => {
        const { attachments, totalAttachmentsSize } = this.state

        const encodedResult = fileReader.result
        const content = encodedResult.split(',')[1]

        if (totalAttachmentsSize + file.size > 20000000) {
          openDialog({
            title: 'Some files were not attached',
            content: `"${file.name
              }" could not be attached. Please make sure the total size of the attachments are below 20MB`
          })

          return
        }

        this.setState(prevState => ({
          attachments: [
            ...attachments,
            {
              content,
              type: file.type,
              filename: file.name,
              size: file.size,
              disposition: 'attachment'
            }
          ],
          totalAttachmentsSize: prevState.totalAttachmentsSize + file.size
        }))
      }

      fileReader.readAsDataURL(file)
    })
  }

  handleAttachmentDelete = index => {
    const { attachments } = this.state

    const deletedAttachment = _.pullAt(attachments, index)

    this.setState(prevState => ({
      attachments,
      totalAttachmentsSize: prevState.totalAttachmentsSize - deletedAttachment[0].size
    }))
  }

  clearAllAttachments = () => {
    this.setState({
      attachments: [],
      totalAttachmentsSize: 0
    })
  }

  handleEmailSendAction = event => {
    event.preventDefault()

    const { openDialog } = this.context
    const { user } = this.props

    this.setState({ sendingEmails: true }, () => {
      user
        .getIdToken()
        .then(token => {
          this.sendEmails(token)
        })
        .catch(err => {
          openDialog({
            title: 'Authentication Error',
            content: `Hmm.. We can't seem to figure out who you are. Please logout, log back in and try again. If the issue persists, contact the tech team with the following message: ${err.message
              }`
          })
          this.setState({
            sendingEmails: false
          })
        })
    })
  }

  sendEmails = async authToken => {
    const { subject, fromEmail, ccEmail, templateId, content, selectedCategories, csvData, attachments } = this.state
    const { openDialog, openSnackbar } = this.context

    this.setState({
      sendingErrors: [],
      progress: 0
    })

    const date = getDateString('en-GB', 'numeric', 'short', 'numeric')

    const oneProgressTick = 100 / csvData.length

    for (const [index, record] of csvData.entries()) {
      this.setState({
        progressText: `Sending Emails to ${record.Company || record.Group}`
      })

      try {
        await axios.post(
          `${settings.baseURL}/api/emails/sendmail`,
          {
            subject,
            fromEmail,
            ccEmail,
            templateId,
            content: content
            .replaceAll('<figure', '<div style="margin: auto; width: 90%"') // fix for figure tags being left aligned + figure tags not being shown at all in Ymail
            .replaceAll('</figure>', '</div>') // replacing figures to divs for Ymail
            .replaceAll('<img', '<img style="width: 100%"'), // fix for images overflowing figure tags. editor adjusts the size of figure tags when resizing images, but the image width overflows them,
            selectedCategories,
            date,
            attachments,
            record
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': authToken
            }
          }
        )

        this.setState(prevState => ({
          progress: prevState.progress + oneProgressTick
        }))
      } catch (err) {
        this.setState(prevState => ({
          progress: prevState.progress + oneProgressTick,
          sendingErrors: [
            ...prevState.sendingErrors,
            { type: 'mail send error', line: index + 2, company: record.Company, error: extractErrorData(err) }
          ]
        }))
      }
    }

    this.setState({
      progressText: `Emails Sent.`
    })

    setTimeout(() => {
      this.setState({
        sendingEmails: false,
        isConfirmed: false
      })

      const { sendingErrors } = this.state

      if (sendingErrors.length) {
        openDialog({
          title: `Some emails have failed`,
          content: `For a full list of the emails that failed and the reason why they failed, see the box at the top of the page. 
          If the issue persists, contact the tech team.`
        })
      } else {
        openSnackbar('Emails sent successfully!')

        this.setState({
          subject: mailData.subjects[0].name,
          content: '',
          csvData: [],
          attachments: [],
          totalAttachmentsSize: 0,
          isConfirmed: false,
          sendingEmails: false,
          selectedCategories: ['frontier-mail'],
          csvInputResetKey: `csvResetKey${Date.now()}`
        })
      }
    }, 1000)
  }

  componentDidMount = () => {
    const { user } = this.props

    user.getIdToken().then(token => {
      this.setTemplateData(token)
    })

    this.setMailingListData()
  }

  render() {
    const {
      templates,
      templateId,
      subjects,
      subject,
      categories,
      selectedCategories,
      mailingLists,
      selectedMailingList,
      fromEmail,
      fromEmails,
      ccEmail,
      csvData,
      content,
      attachments,
      isConfirmed,
      sendingEmails,
      csvInputResetKey,
      progressText,
      progress,
      sendingErrors,
      badFormats,
      duplicates,
      csvStatus,
      csvFileName,
      loading
    } = this.state
    const { classes } = this.props

    const errors = [...sendingErrors, ...badFormats, ...duplicates]

    return (
      <Container maxWidth="lg">
        <ShowSkeleton variant="rect" height={700} condition={loading}>
          <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            {
              // errors
            }

            {errors.length ? (
              <ErrorTable errors={errors} badFormats={badFormats} duplicates={duplicates} classes={classes} />
            ) : null}
          </div>

          {
            // controls + editor
          }
          <div style={{
            display: 'flex',
            // border: '1px solid red'
          }}>
            {
              // form, CSV review
            }
            <div className={classes.emailDetailsForm}>
              <form autoComplete="off">
                {
                  // template
                }
                <TextField
                  disabled={isConfirmed}
                  fullWidth
                  select
                  required
                  label="Template"
                  margin="dense"
                  variant="outlined"
                  name="templateId"
                  value={templateId}
                  onChange={this.handleTemplateChange}
                >
                  {templates.map(templateObject => (
                    <MenuItem key={templateObject.id} value={templateObject.id}>
                      {templateObject.name}
                    </MenuItem>
                  ))}
                </TextField>

                {
                  // subject
                }
                <Autocomplete
                  disabled={isConfirmed}
                  freeSolo
                  autoSelect
                  groupBy={option => option.type}
                  required
                  name="subject"
                  onChange={this.handleSubjectChange}
                  options={subjects}
                  getOptionLabel={option => option.name || option}
                  value={subject}
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label="Subject"
                      placeholder="Add Subject"
                    />
                  )}
                />

                {
                  // from email - hidden
                }
                <TextField
                  // style={{ display: "none" }}
                  disabled={isConfirmed}
                  select
                  required
                  label="Send from"
                  margin="dense"
                  variant="outlined"
                  name="fromEmail"
                  value={fromEmail}
                  onChange={this.handleInputchange}
                >
                  {fromEmails.map(fromEmailsItem => (
                    <MenuItem key={fromEmailsItem.name + fromEmailsItem.email} value={fromEmailsItem}>
                      {`${fromEmailsItem.name} - ${fromEmailsItem.email}`}
                    </MenuItem>
                  ))}
                </TextField>

                {
                  // categories
                }
                <Autocomplete
                  disabled={isConfirmed}
                  multiple
                  required
                  id="email-categories"
                  name="selectedCategories"
                  onChange={this.handleCategoriesChange}
                  options={categories}
                  getOptionLabel={category => category}
                  value={selectedCategories}
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      label="Categories"
                      placeholder="Add Categories"
                    />
                  )}
                />

                {
                  // send copy to - hidden
                }
                <TextField
                  style={{ display: "none" }}
                  disabled={isConfirmed}
                  required
                  label="Send copy to"
                  margin="dense"
                  variant="outlined"
                  name="ccEmail"
                  value={ccEmail}
                  onChange={this.handleInputchange}
                />

                {
                  // mailing list
                }
                <div style={{
                  margin: '2rem 0'
                }}>
                  <Typography>Upload a CSV from your computer OR select mailing list name from dropdown:</Typography>

                  {
                    // mailing list
                  }
                  {csvStatus === 'Waiting Input' ? (
                    <>
                      {
                        // csv upload
                      }
                      <input
                        accept=".csv"
                        className={classes.hiddenInput}
                        id="csv-upload-button"
                        type="file"
                        onChange={this.handleCSVFileSelect}
                        key={csvInputResetKey}
                      />
                      <label htmlFor="csv-upload-button">
                        <Button variant="contained" component="span" className={classes.csvButton}>
                          Upload csv file
                          <CloudUploadIcon className={classes.rightIcon} />
                        </Button>
                      </label>

                      {
                        // mailing list drop down
                      }
                      <Autocomplete
                        disabled={isConfirmed}
                        required
                        id="mailing-list"
                        name="selectedMailingList"
                        onChange={this.handleMailistListChange}
                        options={mailingLists}
                        getOptionLabel={option => option.name}
                        value={selectedMailingList}
                        filterSelectedOptions
                        renderInput={params => (
                          <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            placeholder="Select mailing list"
                          />
                        )}
                      />
                    </>
                  ) : null}

                  {csvStatus === 'processing' ? (
                    <CSVFieldSelectForm
                      csvFileName={csvFileName}
                      csvFieldsOnInput={Object.keys(csvData[0])}
                      handleCsvDone={this.handleCsvDone}
                      handleCsvDiscard={this.handleCsvDiscard}
                      classes={classes}
                    />
                  ) : null}

                  {csvStatus === 'processed' ? (
                    <FormControl variant="outlined" margin='normal' fullWidth>
                      <InputLabel htmlFor="Csvfile">CSV file</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        margin='dense'
                        value={csvFileName}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="clear csv" onClick={this.handleCsvDiscard} edge="end">
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={70}
                      />
                    </FormControl>
                  ) : null}
                </div>

                {
                  // attachments
                }
                <div>
                  <div className={classes.attachmentControls}>
                    <input
                      className={classes.hiddenInput}
                      id="attachment-upload-button"
                      type="file"
                      multiple
                      onChange={this.handleAttachmentAdd}
                    />
                    <label htmlFor="attachment-upload-button">
                      <Button variant="contained" component="span">
                        Add Attachment(s)
                      </Button>
                    </label>

                    {attachments.length > 0 ? (
                      <Button
                        className={classes.clearAttachmentsButton}
                        variant="outlined"
                        component="span"
                        onClick={() => this.clearAllAttachments()}
                      >
                        Clear all
                      </Button>
                    ) : null}
                  </div>

                  {
                    attachments.length > 0 ? (
                      <Paper className={classes.fileChips}>
                        {
                          attachments.map((attachment, index) => (
                            <Chip
                              className={classes.fileChip}
                              key={attachment.filename + index}
                              avatar={<Avatar>{attachment.filename[0].toUpperCase()}</Avatar>}
                              label={attachment.filename}
                              onDelete={() => this.handleAttachmentDelete(index)}
                            />
                          ))
                        }
                      </Paper>
                    ) : null
                  }
                </div>

                {
                  // check, errors, button
                }
                <div style={{
                  margin: '2rem 0'
                }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isConfirmed"
                        checked={isConfirmed}
                        onChange={this.handleInputchange}
                        value="isConfirmed"
                        color="primary"
                      />
                    }
                    label="I've checked all the details"
                  />

                  {sendingEmails ? (
                    <div className={classes.progressbar} style={{ width: '100%' }}>
                      <LinearProgressWithLabel value={progress} />
                      <p className={classes.progressText}>{progressText}</p>
                    </div>
                  ) : (
                    <Button variant="contained" color="primary" disabled={!isConfirmed} onClick={this.handleEmailSendAction}>
                      Send Emails
                    </Button>
                  )}
                </div>
              </form>

              {
                // csv review
              }
              <CSVReviewList csvData={csvData} csvStatus={csvStatus} />
            </div>

            {
              // editor
            }
            <div className={classes.emailEditor}>
              <div style={{
                width: '95%',
                marginBottom: '1rem'
              }}>
                <CKEditor
                  editor={Editor}
                  config={{
                    removePlugins: ["Title"],
                    toolbar: [
                      'heading', '|', 
                      'link', '|', 
                      'bold', 'italic', 'underline', 'highlight', 'alignment', '|', 
                      'fontFamily', 'fontSize', 'fontBackgroundColor', 'fontColor', '|', 
                      'imageInsert', 'mediaEmbed', "|",
                      'outdent', 'indent', '|', 
                      'bulletedList', 'numberedList', '|', 
                      'horizontalLine', 'insertTable', 
                    ],
                    fontSize: {
                      options: [10, 11, 12, 13, 13.5, 14, 16, 18],
                      supportAllValues: true
                    },
                    fontFamily: {
                      options: [
                        'default',
                        'Arial, Helvetica, sans-serif',
                        'Courier New, Courier, monospace',
                        'Georgia, serif',
                        'Lucida Sans Unicode, Lucida Grande, sans-serif',
                        'Tahoma, Geneva, sans-serif',
                        'Times New Roman, Times, serif',
                        'Trebuchet MS, Helvetica, sans-serif',
                        'Verdana, Geneva, sans-serif'
                      ],
                      supportAllValues: true
                    },
                    table: {
                      tableCellProperties: {
                        defaultProperties: {
                          borderColor: 'transparent',
                        }
                      }
                    }
                  }}
                  data={content}
                  onChange={this.handleEditorChange}
                />
              </div>
            </div>
          </div>
        </ShowSkeleton >
      </Container >
    )
  }
}

export default withStyles(styles)(Mail)
