import React, { Component } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button
} from '@material-ui/core'

class ResetPasswordDialog extends Component {
  state = {
    emailAddress: ''
  }

  handleExited = () => {
    this.setState({
      emailAddress: ''
    })
  }

  resetPassword = () => {
    const { emailAddress } = this.state
    const { resetPassword } = this.props

    resetPassword(emailAddress)
  }

  handleKeyPress = event => {
    const { key, altKey, ctrlKey, metaKey, shiftKey } = event

    if (altKey || ctrlKey || metaKey || shiftKey) {
      return
    }

    if (key === 'Enter') {
      event.preventDefault()

      this.resetPassword()
    }
  }

  handleInputChange = event => {
    const {
      target: { name, value }
    } = event

    this.setState({
      [name]: value
    })
  }

  render() {
    const { emailAddress } = this.state

    const { isPerformingAuthAction, open, onClose } = this.props

    return (
      <Dialog open={open} onClose={onClose} onExited={this.handleExited} onKeyPress={this.handleKeyPress}>
        <DialogTitle>Reset your password</DialogTitle>

        <DialogContent>
          <DialogContentText>
            You will receive an email containing instructions on how to reset your password.
          </DialogContentText>

          <form>
            <TextField
              required
              variant="outlined"
              autoComplete="email"
              type="email"
              name="emailAddress"
              fullWidth
              autoFocus
              helperText="This should be your registered email address"
              margin="normal"
              onChange={this.handleInputChange}
              placeholder="Email Address"
              value={emailAddress}
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!emailAddress || isPerformingAuthAction}
            variant="contained"
            onClick={this.resetPassword}
          >
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ResetPasswordDialog
