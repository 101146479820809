import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import { BrowserRouter as Router } from 'react-router-dom'
import readingTime from 'reading-time'
import theme from './theme'
import { MuiThemeProvider, Snackbar, CircularProgress, withStyles } from '@material-ui/core'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/performance'
import './App.css'

// Routes
import Routes from './routes/routes'

// components
import { NavBar } from './components/layout'
import { Login } from './components/pages'
import { ResetPasswordDialog, CustomMessageDialog } from './components/Dialogs'

// Contexts
import { FunctionContext } from './components/contexts'

// Styles
import './styles/index.scss'
import { commonStyles } from './styles'

// custom
import settings from './settings'

// Load styles
const styles = themeData => ({ ...commonStyles(themeData) })

// Load Firebase configs
firebase.initializeApp(settings.credentials.firebase)

const auth = firebase.auth()

if (process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line no-unused-vars
  const performance = firebase.performance()
  Sentry.init({ dsn: 'https://6f56cba3073d4e4898abc4310cd80b7e@sentry.io/1518940' })
}

auth.useDeviceLanguage()

class App extends Component {
  _isMounted = false

  state = {
    isAuthReady: false,
    isPerformingAuthAction: false,
    isSignedIn: false,

    user: null,
    userRole: null,

    snackbar: {
      open: false,
      autoHideDuration: 0,
      message: ''
    },

    dialog: {
      open: false,
      title: '',
      content: ''
    },

    resetPasswordDialog: {
      open: false
    }
  }

  componentDidMount() {
    this._isMounted = true

    const localTheme = JSON.parse(localStorage.getItem('theme'))

    if (localTheme) {
      this.updateTheme(localTheme)
    }

    this.removeAuthObserver = firebase.auth().onAuthStateChanged(async user => {
      if (this._isMounted) {
        let userRole
        // Get user role from custom claims
        if (user) {
          const { claims } = await user.getIdTokenResult(true)

          userRole = claims.role
        }

        this.setState({
          isAuthReady: true,
          isSignedIn: !!user,
          user,
          userRole: user ? userRole : null // Store role as a separate property or it breaks other firebase methods on user
        })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false

    this.removeAuthObserver()
  }

  openSnackbar = message => {
    this.setState({
      snackbar: {
        open: true,
        autoHideDuration: readingTime(message).time * 2,
        message
      }
    })
  }

  closeSnackbar = (clearMessage = false) => {
    const { snackbar } = this.state

    this.setState({
      snackbar: {
        message: clearMessage ? '' : snackbar.message,
        open: false
      }
    })
  }

  openDialog = ({ failedAction, data, title, content }) => {
    this.setState({
      dialog: {
        open: true,
        title: title || `Couldn't ${failedAction}`,
        content:
          content ||
          `Please try reloading this page. If the issue persists, contact the tech team with the following message: ${data}`
      }
    })
  }

  closeDialog = (callback, clearMessage = false) => {
    const { dialog } = this.state
    this.setState(
      {
        dialog: {
          open: false,
          title: clearMessage ? '' : dialog.title,
          content: clearMessage ? '' : dialog.content
        }
      },
      () => {
        if (typeof callback === 'function') callback()
      }
    )
  }

  openResetPasswordDialog = () => {
    this.setState({
      resetPasswordDialog: {
        open: true
      }
    })
  }

  resetPassword = emailAddress => {
    if (!emailAddress) {
      return
    }

    this.setState({ isPerformingAuthAction: true }, async () => {
      try {
        await auth.sendPasswordResetEmail(emailAddress)

        this.closeResetPasswordDialog(() => {
          this.openSnackbar(`Password reset email sent to: ${emailAddress}`)
        })
      } catch (reason) {
        const { message } = reason
        this.openSnackbar(message)
      }

      this.setState({ isPerformingAuthAction: false })
    })
  }

  closeResetPasswordDialog = callback => {
    this.setState(
      {
        resetPasswordDialog: {
          open: false
        }
      },
      () => {
        if (callback && typeof callback === 'function') callback()
      }
    )
  }

  signIn = (emailAddress, password) => {
    const { isSignedIn } = this.state

    if (isSignedIn) {
      return
    }

    if (!emailAddress || !password) {
      return
    }

    this.setState({ isPerformingAuthAction: true }, async () => {
      try {
        const value = await auth.signInWithEmailAndPassword(emailAddress, password)

        const {
          user: { displayName, email }
        } = value

        this.openSnackbar(`Signed in as: ${displayName || email}`)
      } catch (reason) {
        const { message } = reason
        this.openSnackbar(message)
      }

      this.setState({ isPerformingAuthAction: false })
    })
  }

  signOut = () => {
    const { isSignedIn } = this.state

    if (!isSignedIn) {
      return
    }

    this.setState({ isPerformingAuthAction: true }, async () => {
      try {
        await auth.signOut()
        this.openSnackbar('Signed out')
      } catch (reason) {
        const { message } = reason
        this.openSnackbar(message)
      }

      this.setState({ isPerformingAuthAction: false })
    })
  }

  render() {
    const {
      isAuthReady,
      isPerformingAuthAction,
      isSignedIn,
      snackbar,
      dialog,
      resetPasswordDialog,
      user,
      userRole
    } = this.state

    const { classes } = this.props

    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <div
            className={classes.flexContainer}
            style={{ backgroundColor: theme.palette.type === 'dark' ? '#303030' : '#fafafa' }}
          >
            {!isAuthReady && <CircularProgress style={{ margin: 'auto'}} />}

            {isAuthReady && (
              <>
                <FunctionContext.Provider value={{ openDialog: this.openDialog, openSnackbar: this.openSnackbar }}>
                  {isSignedIn && (
                    <>
                      <NavBar
                        title={settings.title}
                        isSignedIn={isSignedIn}
                        isPerformingAuthAction={isPerformingAuthAction}
                        openResetPasswordDialog={this.openResetPasswordDialog}
                        user={user}
                        userRole={userRole}
                        signOut={this.signOut}
                      />

                      <Routes user={user} userRole={userRole} />
                    </>
                  )}

                  {!isSignedIn && (
                    <Login
                      signIn={this.signIn}
                      isPerformingAuthAction={isPerformingAuthAction}
                      openResetPasswordDialog={this.openResetPasswordDialog}
                    />
                  )}
                </FunctionContext.Provider>

                <Snackbar
                  autoHideDuration={snackbar.autoHideDuration}
                  message={snackbar.message}
                  open={snackbar.open}
                  onClose={this.closeSnackbar}
                />

                <CustomMessageDialog dialog={dialog} closeDialog={this.closeDialog} />

                <ResetPasswordDialog
                  open={resetPasswordDialog.open}
                  isPerformingAuthAction={isPerformingAuthAction}
                  resetPassword={this.resetPassword}
                  onClose={this.closeResetPasswordDialog}
                />
              </>
            )}
          </div>
        </MuiThemeProvider>
      </Router>
    )
  }
}

export default withStyles(styles)(App)
