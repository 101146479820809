import _ from 'lodash'

export default function addOrEditData(newData, collection) {
  const copyOfCollection = _.cloneDeep(collection) // make a copy of the collection data
  const dataExists = _.find(copyOfCollection, ['id', newData.id])

  if (dataExists) {
    // Edit action, so we replace the existing data  with the new one
    const dataIndex = _.findIndex(copyOfCollection, ['id', newData.id])
    copyOfCollection[dataIndex] = newData

    return copyOfCollection // Return replaced data when editing
  }

  return [...copyOfCollection, newData] // Add the new data when adding
}
