import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  AppBar,
  Toolbar,
  Container,
  Slide
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function FormDialog({ fullScreen, open, handleClose, title, bodyText, classes, children }) {
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialogStyles }}
      TransitionComponent={fullScreen && Transition} // Add a smooth Slide transiton, if it's a fullscreen dialog
      style={{ marginTop: fullScreen && '3vh' }} // Add some margin to the top if it's a fullscreen dialog
    >
      {fullScreen && (
        // Add a appbar and close button, if using a full screen dialog
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Container>
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          {bodyText && <DialogContentText>{bodyText}</DialogContentText>}
          <div style={{ padding: 20, overflow: 'hidden' }}>{children}</div>
        </DialogContent>
      </Container>
    </Dialog>
  )
}
