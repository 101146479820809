import React from 'react'

import { withStyles, Container, Typography, Button } from '@material-ui/core'

import { InstitutionForm } from '../../forms'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { extractErrorData, algoliaDataUpdate } from '../../helpers'
import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

class EditInstitution extends React.Component {
  static contextType = FunctionContext

  state = {
    institutionData: null
  }

  componentDidMount() {
    this.getInstitutionData()
  }

  getInstitutionData = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params }
    } = this.props

    try {
      const data = await databaseQueryInterface.get(`${settings.baseURL}/api/institutions/${params.id}`, user)

      this.setState({ institutionData: data }, () => openSnackbar(`Retrieved data for ${data.name}`))
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve user records`,
        data
      })
    }
  }

  handleSubmit = async formData => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params },
      history
    } = this.props
    try {
      const { institution, packagesIdsToRemove } = await databaseQueryInterface.put(
        `${settings.baseURL}/api/institutions/${params.id}`,
        user,
        {
          ...formData
        }
      )

      algoliaDataUpdate({
        objectID: institution.id,
        name: institution.name,
        type: 'institutions',
        index: 'institutions',
        operation: 'edit'
      })

      for (const id of packagesIdsToRemove) {
        await databaseQueryInterface.put(`${settings.baseURL}/api/institutions/${params.id}/remove-package/${id}`, user)
      }

      openSnackbar(`Updated Institution: ${institution.name}`)
      history.goBack()
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({
        failedAction: `edit institution`,
        data
      })
    }
  }

  render() {
    const { user, classes, history } = this.props
    const { institutionData } = this.state

    return (
      <Container>
        <Typography variant="h1">Edit {institutionData && institutionData.name}</Typography>

        <br />

        <InstitutionForm
          user={user}
          handleSubmit={this.handleSubmit}
          currentValues={!!institutionData && institutionData}
        />

        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="secondary" onClick={history.goBack} className={classes.actionButton}>
            Cancel
          </Button>
        </div>
      </Container>
    )
  }
}

export default withStyles(styles)(EditInstitution)
