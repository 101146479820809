import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { AppBar, Toolbar, Typography, IconButton, Avatar, Menu, MenuItem, Link } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { withStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import MenuIcon from '@material-ui/icons/Menu'

import { PermissibleRender } from '../utilities'
import { navbarStyles } from '../../styles'
import NavDrawer from './NavDrawer'

const styles = theme => ({ ...navbarStyles(theme) })

class NavBar extends Component {
  state = {
    menu: {
      anchorEl: null
    },
    navDrawer: {
      open: false
    }
  }

  openNavDrawer = () => {
    this.setState({
      navDrawer: {
        open: true
      }
    })
  }

  closeNavDrawer = event => {
    const { type, key } = event

    if (type === 'keydown' && (key === 'Tab' || key === 'Shift')) {
      return
    }

    this.setState({
      navDrawer: {
        open: false
      }
    })
  }

  openMenu = event => {
    const anchorEl = event.currentTarget

    this.setState({
      menu: {
        anchorEl
      }
    })
  }

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null
      }
    })
  }

  handleSignOutClick = () => {
    const { signOut } = this.props

    this.closeMenu()
    signOut()
  }

  render() {
    const { menu, navDrawer } = this.state
    const { classes, title, isPerformingAuthAction, isSignedIn, openResetPasswordDialog, user, userRole } = this.props

    return (
      <>
        <AppBar color="primary" className={classes.mainContainer}>
          <Toolbar variant="dense">
            <PermissibleRender userPermission={userRole} requiredPermissions={['dev', 'editor', 'member', 'remoteMember', 'remoteMailing']}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={this.openNavDrawer}
              >
                <MenuIcon />
              </IconButton>
            </PermissibleRender>

            <Typography style={{ flexGrow: 1 }} color="inherit" variant="p">
              {title}
            </Typography>

            <Link to="/search" component={RouterLink} color="inherit">
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Search">
                <SearchIcon />
              </IconButton>
            </Link>

            {isSignedIn && (
              <>
                <IconButton color="inherit" disabled={isPerformingAuthAction} onClick={this.openMenu}>
                  {user.photoURL ? <Avatar alt="Avatar" src={user.photoURL} /> : <PersonIcon />}
                </IconButton>

                <Menu anchorEl={menu.anchorEl} open={Boolean(menu.anchorEl)} onClose={this.closeMenu}>
                  <MenuItem disabled={isPerformingAuthAction} onClick={openResetPasswordDialog}>
                    Reset Password
                  </MenuItem>
                  <MenuItem disabled={isPerformingAuthAction} onClick={this.handleSignOutClick}>
                    Sign out
                  </MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>

        <div className={classes.toolbar} />

        <NavDrawer open={navDrawer.open} onClose={this.closeNavDrawer} userRole={userRole} />
      </>
    )
  }
}

export default withStyles(styles)(NavBar)
