export default function formatEditorContent(content) {
  let formatted;
  formatted = content
    .replaceAll(`&quot;`, `'`) // fix for &quot preventing font family to apply correctly
    .replaceAll("<table", '<table style="border-spacing: 0"'); // fix for default border-spacing 2px being applied and a thick white border appearing around cells as a result

  const withFormatedLinks = formatLinks(formatted);

  return withFormatedLinks;
}

function formatLinks(string) {
  // const links = getStringBetween(filtered, '<a' , '</a>')
  const separatedLinks = string.split("</a");

  for (let i = 0; i < separatedLinks.length - 1; i++) {
    let splitLink = separatedLinks[i].split("<a");

    splitLink[1] = splitLink[1].replaceAll(`color:black;`, ``);

    separatedLinks[i] = splitLink.join("<a");
  }

  return separatedLinks.join("</a");
}
