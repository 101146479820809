import React, { Component } from 'react'

import { withStyles, Container, Typography, Button } from '@material-ui/core'

import { PersonForm } from '../../forms'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { extractErrorData, algoliaDataUpdate } from '../../helpers'
import { commonStyles, formStyles } from '../../../styles'

const styles = theme => ({ ...commonStyles(theme), ...formStyles(theme) })

class CreatePerson extends Component {
  static contextType = FunctionContext

  handleSubmit = async formData => {
    const { openDialog, openSnackbar } = this.context
    const { user, history } = this.props

    try {
      const data = await databaseQueryInterface.post(`${settings.baseURL}/api/users-new/`, user, {
        ...formData
      })

      const { id, name, primaryEmail } = data

      algoliaDataUpdate({
        objectID: id,
        name,
        email: primaryEmail,
        type: 'person', // 'type' attribute is used to add links to search results -> /person/view/:id
        index: 'people',
        operation: 'add'
      })

      openSnackbar(`Created new user - ${data.name}`)
      history.goBack()
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `create person`,
        data
      })
    }
  }

  render() {
    const { user, classes, history } = this.props
    return (
      <Container>
        <Typography variant="h1">Create a new User</Typography>

        <br />

        <PersonForm user={user} handleSubmit={this.handleSubmit} />

        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="secondary" onClick={history.goBack} className={classes.actionButton}>
            Cancel
          </Button>
        </div>
      </Container>
    )
  }
}

export default withStyles(styles)(CreatePerson)
