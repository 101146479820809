import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  Mail,
  ErrorNotFound,
  Users,
  ImportUsers,
  Institutions,
  Institution,
  CreateInstitution,
  EditInstitution,
  Products,
  Product,
  CreateProduct,
  EditProduct,
  AddUsersToProduct,
  Packages,
  Package,
  CreatePackage,
  EditPackage,
  Person,
  CreatePerson,
  DuplicatePerson,
  EditPerson,
  Search,
  Changelogs,
  Logs
} from '../components/pages'

const routes = [
  { path: '/', component: Mail }, // Homepage
  { path: '/logs', component: Logs }, // Logs

  // Firebase User routes
  { path: '/users', component: Users },
  { path: '/users/import', component: ImportUsers },

  // ----------- Institution Routes -----------
  { path: '/institutions', component: Institutions },
  { path: '/institutions/create', component: CreateInstitution },
  { path: '/institutions/edit/:id', component: EditInstitution },
  { path: '/institutions/view/:id', component: Institution },

  // ----------- Product Routes -----------
  { path: '/products', component: Products },
  { path: '/products/create', component: CreateProduct },
  { path: '/products/edit/:id', component: EditProduct },
  { path: '/products/add-users/:id', component: AddUsersToProduct },
  { path: '/products/view/:id', component: Product },

  // ----------- Package Routes -----------
  { path: '/packages', component: Packages },
  { path: '/packages/create', component: CreatePackage },
  { path: '/packages/edit/:id', component: EditPackage },
  { path: '/packages/view/:id', component: Package },

  // ----------- People Routes -----------
  { path: '/person/create', component: CreatePerson },
  { path: '/person/view/:id', component: Person },
  { path: '/person/:id/edit', component: EditPerson },
  { path: '/person/:id/duplicate', component: DuplicatePerson },

  { path: '/search', component: Search },
  { path: '/changelogs', component: Changelogs }
]

const Routes = ({ user, userRole }) => (
  <Switch>
    {routes.map((route, index) => (
      <Route
        exact
        key={index}
        path={route.path}
        render={props => <route.component user={user} userRole={userRole} {...props} />}
      />
    ))}

    <Route component={ErrorNotFound} />
  </Switch>
)

export default Routes
