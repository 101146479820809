import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'

import {
  withStyles,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  FormControlLabel,
  Switch,
  Chip
} from '@material-ui/core'

import { AlphabeticalPaginationBar, AdminButtons, CustomList, CustomAccordion, ShowRemovedSwitch } from '../../blocks'
import { FormDialog, ConfirmationDialog } from '../../Dialogs'
import { PackageDetailForm } from '../../forms'

import {
  extractErrorData,
  filterRemovedItems,
  getDataForPage,
  algoliaDataUpdate,
  getPaginationButtons
} from '../../helpers'
import { getTotalPackageValue } from '../../lib/institution'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { commonStyles, formStyles } from '../../../styles'

const styles = theme => ({ ...commonStyles(theme), ...formStyles(theme) })

class Institution extends Component {
  static contextType = FunctionContext

  state = {
    institutionDetails: null,
    parentInstitution: null,
    paginationButtons: [],
    usersToDisplay: [],
    showDeletedUsers: false,
    packagesToDisplay: [],
    showDeletedPackages: false,
    selectedPackage: null,
    openRemoveDialog: false,
    openDeleteDialog: false,
    openPackageDetailForm: false,
    openPackageDeleteForm: false
  }

  componentDidMount() {
    this.getData()
  }

  // TODO: Add remove/delete actions to this page

  getData = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params }
    } = this.props

    const { showDeletedUsers } = this.state

    try {
      const data = await databaseQueryInterface.get(
        `${settings.baseURL}/api/institutions/${params.id}?showDeleted=${showDeletedUsers ? 'true' : 'false'}`,
        user
      )

      const paginationButtons = getPaginationButtons(data.users)

      this.handleFilterPackages(data.packages)

      this.setState({ institutionDetails: data, usersToDisplay: data.users, paginationButtons }, () => {
        this.getParentInstitution()
        openSnackbar(`Retrieved data for ${data.name}`)
      })
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve user records`,
        data
      })
    }
  }

  getParentInstitution = async () => {
    const { openDialog } = this.context
    const { user } = this.props
    const { institutionDetails } = this.state

    if (!institutionDetails.parentId) return

    try {
      const parentInstitution = await databaseQueryInterface.get(
        `${settings.baseURL}/api/institutions/${institutionDetails.parentId}`,
        user
      )

      this.setState({ parentInstitution })
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve Parent Institution records`,
        data
      })
    }
  }

  handlePaginationSelect = (event, buttonValue) => {
    const { institutionDetails } = this.state

    const usersToDisplay = getDataForPage(buttonValue, institutionDetails.users)

    this.setState({ usersToDisplay })
  }

  handleShowDeletedSwitchChange = () => {
    const { showDeletedUsers } = this.state
    this.setState({ showDeletedUsers: !showDeletedUsers }, () => this.getData())
  }

  handleDownload = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { institutionDetails } = this.state

    try {
      const downloadRoute = `${settings.baseURL}/api/institutions/${institutionDetails.id}/download`

      const csv = await databaseQueryInterface.get(downloadRoute, user)

      // create an <a> tag and trigger a click to download file
      const link = document.createElement('a')

      link.href = window.URL.createObjectURL(new Blob([csv]))
      link.setAttribute('download', `${institutionDetails.name}.csv`)
      
      document.body.appendChild(link)
      link.click()
      link.remove()

      openSnackbar(`Downloaded subscription details for institution.`)
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `download subscription details`,
        data
      })
    }
  }

  handleRemove = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user, history } = this.props
    const { institutionDetails } = this.state

    try {
      const data = await databaseQueryInterface.put(
        `${settings.baseURL}/api/institutions/${institutionDetails.id}/remove`, // This route acts as a toggle
        user
      )

      this.setState({ openRemoveDialog: false })

      openSnackbar(`${data.deletedAt ? 'Removed' : 'Restored'} Institution: ${data.name}`)

      history.goBack()
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `${data.deletedAt === null ? 'remove' : 'restore'} institution`,
        data
      })
    }
  }

  handleDelete = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user, history } = this.props
    const { institutionDetails } = this.state

    try {
      const data = await databaseQueryInterface.destroy(
        `${settings.baseURL}/api/institutions/${institutionDetails.id}`,
        user
      )

      this.setState({ openDeleteDialog: false })

      algoliaDataUpdate({
        objectID: data.id,
        index: 'institutions',
        operation: 'delete'
      })

      openSnackbar(`Permanently deleted institution: ${data.name}`)

      history.goBack()
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `delete institution`,
        data
      })
    }
  }

  handleFilterPackages = (listOfPackages, showDeletedPackages = false) => {
    const filteredPackages = filterRemovedItems(listOfPackages, 'PackageDetails.deletedAt', showDeletedPackages)

    this.setState({ packagesToDisplay: filteredPackages })
  }

  handleShowRemovedPackageSwitchChange = () => {
    this.setState(
      prevState => ({ showDeletedPackages: !prevState.showDeletedPackages }),
      () => {
        const { institutionDetails, showDeletedPackages } = this.state
        this.handleFilterPackages(institutionDetails.packages, showDeletedPackages)
      }
    )
  }

  handleRemoveDialogOpen = () => this.setState({ openRemoveDialog: true })

  handleRemoveDialogClose = () => this.setState({ openRemoveDialog: false })

  handleDeleteDialogOpen = () => this.setState({ openDeleteDialog: true })

  handleDeleteDialogClose = () => this.setState({ openDeleteDialog: false })

  handleOpenPackageDetailFormDialog = packageItem =>
    this.setState({ openPackageDetailForm: true, selectedPackage: packageItem })

  handleClosePackageDetailFormDialog = () => this.setState({ openPackageDetailForm: false, selectedPackage: null })

  handlePackageEditSubmit = async formData => {
    const { user } = this.props
    const { institutionDetails } = this.state
    const { openDialog, openSnackbar } = this.context

    try {
      await databaseQueryInterface.put(
        `${settings.baseURL}/api/institutions/${institutionDetails.id}/update-package`,
        user,
        { package: formData }
      )

      openSnackbar('Package Successfully edited.')
      this.handleClosePackageDetailFormDialog()
      this.getData()
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `edit Package`,
        data
      })
    }
  }

  handleOpenPackageDeleteDialog = packageItem =>
    this.setState({ openPackageDeleteForm: true, selectedPackage: packageItem })

  handleClosePackageDeleteDialog = () => this.setState({ openPackageDeleteForm: false, selectedPackage: null })

  handleTogglePackageRemove = async () => {
    const { user } = this.props
    const { institutionDetails, selectedPackage } = this.state
    const { openDialog, openSnackbar } = this.context

    const action = selectedPackage.PackageDetails.deletedAt ? 'restore' : 'remove'

    try {
      await databaseQueryInterface.put(
        `${settings.baseURL}/api/institutions/${institutionDetails.id}/${action}-package/${selectedPackage.id}`,
        user
      )

      openSnackbar(`Package Successfully ${action}d. All users subscribed through it have also been ${action}d.`)
      this.handleClosePackageDeleteDialog()
      this.getData()
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `${action} package.`,
        data
      })
    }
  }

  render() {
    const { classes, history, userRole, user } = this.props
    const {
      institutionDetails,
      parentInstitution,
      paginationButtons,
      usersToDisplay,
      showDeletedUsers,
      packagesToDisplay,
      showDeletedPackages,
      selectedPackage,
      openRemoveDialog,
      openDeleteDialog,
      openPackageDetailForm,
      openPackageDeleteForm
    } = this.state

    return (
      <Container>
        {institutionDetails ? (
          <div>
            <Grid container justifyContent="space-between" alignItems="flex-start" spacing={3}>
              {/* Company info column */}
              <Grid item xs={12} md={6}>
                <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={3}>
                  <Grid item>
                    <Paper className={classes.paperStyles}>
                      <div className={classes.rightAlignedButtonContainer}>
                        <AdminButtons
                          classes={classes}
                          userRole={userRole}
                          item={institutionDetails}
                          handleEditClick={() => history.push(`/institutions/edit/${institutionDetails.id}`)}
                          handleRemoveClick={this.handleRemoveDialogOpen}
                          handleDeleteClick={this.handleDeleteDialogOpen}
                          handleDownloadClick={this.handleDownload}
                        />
                      </div>

                      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                        <Typography variant="h1">{institutionDetails.name}</Typography>

                        {institutionDetails.deletedAt && (
                          <Grid item>
                            <Chip label="Removed" color="secondary" variant="outlined" />
                          </Grid>
                        )}
                      </Grid>

                      {parentInstitution ? (
                        <Typography variant="overline">Subsidiary of: {parentInstitution.name}</Typography>
                      ) : null}

                      <Container className={classes.sectionDiv}>
                        <Typography variant="overline">
                          <b>Active Subscribers:</b> {institutionDetails.users.length}
                        </Typography>
                        <br />
                        <Typography variant="overline">
                          <b>Total Package Value:</b> {getTotalPackageValue(institutionDetails.packages)}
                        </Typography>
                      </Container>

                      {(institutionDetails.industry ||
                        institutionDetails.address ||
                        institutionDetails.size ||
                        institutionDetails.taxNumber) && (
                        <Container className={classes.sectionDiv}>
                          {institutionDetails.industry && (
                            <Typography variant="caption">
                              <b>Industry:</b> {institutionDetails.industry}
                              <br />
                            </Typography>
                          )}
                          {institutionDetails.address && (
                            <Typography variant="caption">
                              <b>Address:</b> {institutionDetails.address}
                              <br />
                            </Typography>
                          )}
                          {institutionDetails.size && (
                            <Typography variant="caption">
                              <b>Size:</b> {institutionDetails.size}
                              <br />
                            </Typography>
                          )}
                          {institutionDetails.taxNumber && (
                            <Typography variant="caption">
                              <b>Tax Number:</b> {institutionDetails.taxNumber}
                            </Typography>
                          )}
                        </Container>
                      )}
                    </Paper>
                  </Grid>

                  <Grid item>
                    <Paper className={classes.paperStyles}>
                      <ShowRemovedSwitch
                        checked={showDeletedPackages}
                        handleChange={this.handleShowRemovedPackageSwitchChange}
                        name="showRemoved"
                      />

                      <Typography variant="h2">Packages</Typography>

                      {_.orderBy(packagesToDisplay, ['PackageDetails.deletedAt', 'name'], ['desc', 'asc']).map(
                        packageItem => (
                          <CustomAccordion
                            key={packageItem.id}
                            title={packageItem.name}
                            classes={classes}
                            data={packageItem}
                            removedStatusPath="PackageDetails.deletedAt"
                            customChips={[
                              {
                                label: `${packageItem.PackageDetails.numUsersAllowed} users allowed`,
                                path: 'PackageDetails.numUsersAllowed'
                              },
                              {
                                label: `${packageItem.PackageDetails.discount}% discount applied`,
                                path: 'PackageDetails.discount'
                              },
                              { label: 'Trial', path: 'PackageDetails.trial' }
                            ]}
                            handleEdit={() => this.handleOpenPackageDetailFormDialog(packageItem)}
                            handleDelete={() => this.handleOpenPackageDeleteDialog(packageItem)}
                          >
                            <>
                              <div className={classes.accordionDetailColumn}>
                                <Typography>
                                  <strong>Subscription Start Date:</strong>{' '}
                                  {moment(new Date(packageItem.PackageDetails.startDate).toISOString()).format(
                                    'Do MMM YYYY'
                                  )}
                                </Typography>
                              </div>

                              {packageItem.PackageDetails.trial && (
                                <div className={classes.accordionDetailColumn}>
                                  <Typography>
                                    <strong>Trial End Date:</strong>{' '}
                                    {moment(new Date(packageItem.PackageDetails.trialEndDate).toISOString()).format(
                                      'Do MMM YYYY'
                                    )}
                                  </Typography>
                                </div>
                              )}

                              <div className={classes.accordionDetailColumn}>
                                <Typography variant="h6">Active products under this package:</Typography>

                                <CustomList
                                  itemsToList={packageItem.products}
                                  urlModel="/products/view/"
                                />
                              </div>

                              {packageItem.PackageDetails.notes && (
                                <div className={classes.accordionDetailColumn}>
                                  <Typography variant="h6">Notes:</Typography>
                                  <Typography>{packageItem.PackageDetails.notes}</Typography>
                                </div>
                              )}
                            </>
                          </CustomAccordion>
                        )
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

              {/* People info column */}
              <Grid item xs={12} md={6}>
                <Paper className={classes.paperStyles}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showDeletedUsers}
                            onChange={this.handleShowDeletedSwitchChange}
                            color="primary"
                            name="showDeleted"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label="Show removed users"
                      />
                    </Grid>

                    <Grid item>
                      <AdminButtons
                        classes={classes}
                        userRole={userRole}
                        handleAddClick={() => {
                          history.push('/person/create')
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={3}>
                    <Grid item>
                      <Grid container direction="column" justifyContent="space-between" alignItems="center" spacing={3}>
                        <Grid item>
                          <AlphabeticalPaginationBar
                            paginationButtons={paginationButtons}
                            handlePaginationSelect={this.handlePaginationSelect}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <CustomList
                        itemsToList={usersToDisplay}
                        showRemoved={showDeletedUsers}
                        urlModel="/person/view/"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            {/* Dialogs for Packages */}
            <FormDialog
              fullScreen
              open={openPackageDetailForm}
              handleClose={this.handleClosePackageDetailFormDialog}
              classes={classes}
              title="Edit Package"
            >
              <PackageDetailForm
                user={user}
                existingPackages={_.map(institutionDetails.packages, 'id')}
                handleSubmit={formData => this.handlePackageEditSubmit(formData)}
                currentValues={selectedPackage}
              />
            </FormDialog>

            {selectedPackage && (
              <ConfirmationDialog
                open={openPackageDeleteForm}
                closeDialog={this.handleClosePackageDeleteDialog}
                text={`Are you sure you want to ${selectedPackage.PackageDetails.deletedAt ? 'restore' : 'delete'} "${
                  selectedPackage.name
                }"? This will also ${
                  selectedPackage.PackageDetails.deletedAt ? 'restore' : 'delete'
                } all products under the package that users are subscribed to.`}
                handleConfirm={this.handleTogglePackageRemove}
              />
            )}

            {/* Dialogs for Institution */}
            <ConfirmationDialog
              open={openRemoveDialog}
              closeDialog={this.handleRemoveDialogClose}
              text={`Are you sure you want to ${institutionDetails.deletedAt === null ? 'remove' : 'restore'} "${
                institutionDetails.name
              }"?`}
              handleConfirm={this.handleRemove}
            />

            <ConfirmationDialog
              open={openDeleteDialog}
              closeDialog={this.handleDeleteDialogClose}
              text={`Are you sure you want to delete "${institutionDetails.name}"?`}
              handleConfirm={this.handleDelete}
              extraSecurity
            />
          </div>
        ) : (
          <CircularProgress />
        )}
      </Container>
    )
  }
}

export default withStyles(styles)(Institution)
