import * as Yup from 'yup'

export const validationSchema = Yup.object({
  name: Yup.string()
    .max(255, 'Must be 255 characters or less')
    .required('Required'),
  description: Yup.string().min(10, 'Must be at least 10 characters or less'),
  subCategoryId: Yup.number().required(),
  categoryId: Yup.number(),
  notes: Yup.string().min(10, 'Must be at least 10 characters or less')
})

export const initialFormData = {
  name: '', // Name of the Product
  description: '', // Description of the Product
  subCategoryId: '', // Subcategory of the product
  categoryId: '', // Category of the product
  notes: '' // Additional Notes
}
