import React, { useEffect, useContext } from 'react'
import { Formik } from 'formik'

import { withStyles, Grid, Button, FormHelperText } from '@material-ui/core'

import { validationSchema, initialFormData } from './formSetup'

import { SingleLineTextField, DatePickerField, MultiLineTextField, SelectField, CheckboxField } from '../../blocks'
import { formStyles } from '../../../styles'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { extractErrorData, formatDataForAutocomplete } from '../../helpers'
import { mapCurrentValuesToForm, getPackageData, formatPackageDetailFormSubmit } from '../../lib/packageDetail'

const styles = theme => ({ ...formStyles(theme) })

const PackageDetailForm = ({ handleSubmit, user, currentValues, existingPackages, classes }) => {
  const [allPackages, setAllPackages] = React.useState(null)
  const [initialValues, setInitialValues] = React.useState(initialFormData)

  const { openDialog } = useContext(FunctionContext)

  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    // useEffect can only return a function (not an async function),
    // So, we create an async function and call it immediately to avoid errors
    async function fetchPackageList() {
      try {
        const data = await databaseQueryInterface.get(`${settings.baseURL}/api/packages`, user)

        const packagesList = formatDataForAutocomplete(data)

        if (isMounted) setAllPackages(packagesList)
      } catch (err) {
        const data = extractErrorData(err)
        openDialog({
          failedAction: `retrieve list of Packages for package field`,
          data
        })
      }
    }

    fetchPackageList()

    return () => {
      isMounted = false
    }
  }, [openDialog, user])

  // Effect to update the form with the new values during an edit action
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updatePackageDetailData() {
      if (currentValues) {
        const currentData = mapCurrentValuesToForm(currentValues)

        if (isMounted) setInitialValues(currentData)
      }
    }

    updatePackageDetailData()

    return () => {
      isMounted = false
    }
  }, [currentValues])

  const formatDataToSubmit = values => {
    const packageData = getPackageData(values.package, allPackages)

    return formatPackageDetailFormSubmit(packageData, values)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
      validationSchema={validationSchema}
      onSubmit={values => {
        handleSubmit(formatDataToSubmit(values))
      }}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <SelectField
                name="package"
                fullWidth
                label="Package"
                value={formik.values.package}
                disabledOptionIds={existingPackages}
                handleChange={selectedOption => {
                  // We fine the full package details from allPackages and assign that to formik.values.package
                  // This is so that we can use the package name etc. in the InstitutionForm
                  formik.setFieldValue('package', selectedOption || '')
                }}
                touched={formik.touched.package}
                errors={formik.errors.package}
                items={allPackages || []}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SingleLineTextField
                fullWidth
                name="numUsersAllowed"
                label="Number of Users Allowed"
                value={formik.values.numUsersAllowed}
                handleChange={formik.handleChange}
                touched={formik.touched.numUsersAllowed}
                errors={formik.errors.numUsersAllowed}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SingleLineTextField
                fullWidth
                name="discount"
                label="Discount (%)"
                disabled={formik.values.trial} // disabled if trial
                value={formik.values.discount}
                handleChange={formik.handleChange}
                touched={formik.touched.discount}
                errors={formik.errors.discount}
              />

              {formik.values.trial && <FormHelperText>Trial packages are given a 100% discount</FormHelperText>}
            </Grid>

            <Grid item xs={12} md={6}>
              <DatePickerField
                name="startDate"
                fullWidth
                label="Start Date"
                value={formik.values.startDate}
                handleChange={date => {
                  formik.setFieldValue('startDate', date ? date._d : '')
                }}
                touched={formik.touched.startDate}
                errors={formik.errors.startDate}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={4} justifyContent="space-between" alignItems="flex-start">
                <Grid item xs={12} md={6}>
                  <CheckboxField
                    name="trial"
                    label="Trial"
                    handleChange={(event, value) => {
                      if (value) {
                        formik.setFieldValue('discount', 100)
                      } else {
                        formik.setFieldValue('discount', initialValues.discount)
                      }
                      formik.setFieldValue('trial', value)
                    }}
                    checked={formik.values.trial}
                    touched={formik.touched.trial}
                    errors={formik.errors.trial}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DatePickerField
                    name="trialEndDate"
                    fullWidth
                    label="Trial End Date"
                    value={formik.values.trialEndDate}
                    handleChange={date => {
                      formik.setFieldValue('trialEndDate', date._d || new Date())
                    }}
                    disabled={!formik.values.trial}
                    touched={formik.touched.trialEndDate}
                    errors={formik.errors.trialEndDate}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <MultiLineTextField
                fullWidth
                name="notes"
                label="Notes"
                value={formik.values.notes}
                handleChange={formik.handleChange}
                touched={formik.touched.notes}
                errors={formik.errors.notes}
              />
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default withStyles(styles)(PackageDetailForm)
