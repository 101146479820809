import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Container, Paper, IconButton, Typography, Button } from '@material-ui/core'
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab'

import { CustomList, AdminButtons } from '../../blocks'
import { getTotalPackageValue } from '../../lib/institution'

const DetailsPane = ({
  classes,
  userRole,
  selectedInstitution,
  handleClose,
  findParentCompany,
  handleEdit,
  handleRemove,
  handleDelete,
  history
}) => {
  return (
    <Paper className={classes.paperStyles}>
      <div className={classes.rightAlignedButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/institutions/view/${selectedInstitution && selectedInstitution.id}`}
        >
          View All Details
        </Button>

        <AdminButtons
          userRole={userRole}
          classes={classes}
          item={selectedInstitution && selectedInstitution}
          handleEditClick={handleEdit}
          handleRemoveClick={handleRemove}
          handleDeleteClick={handleDelete}
        />

        <IconButton onClick={handleClose}>
          <KeyboardTabIcon />
        </IconButton>
      </div>

      <Typography variant="h2">{selectedInstitution && selectedInstitution.name}</Typography>

      {selectedInstitution && selectedInstitution.parentId && (
        <Typography variant="overline">
          Subsidiary of: {findParentCompany(selectedInstitution.parentId).name}
        </Typography>
      )}

      <Container className={classes.sectionDiv}>
        <Typography variant="h4">{selectedInstitution && selectedInstitution.users.length} subscribers</Typography>

        {selectedInstitution && (
          <CustomList
            itemsToList={selectedInstitution.users}
            urlModel="/person/view/"
          />
        )}
      </Container>
    </Paper>
  )
}

export default DetailsPane
