import React from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { List, ListItem, ListItemText, Divider, Chip } from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'

export default function CustomList({
  itemsToList, // items to be listed out
  handleItemSelect, // function to handle clicking the list item - if it's a link, CustomList won't have a handleSelect function
  urlModel, // to use for the 'to' prop of ListItem - link that the <a> tag points to
  showRemoved, // Boolean to toggle soft deleted items
  removedAssociationPath, // String path to deletedAt property in association
  customChips // Array of strings, where each string is a path to a property in the listed object - to be rendered as chips (See Person.js for example)
}) {
  let list = []

  if (itemsToList.length > 0) {
    if (showRemoved) {
      list = itemsToList
    } else if (!showRemoved && 'deletedAt' in itemsToList[0]) {
      list = itemsToList.filter(listItem => listItem.deletedAt === null)
    } else {
      // If we are not showing deleted items, but each item doesn't have a deletedAt property, then just show the list,
      // as this list is not soft-deletable.
      list = itemsToList
    }
  }

  return (
    <List>
      {list.length > 0 ? (
        // We order the list to show non-deleted items first and then in alphabetical order
        _.orderBy(list, ['deletedAt', 'name'], ['desc', 'asc']).map(listItem => (
          <div key={`${listItem.name}-${listItem.id}`}>
            <ListItem
              button
              onClick={handleItemSelect ? event => handleItemSelect(event, listItem, urlModel) : null }
              component={urlModel ? Link : null}
              to={urlModel ? `${urlModel}${listItem.id}` : ''}
              style={{ backgroundColor: (listItem.deletedAt || (listItem.subscription && listItem.subscription.deletedAt)) ? '#dcdcdc' : 'inherit' }}
            >
              <div style={{ marginRight: '5px' }}>
                <ListItemText primary={`${listItem.name}`} />

                {listItem.deletedAt || _.at(listItem, removedAssociationPath)[0] ? (
                  <Chip
                    label={`Removed${_.at(listItem, removedAssociationPath)[0] ? ' association' : ''}`}
                    variant="outlined"
                  />
                ) : null}
              </div>

              {/* Render list of chips, based on the custom chip paths provided */}
              {customChips &&
                customChips.map(
                  chipProperty =>
                    _.at(listItem, chipProperty.path)[0] && ( // _.at() gets us the value at the given path, where chipProperty is a string path to the required object key
                      <Chip
                        label={chipProperty.label || _.at(listItem, chipProperty.path)[0]}
                        variant="outlined"
                        key={`${listItem.id}-${chipProperty.path}`}
                        style={{ marginLeft: '15px' }}
                      />
                    )
                )}
            </ListItem>
            <Divider />
          </div>
        ))
      ) : (
        // Display an alert if there are no items to display
        <Alert severity="info">No items to display</Alert>
      )}
    </List>
  )
}
