import React from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'

const ShowRemovedSwitch = ({ checked,  handleChange,  name }) => (
  <FormControlLabel
    control={
      <Switch
        checked={checked}
        onChange={handleChange}
        color="primary"
        name={name}
        id={name}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    }
    label="Show removed items"
  />
)

export default ShowRemovedSwitch
