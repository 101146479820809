import databaseQueryInterface from '../../database/databaseQueryInterface'
import settings from '../../settings'
import { extractErrorData } from '../helpers'

export async function formatSubscriptionData(subscriptionData, user, openDialog) {
  try {
    const product = await databaseQueryInterface.get(
      `${settings.baseURL}/api/products/${subscriptionData.productId}`,
      user
    )

    return {
      success: true,
      subscription: {
        id: subscriptionData.productId,
        name: product.name,
        deletedAt: product.deletedAt,
        Subscription: {
          mailingPreference: subscriptionData.mailingPreference,
          emailType: subscriptionData.emailType,
          institutionId: subscriptionData.institutionId,
          packageId: subscriptionData.packageId
        }
      }
    }
  } catch (err) {
    const data = extractErrorData(err)

    openDialog({
      failedAction: `retrieve subscription data to save subscription`,
      data
    })

    return { success: false, error: data }
  }
}

export function mapCurrentValuesToForm(currentValues) {
  return {
    mailingPreference: currentValues.Subscription.mailingPreference,
    emailType: currentValues.Subscription.emailType,
    packageId: currentValues.Subscription.packageId,
    institutionId: currentValues.Subscription.institutionId,
    productId: currentValues.id
  }
}
