import { grey } from '@material-ui/core/colors'

export default function mailStyles(theme) {
  return {
    emailDetailsForm: {
      width: '25%',
      display: 'flex',
      flexDirection: 'column',
      // border: '1px solid red'
    },
    emailEditor: {
      width: '75%',
      display: 'flex',
      justifyContent: 'flex-end',
      // border: '1px solid red'
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      margin: theme.spacing(0.25)
    },
    tinyMCE: {
      display: 'none'
    },
    attachmentControls: {
      width: '100%',
      display: 'flex'
    },
    clearAttachmentsButton: {
      marginLeft: 'auto'
    },
    noFilesText: {
      width: '100%',
      color: grey[500]
    },
    fileChips: {
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(1.5),
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center'
    },
    fileChip: {
      margin: theme.spacing(0.25)
    },
    progressbar: {
      width: '50%',

      '& > * + *': {
        marginTop: theme.spacing(3)
      }
    },
    progressText: {
      marginTop: theme.spacing(1),
      color: '#b2bec3'
    },
    errorLog: {
      padding: theme.spacing(2),
      backgroundColor: '#fff',
      marginBottom: theme.spacing(3),
      maxHeight: '200px',
      overflowY: 'scroll'
    },
    errorHeading: {
      color: '#e17055',
      margin: '0',
      paddingBottom: theme.spacing(2)
    },
    errorRecord: {
      fontSize: '0.7rem'
    },
    table: {
      fontSize: '0.7rem'
    },
    errTableHeaders: {
      fontSize: '0.8rem'
    },
    errTableData: {
      fontSize: '0.8rem'
    },
    csvDetails: {
      fontSize: '0.9rem',
      padding: theme.spacing(1),
      margin: theme.spacing(2)
    },
    formSelect: {
      width: '15ch'
    },
    csvDetailFromField: {
      width: '40ch',
      minWidth: '30ch',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    csvFromLabel: {
      width: '12ch',
      marginLeft: theme.spacing(3)
    },
    csvFormActions: {
      fontSize: '0.8rem',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginRight: '10%',
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    csvFromButton: {
      marginLeft: theme.spacing(2)
    },
    csvContainer: {
      height: '150px'
    },
    formHeader: {
      marginBottom: '0',
      marginLeft: theme.spacing(3),
      fontWeight: '500'
    },
    formHelperText: {
      margin: '0',
      marginLeft: theme.spacing(3),
      fontSize: '0.7rem',
      color: 'gray'
    }
  }
}
