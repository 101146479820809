import { getDateString } from '../../helpers'

const shortDate = getDateString('en-GB', 'numeric', 'numeric', 'numeric')
const longDate = getDateString('en-GB', 'numeric', 'long', '2-digit')
// remove date part from something like /8 September 21/ or /15 September 21/
// split at first space and return last element
const longDateWithoutDate = getDateString('en-GB', 'numeric', 'long', 'numeric').split(" ").slice(1).join(" ")

let ttVersions = [
  "Local News Update - Economy",
  "Local News Update - Business & Sector",
  "Local News Update - Business & Sector with Forums",
  "All-In-One-Daily",
  "All-In-One-Daily - Special",

  "Sunday News Update - Economy",
  "Sunday News Update - Business & Sector",
].map(version => ({
  type: "TT", name: `${version} - ${shortDate}`
}))

let spotlights = [
  "Business Events Update",
  "Banking & Finance Weekly",
  "Real Estate Bi-Weekly",
  "Social Events Update",
  "Econ Op-eds in Summary",
].map(spotlight => ({
  type: "Spotlights", name: `Spotlight: ${spotlight} - ${longDate}`
}))

let monthlies = [
  "Consensus Monthly",
  "Deposit Rate Guide",
  "Frontier Economic Monthly",
  "Frontier Insights: Finance Company Sector Update",
  "Traders' Edge: Notes post Monetary Policy Review",
  "Frontier Inflation Review",
  "Frontier Inflation Preview",
].map(monthly => ({
  type: 'Monthlies', name: `${monthly} - ${longDateWithoutDate}`
}))

let quarterlies = [
  "Frontier Insights: Banking Sector Update - ",
  "Frontier Insights: Banking Sector Update - PB and HSBC - ",
  "Quarterly Deals, Transactions and Investments Update for Hemas - ",
  "Quarterly Deals, Transactions and Investments Update - ",
  "Banking Quarterly Financial Update - ",
].map(quarterly => ({
  type: 'Quarterlies', name: quarterly
}))

let other = [
  "Reinsurer Update",
  "Hospital Insights - Quick Update - Quarter Ended",
  "Equity Strategy Perspectives: ",
  "Global Customized Summary",
  "Global Customized Weekly",
].map(update => ({
  type: 'Other', name: `${update} - ${longDate}`
}))

export const mailData = {
  categories: {
    default: 'frontier-mail',
    timeTwister: [
      'Time Twister',
      'First Update',
      'Second Update',
      'Second Update with Forums',
      'All-in-One Daily',
      'First Update - Early Daily',
      'All-in-One Daily - Special',
      'Sunday News Update - Economy',
      'Sunday News Update - Business & Sector'
    ],
    curation: [
      'Econ Op-eds',
      'Social Events',
      'Business Events',
      'Banking & Finance',
      'Real Estate',
      'Politics Extra',
      'Consensus',
      'Reinsurer Update',
      'Deposit Rate Snapshot',
      'Frontier Best of the Web',
      'Hemas M&A Update',
      'M&A Update'
    ],
    customized: ['Consensus', 'Reinsurer Update'],
    reports: [
      'Consensus',
      'Reinsurer Update',
      'Frontier Economic Quarterly',
      'Hemas Economic Quarterly',
      'Frontier Economic Monthly',
      'Frontier Special Reports',
      'Post Data',
      'Pre Data',
      'Traders Edge',
      'Frontier Insights - Banking Quarterly',
      'Frontier Insights - Banking Supplementary Report',
      'Frontier Insights - LFC Quarterly',
      'Main Banks Combined Update',
      'Equity Strategy Reports',
      'Hostpital Insights'
    ]
  },
  subjects: [...ttVersions, ...spotlights, ...monthlies, ...quarterlies, ...other],
  fromEmails: {
    research: { name: 'Frontier Research', email: 'research@frontiergroup.info' },
    news: { name: 'Frontier Research News', email: 'news@frontiergroup.info' },
    timeTwister: { name: 'Time Twister', email: 'time.twister@frontiergroup.info' }
  },
  validEmailFormat: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}
