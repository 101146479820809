/* eslint-disable no-unused-vars */

import {
  amber,
  blue,
  blueGrey,
  brown,
  common,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow
} from '@material-ui/core/colors'

/* eslint-enable no-unused-vars */

const settings = {
  title: 'Frontier Research',

  baseURL: process.env.NODE_ENV !== 'development' ? 'https://frontier-internal.appspot.com' : 'http://localhost:4000',
  // baseURL: process.env.NODE_ENV !== 'development' ? 'https://frontier-internal.appspot.com' : 'https://frontier-internal.appspot.com',

  theme: {
    primaryColor: {
      name: 'blueGrey',
      import: blueGrey
    },
    secondaryColor: {
      name: 'red',
      import: red
    },
    type: 'light'
  },

  credentials: {
    firebase: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: 'frontier-internal.firebaseapp.com',
      databaseURL: 'https://frontier-internal.firebaseio.com',
      projectId: 'frontier-internal',
      storageBucket: 'frontier-internal.appspot.com',
      messagingSenderId: '632841321990',
      appId: '1:632841321990:web:5605c577d7944074'
    }
  }
}

export default settings
