export function mapCurrentValuesToForm(currentValues, category) {
  return {
    name: currentValues.name,
    description: currentValues.description,
    subCategoryId: currentValues.productSubcategoryId,
    categoryId: category && category.value, // Only add the category if it exists
    notes: currentValues.notes
  }
}

export function formatUsers(usersList) {
  const formattedUsers = usersList.map(user => {
    const subscribedEmail = user.Subscription.emailType

    return {
      id: user.id,
      name: user.name,
      email: user[`${subscribedEmail}Email`],
      mailingPreference: user.Subscription.mailingPreference,
      deletedAt: user.deletedAt,
      subscription: user.Subscription
    }
  })

  return formattedUsers
}

export function countActiveUsers(usersList) {
  return usersList.filter(user => !user.Subscription.deletedAt).length
}
