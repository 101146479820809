import React from 'react'
import { Formik } from 'formik'

import { withStyles, Button, Grid } from '@material-ui/core'

import { formStyles } from '../../../styles'
import { MultiSelectField } from '../../blocks'

const styles = theme => ({ ...formStyles(theme) })

const AddUsersForm = ({ classes, user, handleSubmit, currentValues, users }) => {
  const [initialValues, setInitialValues] = React.useState({
    subscribers: currentValues
  })

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12}>
              <MultiSelectField
                name="subscribers"
                fullWidth
                label="Subscribers"
                value={formik.values.subscribers}
                handleChange={selectedOption => {
                  formik.setFieldValue('subscribers', selectedOption)
                }}
                touched={formik.touched.subscribers}
                errors={formik.errors.subscribers}
                items={users || []}
              />
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default withStyles(styles)(AddUsersForm)
