import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import EmailIcon from '@material-ui/icons/Email'
import PeopleIcon from '@material-ui/icons/People'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BusinessIcon from '@material-ui/icons/Business'
import DescriptionIcon from '@material-ui/icons/Description'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'

import { PermissibleRender } from '../utilities'
import { Unauthorized } from '../pages'

import { navbarStyles } from '../../styles'

const styles = theme => ({ ...navbarStyles(theme) })

class NavDrawer extends Component {
  render() {
    const { open, onClose, classes, userRole } = this.props

    // Custom Drawer Link component to remove lots of repeated code
    const DrawerLink = ({ href, name, iconComponent }) => (
      <Link to={href}>
        <ListItem button key={name} onClick={onClose}>
          <ListItemIcon>{iconComponent}</ListItemIcon>
          <ListItemText primary={name} />
        </ListItem>
      </Link>
    )

    return (
      <Drawer open={open} onClose={onClose}>
        <div className={classes.list} role="presentation">
          <List>
            <DrawerLink href="/" name="Email" iconComponent={<EmailIcon />} />

            <DrawerLink href="/products" name="Products" iconComponent={<DescriptionIcon />} />

            <PermissibleRender
              userPermission={userRole}
              requiredPermissions={['editor', 'dev', 'remoteMember']}
            >

              <DrawerLink href="/institutions" name="Institutions" iconComponent={<BusinessIcon />} />

              <DrawerLink href="/packages" name="Packages" iconComponent={<BusinessCenterIcon />} />

              <PermissibleRender
                userPermission={userRole}
                requiredPermissions={['editor', 'dev']}
              >
                <DrawerLink href="/users" name="Users" iconComponent={<PeopleIcon />} />

                <DrawerLink href="/logs" name="Logs" iconComponent={<AssignmentIcon />} />
                
                <DrawerLink href="/changelogs" name="Changelogs" iconComponent={<TrackChangesIcon />} />
              </PermissibleRender>
            </PermissibleRender>
          </List>
        </div>
      </Drawer>
    )
  }
}

export default withStyles(styles)(NavDrawer)
