import React, { Component } from 'react'

import { Container, Paper, withStyles, Grid, Slide } from '@material-ui/core'

import DetailsPane from './DetailsPane'
import { AdminButtons, AlphabeticalPaginationBar, CustomList, ShowRemovedSwitch } from '../../blocks'
import { ConfirmationDialog } from '../../Dialogs'

import { commonStyles } from '../../../styles'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import { extractErrorData, getPaginationButtons, getDataForPage, algoliaDataUpdate } from '../../helpers'
import { ShowSkeleton } from '../../utilities'

const styles = theme => ({ ...commonStyles(theme) })

class Packages extends Component {
  static contextType = FunctionContext

  state = {
    allPackages: [],
    packagesToDisplay: [],
    paginationButtons: [],
    selectedPackage: null,
    openRemoveDialog: false,
    openDeleteDialog: false,
    showRemoved: false
  }

  componentDidMount() {
    this.getPackagesList()
  }

  getPackages = async () => {
    const { openDialog } = this.context
    const { user } = this.props

    try {
      const data = await databaseQueryInterface.get(`${settings.baseURL}/api/packages/?showDeleted=true`, user)

      const paginationButtons = getPaginationButtons(data)

      return { success: true, data, paginationButtons }
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve package records`,
        data
      })

      return { success: false, error: data }
    }
  }

  setPackageState = (data, paginationButtons) => {
    this.setState({ allPackages: data, paginationButtons, packagesToDisplay: data, selectedPackage: null })
  }

  getPackagesList = async () => {
    const { openSnackbar } = this.context

    const packageData = await this.getPackages()

    if (packageData.success) {
      const { data, paginationButtons } = packageData

      this.setPackageState(data, paginationButtons)

      openSnackbar(`Retrieved ${data.length} packages`)
    }
  }

  handlePaginationSelect = (event, buttonValue) => {
    const { allPackages } = this.state

    const packagesToDisplay = getDataForPage(buttonValue, allPackages)

    this.setState({ packagesToDisplay })
  }

  handlePackageSelect = (event, selectedItem) => {
    this.setState({ selectedPackage: selectedItem })

     // scroll to top (200px from top)
     document.body.scrollTop = 200; // For Safari
     document.documentElement.scrollTop = 200; // For Chrome, Firefox, IE and Opera
  }

  handleDetailsClose = () => this.setState({ selectedPackage: null })

  handleAdd = () => {
    const { history } = this.props

    history.push('/packages/create')
  }

  handleEdit = () => {
    const { history } = this.props
    const { selectedPackage } = this.state

    history.push(`/packages/edit/${selectedPackage.id}`)
  }

  handleRemoveDialogOpen = () => this.setState({ openRemoveDialog: true })

  handleRemoveDialogClose = () => this.setState({ openRemoveDialog: false })

  handleDeleteDialogOpen = () => this.setState({ openDeleteDialog: true })

  handleDeleteDialogClose = () => this.setState({ openDeleteDialog: false })

  handleShowRemovedSwitchChange = () => this.setState(prevState => ({ showRemoved: !prevState.showRemoved }))

  handleRemove = async () => {
    const { openDialog } = this.context
    const { user } = this.props
    const { selectedPackage } = this.state

    try {
      const data = await databaseQueryInterface.put(
        `${settings.baseURL}/api/packages/${selectedPackage.id}/remove`, // This route acts as a toggle
        user
      )

      this.setState({ openRemoveDialog: false })

      const { id, name, deletedAt } = data

      await this.updateUi(data, `${data.deletedAt === null ? 'Restored' : 'Removed'}`)
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `${data.deletedAt === null ? 'remove' : 'restore'} package`,
        data
      })
    }
  }

  handleDelete = async () => {
    const { openDialog } = this.context
    const { user } = this.props
    const { selectedPackage } = this.state

    try {
      const data = await databaseQueryInterface.destroy(`${settings.baseURL}/api/packages/${selectedPackage.id}`, user)

      this.setState({ openDeleteDialog: false })

      algoliaDataUpdate({
        objectID: data.id,
        index: 'packages',
        operation: 'delete'
      })

      await this.updateUi(data, 'Permanently deleted')
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `delete package`,
        data
      })
    }
  }

  updateUi = async (packageData, action) => {
    const { openSnackbar } = this.context

    const newListOfPackages = await this.getPackages()

    if (newListOfPackages.success) {
      const { data, paginationButtons } = newListOfPackages

      this.setPackageState(data, paginationButtons)

      openSnackbar(`${action} package: ${packageData.name}`)
    }
  }

  render() {
    const { userRole, classes } = this.props
    const {
      allPackages,
      packagesToDisplay,
      paginationButtons,
      selectedPackage,
      openRemoveDialog,
      openDeleteDialog,
      showRemoved
    } = this.state

    return (
      <Container>
        <Grid container direction="column" justifyContent="space-around" alignItems="center" spacing={5}>
          <AdminButtons userRole={userRole} classes={classes} includeAdd handleAddClick={this.handleAdd} />

          <Grid item xs={12}>
            <AlphabeticalPaginationBar
              paginationButtons={paginationButtons}
              handlePaginationSelect={this.handlePaginationSelect}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={3} justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={12}>
            <ShowRemovedSwitch
              checked={showRemoved}
              handleChange={this.handleShowRemovedSwitchChange}
              name="showRemoved"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ShowSkeleton variant="rect" height={400} condition={allPackages.length === 0}>
              <Paper className={classes.paperStyles}>
                <CustomList
                  itemsToList={packagesToDisplay}
                  handleItemSelect={this.handlePackageSelect}
                  showRemoved={showRemoved}
                />
              </Paper>
            </ShowSkeleton>
          </Grid>

          <Slide direction="left" timeout={300} in={!!selectedPackage} mountOnEnter unmountOnExit>
            <Grid item xs={12} md={6}>
              <DetailsPane
                classes={classes}
                handleClose={this.handleDetailsClose}
                selectedPackage={selectedPackage}
                userRole={userRole}
                handleEdit={this.handleEdit}
                handleRemove={this.handleRemoveDialogOpen}
                handleDelete={this.handleDeleteDialogOpen}
              />
            </Grid>
          </Slide>
        </Grid>

        <ConfirmationDialog
          open={openRemoveDialog}
          closeDialog={this.handleRemoveDialogClose}
          text={`Are you sure you want to ${
            selectedPackage && selectedPackage.deletedAt === null ? 'remove' : 'restore'
          } "${selectedPackage && selectedPackage.name}"?`}
          handleConfirm={this.handleRemove}
        />

        <ConfirmationDialog
          open={openDeleteDialog}
          closeDialog={this.handleDeleteDialogClose}
          text={`Are you sure you want to delete "${selectedPackage && selectedPackage.name}"?`}
          handleConfirm={this.handleDelete}
          extraSecurity
        />
      </Container>
    )
  }
}

export default withStyles(styles)(Packages)
