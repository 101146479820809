import React from 'react'

import { withStyles, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core'

import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

const CheckboxField = ({ checked, handleChange, name, label, touched, errors, classes }) => (
  <>
    <FormControlLabel
      control={<Checkbox id={name} checked={checked} onChange={handleChange} name={name} />}
      label={label}
      className={classes.field}
    />
    {touched && errors && <FormHelperText>{errors}</FormHelperText>}
  </>
)

export default withStyles(styles)(CheckboxField)
