import React, { Component } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion'
import _ from 'lodash'

export default class CSVReviewList extends Component {
  render() {
    const { csvData, csvStatus } = this.props

    const emptyAccordionText = <div className="empty-accordion">Choose a csv file to review the list</div>
    const csvFormAccordionText = <div className="empty-accordion">Select from csv file to review the list</div>

    if (csvStatus === 'processed' && csvData.length !== 0 && csvData[0]['To and Cc'] === undefined) {
      alert(
        'Wrong CSV format! \nCSV must contain the columns: "Company", "To and Cc" and "BCc"\nThese field names are case sensitve and must be given in that order. \nPlease select a valid CSV file.'
      )
      return emptyAccordionText
    }

    if (csvData.length !== 0 && csvStatus === 'processed') {
      return (
        <Accordion allowZeroExpanded>
          {csvData.map(data => (
            <AccordionItem key={data.Company}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {`${data.Company} --- [CC: ${data['To and Cc'].length} BCC: ${data.BCc.length}]`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <table className="outter-table">
                  <thead>
                    <tr>
                      <th>To & CC:</th>
                      <th>BCC:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="email-container">
                        <table className="innter-tocc-table">
                          <tbody>
                            {_.uniq(data['To and Cc']).map(email => (
                              <tr key={email}>
                                <td className="innter-table-data">{email}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                      <td className="email-container">
                        <table className="innter-bcc-table">
                          <tbody>
                            {_.uniq(data.BCc).map(email => (
                              <tr key={email}>
                                <td className="innter-table-data">{email}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )
    }

    if (csvStatus === 'processing') {
      return csvFormAccordionText
    }
    return emptyAccordionText
  }
}
