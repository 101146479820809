import React, { Component } from 'react'
import { Container, Typography, Box } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import { unauthorizedStyles } from '../../../styles'

const styles = theme => ({ ...unauthorizedStyles(theme) })

class Unauthorized extends Component {
  render() {
    const { classes } = this.props

    return (
      <Container maxWidth="lg" className={classes.background}>
        <Box>
          <Typography className={classes.textHeading} variant="h1" gutterBottom>
            <strong>403</strong>
          </Typography>

          <Typography className={classes.text} variant="h3" gutterBottom>
            You are not authorized to access this page!
            <span role="img" aria-label="thinking-emoji" className={classes.emoji}>
              🙅🏽‍♀️
            </span>
          </Typography>
        </Box>
      </Container>
    )
  }
}

export default withStyles(styles)(Unauthorized)
