export default function MapHeadersToStandardFormat(csvFieldsOnInput) {
  // csvFieldsOnInput = Array of column headers from the CSV that was uploaded

  const standardMailingListColumns = {
    Company: '', // String containing the fieldName from the CSV that correcponds to the standard Company Field
    'To and Cc': '', // String containing the fieldName from the CSV that correcponds to the standard 'To and Cc' Field
    BCc: '' // String containing the fieldName from the CSV that correcponds to the standard BCc Field
  }

  csvFieldsOnInput.forEach(fieldName => {
    if (fieldName.toLowerCase() === 'company') return (standardMailingListColumns.Company = fieldName)

    if (fieldName.toLowerCase() === 'group') return (standardMailingListColumns.Company = fieldName)

    if (fieldName.toLowerCase() === 'to and cc') return (standardMailingListColumns['To and Cc'] = fieldName)

    if (fieldName.toLowerCase() === 'bcc') return (standardMailingListColumns.BCc = fieldName)
  })

  return standardMailingListColumns
}
