import React, { Component } from 'react'

export default class ErrorNotFound extends Component {
  render() {
    return (
      <div className="error">
        <h1 className="notFoundTitle">Oops! That page can’t be found.</h1>
        <p className="notFoundDesc">
          It looks like nothing was found at this url. Press back to go to the previous page.
        </p>
      </div>
    )
  }
}
