import databaseQueryInterface from '../../database/databaseQueryInterface'
import settings from '../../settings'
import { extractErrorData } from '../helpers'

export async function getFirebaseDetails(primaryEmail, user) {
  if (!primaryEmail) return // If there is no email entered, don't run this function

  try {
    // Check for firebase user
    const { uid, email } = await databaseQueryInterface.get(
      `${settings.baseURL}/api/users/user?email=${primaryEmail}`,
      user
    )

    return { success: true, uid, message: `Athena account for ${email} exists` } // Return the uid of the user to set it in formik
  } catch (err) {
    const data = extractErrorData(err)

    return { success: false, error: data }
  }
}

export function mapCurrentValuesToForm(currentValues) {
  return {
    name: currentValues.name,
    primaryEmail: currentValues.primaryEmail || "",
    secondaryEmail: currentValues.secondaryEmail || "",
    designation: currentValues.designation || "", // if values are null in database
    firebaseuid: currentValues.firebaseuid || "",
    phoneNumber: currentValues.phoneNumber || "",
    status: currentValues.status.id || "",
    starred: currentValues.starred || false,
    handler: currentValues.handler || 0,
    institutions: currentValues.institutions || [],
    subscriptions: currentValues.products || [],
    notes: currentValues.notes || ""
  }
}

export async function getInitialFormData(user, openDialog) {
  try {
    const allUserStatuses = await databaseQueryInterface.get(`${settings.baseURL}/api/user-statuses`, user)
    const allHandlers = await databaseQueryInterface.get(`${settings.baseURL}/api/users-new/get-handlers`, user)
    const packages = await databaseQueryInterface.get(`${settings.baseURL}/api/packages`, user)

    const statusList = []
    const handlersList = []

    allUserStatuses.forEach(status => {
      statusList.push({ name: status.name, value: status.id })
    })

    allHandlers.forEach(handler => {
      handlersList.push({ name: handler.name, value: handler.id })
    })

    return { success: true, statusList, handlersList, packages }
  } catch (err) {
    const data = extractErrorData(err)
    openDialog({
      failedAction: `retrieve initial form data to populate fields`,
      data
    })

    return { success: false, error: data }
  }
}
