import React, { useState, useEffect, useContext } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import pluralize from 'pluralize'

import { Button, FormHelperText, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { validationSchema, initialFormData } from './formSetup'

import { FunctionContext } from '../../contexts'
import { extractErrorData, formatDataForAutocomplete } from '../../helpers'
import { mapCurrentValuesToForm } from '../../lib/subscription'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'

import { SelectField } from '../../blocks'

const SubscriptionForm = ({
  handleSubmit,
  currentValues,
  currentInstitutions,
  currentProducts,
  hasSecondaryEmail,
  classes,
  loggedInUser
}) => {
  const [initialValues, setInitialValues] = useState(initialFormData)

  const [subscribedPackages, setSubscribedPackages] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [usersLeft, setUsersLeft] = useState(null)

  const { openDialog } = useContext(FunctionContext)

  const getPackages = async institutionId => {
    try {
      const { packages } = await databaseQueryInterface.get(
        `${settings.baseURL}/api/institutions/${institutionId}/products`,
        loggedInUser
      )

      const packagesList = []

      // packages takes the shape [ { id, name, products: [ { id, name } ] } ]
      packages.map(packageItem =>
        packagesList.push({
          name: packageItem.name,
          value: packageItem.id,
          products: packageItem.products, // Used to filter products - see getProducts function below
          numUsersAllowed: packageItem.PackageDetails.numUsersAllowed // Used to get num Users left - see getNumUsers function below
        })
      )

      return packagesList
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve list of Packages and Products for the package and product fields`,
        data
      })
    }
  }

  const getProducts = (packageId, listOfPackages) => {
    if (!packageId) return

    const { products } = _.find(listOfPackages, packageItem => packageItem.value === packageId)

    return formatDataForAutocomplete(products)
  }

  // Effect to update the form with the new values during an edit action
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    async function updateSubscriptionData() {
      if (currentValues) {
        // Current values is a object for a product, that takes the shape:
        // { id, name, deletedAt, Subscription: { mailingPreference, emailType, institutionId, packageId } }
        const currentData = mapCurrentValuesToForm(currentValues)

        if (isMounted) {
          setInitialValues(currentData)
        }
      }
    }

    updateSubscriptionData()

    return () => {
      isMounted = false
    }
  }, [currentValues])

  // Function to notify user on how many more users are allowed on the selected package.
  // We need the product id, as the subscriptions model on the backend only records productId
  const getNumUsers = async (institutionId, packageId) => {
    if (!packageId) return setUsersLeft(null) // If the user clears the package field, clear the usersLeft variable

    try {
      const { subscriptionCount } = await databaseQueryInterface.get(
        `${settings.baseURL}/api/institutions/${institutionId}/packages/${packageId}/count`,
        loggedInUser
      )

      const { numUsersAllowed } = _.find(subscribedPackages, packageItem => packageItem.value === packageId)

      const leftUsers = (numUsersAllowed - subscriptionCount) <= 0 // if minus or zero, set zero. Else, set actual number
                          ? 0 
                          : numUsersAllowed - subscriptionCount

      setUsersLeft(leftUsers)
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve number of subscriptions to selected product`,
        data
      })
    }
  }

  const getEmailTypeOptions = () => {
    if (hasSecondaryEmail)
      return [{ name: 'Primary email', value: 'primary' }, { name: 'Secondary email', value: 'secondary' }]

    return [{ name: 'Primary email', value: 'primary' }]
  }

  const getDisabledProducts = () => {
    if (!currentProducts) return []

    return currentProducts.filter(product => !product.Subscription.deletedAt).map(product => product.id)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
      validationSchema={validationSchema}
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="stretch">
            <Grid item xs={12}>
              <SelectField
                fullWidth
                name="mailingPreference"
                label="Mailing Preference"
                items={[
                  { name: 'Cc', value: 'cc' },
                  { name: 'BCc', value: 'bcc' },
                  { name: 'Google Groups', value: 'googleGroups' }
                ]}
                value={formik.values.mailingPreference}
                handleChange={selectedOption => {
                  formik.setFieldValue('mailingPreference', selectedOption || '')
                }}
                touched={formik.touched.mailingPreference}
                errors={formik.errors.mailingPreference}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectField
                fullWidth
                name="emailType"
                label="Email Address Type"
                items={getEmailTypeOptions()}
                value={formik.values.emailType}
                handleChange={selectedOption => {
                  formik.setFieldValue('emailType', selectedOption || '')
                }}
                touched={formik.touched.emailType}
                errors={formik.errors.emailType}
              />
            </Grid>

            {!currentValues && (
              <>
                <Grid item xs={12}>
                  {currentInstitutions.length === 0 && (
                    <Alert>Please add an Institution to this user to add a subscription</Alert>
                  )}

                  <SelectField
                    fullWidth
                    name="institutionId"
                    label="Institution"
                    items={currentInstitutions.map(institution => ({ name: institution.name, value: institution.id }))}
                    value={formik.values.institutionId}
                    handleChange={selectedOption => {
                      formik.setFieldValue('institutionId', selectedOption || '')
                      if (selectedOption) {
                        // Only get packages if an institution is selected
                        getPackages(selectedOption).then(packages => setSubscribedPackages(packages))
                      } else {
                        setSubscribedPackages([]) // If the user clears the Institution field, clear subscribedPackages
                        setAllProducts([]) // If the user clears the Institution field, clear allProducts
                      }
                    }}
                    touched={formik.touched.institutionId}
                    errors={formik.errors.institutionId}
                  />
                </Grid>

                <Grid item xs={12}>
                  {subscribedPackages.length > 0 ? (
                    // If there are packages to display, load a select field
                    <>
                      <SelectField
                        fullWidth
                        name="packageId"
                        label="Package"
                        items={subscribedPackages}
                        value={formik.values.packageId}
                        handleChange={selectedOption => {
                          formik.setFieldValue('packageId', selectedOption || '')
                          if (selectedOption) {
                            getNumUsers(formik.values.institutionId, selectedOption) // Find remaining number of users allowed

                            const products = getProducts(selectedOption, subscribedPackages) // Only get products if a package is selected
                            setAllProducts(products)
                          } else {
                            setAllProducts([]) // If the user clears the Package field, clear allProducts
                          }
                        }}
                        touched={formik.touched.packageId}
                        errors={formik.errors.packageId}
                      />

                      {usersLeft !== null && (
                        <FormHelperText style={{ color: usersLeft === 0 ? 'red' : 'inherit' }}>
                          This Institution has {usersLeft} {pluralize('slot', usersLeft)} left in this package.
                          {usersLeft === 0 && ' If you proceed, the system will increase the number of slots to accomodate this user.'}
                        </FormHelperText>
                      )}
                    </>
                  ) : (
                    // If there are no packages to display, load an alert with possible reasons
                    // Reason changes based on if a Institution has been selected or not.
                    <Alert severity="info">
                      No Packages to display.{' '}
                      {!formik.values.institutionId && 'Select an Institution to load their packages'}
                    </Alert>
                  )}
                </Grid>

                <Grid item xs={12}>
                  {allProducts.length > 0 ? (
                    // If there are products to display, load a select field
                    <SelectField
                      fullWidth
                      name="productId"
                      label="Product"
                      items={allProducts}
                      value={formik.values.productId}
                      disabled={currentInstitutions.length === 0}
                      disabledOptionIds={getDisabledProducts()}
                      handleChange={selectedOption => {
                        formik.setFieldValue('productId', selectedOption || '')
                      }}
                      touched={formik.touched.productId}
                      errors={formik.errors.productId}
                    />
                  ) : (
                    // If there are no products to display, load an alert with possible reasons
                    // Reason changes based on if a Package has been selected or not.
                    <Alert severity="info">
                      No products to display. {!formik.values.packageId && 'Select a Package to load their products'}
                    </Alert>
                  )}
                </Grid>
              </>
            )}
          </Grid>

          <div className={classes.buttonContainer}>
            <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default SubscriptionForm
