import React, { Component } from 'react'

import { withStyles, Container, Typography, Paper, Grid, CircularProgress, Chip } from '@material-ui/core'

import { AlphabeticalPaginationBar, AdminButtons, CustomList, ShowRemovedSwitch } from '../../blocks'
import { ConfirmationDialog } from '../../Dialogs'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import {
  extractErrorData,
  getPaginationButtons,
  getDataForPage,
  filterRemovedItems,
  algoliaDataUpdate,
  formatAsCurrency
} from '../../helpers'
import { FunctionContext } from '../../contexts'
import settings from '../../../settings'
import { commonStyles } from '../../../styles'

const styles = theme => ({ ...commonStyles(theme) })

class Package extends Component {
  static contextType = FunctionContext

  state = {
    packageData: null,
    openDeleteDialog: false,
    openRemoveDialog: false,
    paginationButtons: [],
    allInstitutions: [],
    institutionsToDisplay: null,
    showRemovedInstitutions: false
  }

  componentDidMount() {
    this.getPackageData()
  }

  getPackageData = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params }
    } = this.props

    try {
      const data = await databaseQueryInterface.get(
        `${settings.baseURL}/api/packages/${params.id}?showDeletedProducts=true&showDeleted=true`,
        user
      )

      this.handleFilterInstitutions(data.institutions, data.deletedAt && true)

      this.setState({ packageData: data, allInstitutions: data.institutions }, () =>
        openSnackbar(`Retrieved data for ${data.name}`)
      )
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve package data`,
        data
      })
    }
  }

  handlePaginationSelect = (event, buttonValue) => {
    const { packageData } = this.state

    const institutionsToDisplay = getDataForPage(buttonValue, packageData.institutions)

    this.setState({ institutionsToDisplay })
  }

  removePackage = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params }
    } = this.props

    try {
      const data = await databaseQueryInterface.put(`${settings.baseURL}/api/packages/${params.id}/remove`, user)

      openSnackbar(
        `The package "${data.name}" was successfully ${
          data.deletedAt === null ? 'restored' : 'removed'
        }. This action is reversible`
      )

      this.setState({ openRemoveDialog: false }, () => this.getPackageData())
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `${data.deletedAt === null ? 'remove' : 'restore'} package`,
        data
      })
    }
  }

  deletePackage = async () => {
    const { openDialog } = this.context
    const { user, history } = this.props
    const { packageData } = this.state

    try {
      const data = await databaseQueryInterface.destroy(`${settings.baseURL}/api/packages/${packageData.id}`, user)

      this.setState({ openDeleteDialog: false })

      algoliaDataUpdate({
        objectID: data.id,
        index: 'packages',
        operation: 'delete'
      })

      history.goBack()
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `delete package`,
        data
      })
    }
  }

  handleFilterInstitutions = (listOfinstitutions, showRemovedInstitutions = false) => {
    const filteredInstitutions = filterRemovedItems(listOfinstitutions, 'deletedAt', showRemovedInstitutions)

    this.setState({ institutionsToDisplay: filteredInstitutions }, () => {
      const { institutionsToDisplay } = this.state

      const paginationButtons = getPaginationButtons(institutionsToDisplay)

      this.setState({ paginationButtons })
    })
  }

  handleShowRemovedInstitutionSwitchChange = () =>
    this.setState(
      prevState => ({ showRemovedInstitutions: !prevState.showRemovedInstitutions }),
      () => {
        const { allInstitutions, showRemovedInstitutions } = this.state

        this.handleFilterInstitutions(allInstitutions, showRemovedInstitutions)
      }
    )

  handleRemoveDialogOpen = () => this.setState({ openRemoveDialog: true })

  handleRemoveDialogClose = () => this.setState({ openRemoveDialog: false })

  handleDeleteDialogOpen = () => this.setState({ openDeleteDialog: true })

  handleDeleteDialogClose = () => this.setState({ openDeleteDialog: false })

  render() {
    const { classes, userRole, history } = this.props
    const {
      packageData,
      openDeleteDialog,
      openRemoveDialog,
      paginationButtons,
      institutionsToDisplay,
      showRemovedInstitutions
    } = this.state

    return (
      <Container>
        {packageData ? (
          <Grid container justifyContent="space-between" alignItems="flex-start" spacing={3}>
            {/* Product info row */}
            <Grid item xs={12} md={6}>
              <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={3}>
                <Grid item>
                  <Paper className={classes.paperStyles}>
                    <div className={classes.rightAlignedButtonContainer}>
                      <AdminButtons
                        userRole={userRole}
                        classes={classes}
                        item={packageData}
                        handleEditClick={() => history.push(`/packages/edit/${packageData.id}`)}
                        handleRemoveClick={() => this.handleRemoveDialogOpen()}
                        handleDeleteClick={() => this.handleDeleteDialogOpen()}
                      />
                    </div>

                    <Typography variant="h1">
                      {packageData.name}

                      {packageData.deletedAt ? (
                        <Chip label="Removed" color="secondary" variant="outlined" style={{ marginLeft: '10px' }} />
                      ) : null}
                    </Typography>

                    <Typography>{packageData.description}</Typography>

                    <Container className={classes.sectionDiv}>
                      <Typography variant="overline">
                        <b>Price:</b> {formatAsCurrency(packageData.price)}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>

                <Grid item>
                  <Paper className={classes.paperStyles}>
                    <Typography variant="h2">Products in this package</Typography>

                    <CustomList
                      itemsToList={packageData.products}
                      urlModel="/products/view/"
                      showRemoved
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            {/* People info column */}
            <Grid item xs={12} md={6}>
              <Paper className={classes.paperStyles}>
                <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={3}>
                  <ShowRemovedSwitch
                    checked={showRemovedInstitutions}
                    handleChange={this.handleShowRemovedInstitutionSwitchChange}
                    name="showRemoved"
                  />

                  <Typography variant="h2">Subscribed Institutions</Typography>

                  <Grid item>
                    <Grid container direction="column" justifyContent="space-between" alignItems="center" spacing={3}>
                      <Grid item>
                        <AlphabeticalPaginationBar
                          paginationButtons={paginationButtons}
                          handlePaginationSelect={this.handlePaginationSelect}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <CustomList
                      itemsToList={institutionsToDisplay}
                      urlModel="/institutions/view/"
                      showRemoved={showRemovedInstitutions}
                      removedAssociationPath="PackageDetails.deletedAt"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <ConfirmationDialog
              open={openRemoveDialog}
              closeDialog={this.handleRemoveDialogClose}
              text={`Are you sure you want to ${packageData.deletedAt ? 'restore' : 'remove'} "${packageData.name}"?`}
              handleConfirm={this.removePackage}
            />

            <ConfirmationDialog
              open={openDeleteDialog}
              closeDialog={this.handleDeleteDialogClose}
              text={`Are you sure you want to delete "${packageData.name}"?`}
              handleConfirm={this.deletePackage}
              extraSecurity
            />
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </Container>
    )
  }
}

export default withStyles(styles)(Package)
