import React, { useEffect, useContext } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import moment from 'moment'

import { withStyles, Button, Grid, Typography, Divider, FormControlLabel, Switch } from '@material-ui/core'

import { PackageDetailForm } from '..'
import { FormDialog, ConfirmationDialog } from '../../Dialogs'
import { SingleLineTextField, DatePickerField, MultiLineTextField, SelectField, CustomAccordion } from '../../blocks'

import { validationSchema, initialFormData } from './formSetup'

import { formStyles, commonStyles } from '../../../styles'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { addOrEditDataInFormPages, deleteData, extractErrorData, filterRemovedItems } from '../../helpers'
import { mapCurrentValuesToForm } from '../../lib/institution'
import { FunctionContext } from '../../contexts'

const styles = theme => ({ ...formStyles(theme), ...commonStyles(theme) })

const InstitutionForm = ({ classes, user, handleSubmit, currentValues }) => {
  const [allInstitutions, setAllInstitutions] = React.useState(null)
  const [initialValues, setInitialValues] = React.useState(initialFormData)

  const [openPackageDetailForm, setOpenPackageDetailForm] = React.useState(false)
  const [openPackageDeleteForm, setOpenPackageDeleteForm] = React.useState(false)
  const [showDeleted, setShowDeleted] = React.useState(false)
  const [selectedPackage, setSelectedPackage] = React.useState(null)

  const { openDialog } = useContext(FunctionContext)

  // Effect to load the list of institutions to populate parent company field
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    // useEffect can only return a function (not an async function),
    // So, we create an async function and call it immediately to avoid errors
    async function fetchInstitutionsList() {
      try {
        const data = await databaseQueryInterface.get(`${settings.baseURL}/api/institutions`, user)

        const institutionsList = []

        data.forEach(institution => {
          institutionsList.push({ name: institution.name, value: institution.id })
        })

        if (isMounted) setAllInstitutions(institutionsList)
      } catch (err) {
        const data = extractErrorData(err)
        openDialog({
          failedAction: `retrieve list of Institutions for Parent Company field`,
          data
        })
      }
    }

    fetchInstitutionsList()

    return () => {
      isMounted = false
    }
  }, [openDialog, user])

  // Effect to update the form with the new values during an edit action
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updateInstitutionData() {
      if (currentValues) {
        const currentData = mapCurrentValuesToForm(currentValues)

        if (isMounted) setInitialValues(currentData)
      }
    }

    updateInstitutionData()

    return () => {
      isMounted = false
    }
  }, [currentValues])

  const handlePackageSubmit = (packageData, formikPackages, selectedValue) => addOrEditDataInFormPages(packageData, formikPackages, selectedValue)

  const handlePackageDelete = (packageData, formikPackages) => deleteData(packageData, formikPackages)

  const packagesToDisplay = formikPackages => {
    // Ensures that all packages are always sorted by their active status first and then by name
    const allPackages = _.orderBy(formikPackages, ['PackageDetails.deletedAt', 'name'], ['desc', 'asc'])

    return filterRemovedItems(allPackages, 'PackageDetails.deletedAt', showDeleted)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
        validationSchema={validationSchema}
        onSubmit={values => {
          handleSubmit(values)
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start">
              <Grid item xs={12} md={6}>
                <SingleLineTextField
                  fullWidth
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  handleChange={formik.handleChange}
                  touched={formik.touched.name}
                  errors={formik.errors.name}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DatePickerField
                  name="joinDate"
                  fullWidth
                  label="Join Date"
                  value={formik.values.joinDate}
                  handleChange={date => {
                    formik.setFieldValue('joinDate', date._d)
                  }}
                  touched={formik.touched.joinDate}
                  errors={formik.errors.joinDate}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SingleLineTextField
                      name="industry"
                      fullWidth
                      label="Industry"
                      value={formik.values.industry}
                      handleChange={formik.handleChange}
                      touched={formik.touched.industry}
                      errors={formik.errors.industry}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SelectField
                      name="parentId"
                      fullWidth
                      label="Parent Company"
                      value={formik.values.parentId}
                      handleChange={selectedOption => {
                        formik.setFieldValue('parentId', selectedOption || '')
                      }}
                      touched={formik.touched.parentId}
                      errors={formik.errors.parentId}
                      items={allInstitutions || []}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SingleLineTextField
                      fullWidth
                      name="size"
                      label="Size"
                      value={formik.values.size}
                      handleChange={formik.handleChange}
                      touched={formik.touched.size}
                      errors={formik.errors.size}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SingleLineTextField
                      fullWidth
                      name="taxNumber"
                      label="Tax Number"
                      value={formik.values.taxNumber}
                      handleChange={formik.handleChange}
                      touched={formik.touched.taxNumber}
                      errors={formik.errors.taxNumber}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <SingleLineTextField
                  fullWidth
                  name="address"
                  label="Address"
                  value={formik.values.address}
                  handleChange={formik.handleChange}
                  touched={formik.touched.address}
                  errors={formik.errors.address}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <MultiLineTextField
                  fullWidth
                  name="notes"
                  label="Notes"
                  value={formik.values.notes}
                  handleChange={formik.handleChange}
                  touched={formik.touched.notes}
                  errors={formik.errors.notes}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.sectionDivider}>
                <Divider />
                <Typography variant="h2" className={classes.sectionHeader}>
                  Packages
                </Typography>

                <Button
                  variant="contained"
                  className={classes.actionButton}
                  onClick={() => {
                    setOpenPackageDetailForm(true)
                  }}
                >
                  Add Package
                </Button>
              </div>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDeleted}
                    onChange={() => {
                      setShowDeleted(!showDeleted)
                    }}
                    color="primary"
                    name="showDeleted"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Show removed items"
              />

              {packagesToDisplay(formik.values.packages).map(packageItem => (
                <CustomAccordion
                  key={packageItem.id}
                  title={packageItem.name}
                  classes={classes}
                  data={packageItem}
                  removedStatusPath="PackageDetails.deletedAt"
                  customChips={[
                    {
                      label: `${packageItem.PackageDetails.numUsersAllowed} users allowed`,
                      path: 'PackageDetails.numUsersAllowed'
                    },
                    {
                      label: `${packageItem.PackageDetails.discount}% discount applied`,
                      path: 'PackageDetails.discount'
                    },
                    { label: 'Trial', path: 'PackageDetails.trial' }
                  ]}
                  handleEdit={() => {
                    setSelectedPackage(packageItem)
                    setOpenPackageDetailForm(true)
                  }}
                  handleDelete={() => {
                    setSelectedPackage(packageItem)
                    setOpenPackageDeleteForm(true)
                  }}
                >
                  <>
                    <div className={classes.accordionDetailColumn}>
                      <Typography>
                        <strong>Subscription Start Date:</strong>{' '}
                        {moment(new Date(packageItem.PackageDetails.startDate).toISOString()).format('Do MMM YYYY')}
                      </Typography>
                    </div>

                    {packageItem.PackageDetails.trial && (
                      <div className={classes.accordionDetailColumn}>
                        <Typography>
                          <strong>Trial End Date:</strong>{' '}
                          {moment(new Date(packageItem.PackageDetails.trialEndDate).toISOString()).format(
                            'Do MMM YYYY'
                          )}
                        </Typography>
                      </div>
                    )}
                  </>
                </CustomAccordion>
              ))}
            </Grid>

            <div className={classes.buttonContainer}>
              <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
                Submit
              </Button>
            </div>

            <FormDialog
              fullScreen
              open={openPackageDetailForm}
              handleClose={() => {
                setOpenPackageDetailForm(false)
              }}
              classes={classes}
              title="Add a new Package to this Institution"
            >
              <PackageDetailForm
                user={user}
                existingPackages={_.map(formik.values.packages, 'id')}
                handleSubmit={formData => {
                  const newData = handlePackageSubmit(formData, formik.values.packages, selectedPackage)
                  formik.setFieldValue('packages', newData)
                  setOpenPackageDetailForm(false)
                }}
                currentValues={selectedPackage}
              />
            </FormDialog>

            <ConfirmationDialog
              open={openPackageDeleteForm}
              closeDialog={() => {
                setOpenPackageDeleteForm(false)
              }}
              text={`Are you sure you want to ${
                selectedPackage && selectedPackage.PackageDetails.deletedAt ? 'restore' : 'delete'
              } "${selectedPackage && selectedPackage.name}"?`}
              handleConfirm={() => {
                const newPackageData = handlePackageDelete(selectedPackage, formik.values.packages)
                formik.setFieldValue('packages', newPackageData)
                setSelectedPackage(null)
                setOpenPackageDeleteForm(false)
              }}
            />
          </form>
        )}
      </Formik>
    </>
  )
}

export default withStyles(styles)(InstitutionForm)
