export default function logsStyles(theme) {
  return {
    mainContainer: {
      minHeight: '70vh', // appropiate height so the loading spinner is on the center
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    formControls: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-End',
      alignItems: 'flex-end',
      margin: theme.spacing(1)
    },
    formControl: {
      margin: theme.spacing(0, 3),
      minWidth: 240
    },
    logsContainer: {
      minWidth: '100%',
      minHeight: '70vh',
      padding: '2%',
      fontSize: '1.2em'
    }
  }
}
