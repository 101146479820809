import React from 'react'

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

export default function ErrorTable({ errors, badFormats, duplicates, classes }) {
  function displayErrors(row) {
    if (row.type === 'duplicate error') {
      if (row.errors.count)
        return (
          <span style={{ display: 'block' }}>
            {row.errors.email} appeared {row.errors.count} times in the CSV
          </span>
        )
    }

    return row.error
  }

  return (
    <Box width="50%" className={classes.errorLog}>
      <p className={classes.errorHeading}>Errors</p>
      <TableContainer>
        <Table className={classes.table} size="small" aria-label="table of email errors">
          <TableHead>
            <TableRow>
              <TableCell className={classes.errTableHeaders}>
                {duplicates.length > 0 ? 'First Seen in ' : null}Row
              </TableCell>

              <TableCell className={classes.errTableHeaders}>Company name</TableCell>

              {badFormats.length > 0 ? <TableCell className={classes.errTableHeaders}>Column</TableCell> : null}

              <TableCell className={classes.errTableHeaders}>
                {duplicates.length > 0 ? 'Duplicates' : 'Errors'}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {errors.map((row, index) => (
              <TableRow key={`${row.line}-${index}`}>
                <TableCell component="th" scope="row" align="center" className={classes.errTableData}>
                  {row.line}
                </TableCell>

                <TableCell className={classes.errTableData}>{row.company}</TableCell>

                {badFormats.length > 0 ? <TableCell className={classes.errTableData}>{row.column}</TableCell> : null}
                
                <TableCell className={classes.errTableData}>{displayErrors(row)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
