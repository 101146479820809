import * as Yup from 'yup'

export const validationSchema = Yup.object({
  name: Yup.string()
    .max(255, 'Must be 255 characters or less')
    .required('Required'),
  primaryEmail: Yup.string().required('Required'),
  secondaryEmail: Yup.string().notOneOf([Yup.ref('primaryEmail')], 'Primary and Secondary email cannot be the same.'),
  institutions: Yup.array(),
  designation: Yup.string(),
  firebaseuid: Yup.string(),
  phoneNumber: Yup.string(),
  status: Yup.string(),
  starred: Yup.boolean(),
  handler: Yup.number(),
  notes: Yup.string()
})

export const initialFormData = {
  name: '', // Name of the Person
  primaryEmail: '', // Primary email of the person
  secondaryEmail: '', // Secondary email of the person
  institutions: [], // Array of Institutions that the person is a part of - takes shape [ { id, name, UserDetails: { id, contactPoint } } ]
  subscriptions: [],
  designation: '', // Designation at a company
  firebaseuid: '', // String denoting Firebase UID of the user - for consolidated analytics etc.
  phoneNumber: '', // String denoting persons phone number
  status: '', // String denoting the users status (lead, trial, paid)
  starred: false, // Boolean denoting whether user is a "starred" or "important" user
  handler: 0, // ID of the internal user that handles this user
  notes: '' // Additional Notes
}
