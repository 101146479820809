import React, { Component } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion'

import { Chip } from '@material-ui/core'

export default class CSVReviewList extends Component {
  render() {
    const { csvData, classes } = this.props

    const emptyAccordionText = <div className="empty-accordion">Choose a csv file to review the list</div>

    if (csvData.length !== 0 && csvData[0].name === undefined) {
      alert(
        'Wrong CSV format! \nCSV must contain the columns: "Name", "Email", "Password" and "Role" and "Categories".\nPlease select a valid csv file.'
      )
      return emptyAccordionText
    }

    return csvData.length === 0 ? (
      emptyAccordionText
    ) : (
      <Accordion allowZeroExpanded>
        {csvData.map(data => (
          <AccordionItem key={data.name}>
            <AccordionItemHeading>
              <AccordionItemButton>{`${data.name}`}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <table className="outter-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Password</th>
                    <th>Role</th>
                    <th>Categories</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="20%">{data.name}</td>
                    <td width="20%">{data.email}</td>
                    <td width="10%">{data.password}</td>
                    <td width="10%">{data.role}</td>
                    <td width="40%">
                      {data.categories.map(category => (
                        <Chip
                          key={`category-chip-${category}`}
                          label={category}
                          variant="outlined"
                          size="small"
                          className={classes.categoryChip}
                        />
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    )
  }
}
