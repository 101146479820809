import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'

import { withStyles, Grid, Button, IconButton, Typography, FormHelperText, Tooltip, Divider } from '@material-ui/core'

import { Alert } from '@material-ui/lab'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import { validationSchema, initialFormData } from './formSetup'

import VerifiedIcon from './VerifiedIcon'

import { UserStatusForm, UserDetailsForm, SubscriptionForm } from '..'

import { SingleLineTextField, MultiLineTextField, SelectField, CheckboxField, CustomAccordion } from '../../blocks'
import { FormDialog, ConfirmationDialog } from '../../Dialogs'
import { getDataFromId, addOrEditDataInFormPages, addOrEditData, deleteData } from '../../helpers'
import { getFirebaseDetails, mapCurrentValuesToForm, getInitialFormData } from '../../lib/person'
import { formatSubscriptionData } from '../../lib/subscription'
import { addNewStatus } from '../../lib/userStatus'

import { FunctionContext } from '../../contexts'
import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

const PersonForm = ({ classes, user, userRole, handleSubmit, currentValues }) => {
  const [initialValues, setInitialValues] = useState(initialFormData)

  const [userStatuses, setUserStatuses] = useState([]) // List of user statuses from database
  const [handlers, setHandlers] = useState([]) // List of user statuses from database
  const [allPackages, setAllPackages] = useState([]) // List of packages from database

  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false)
  const [showUserStatusForm, setShowUserStatusForm] = useState(false)
  const [showInstitutionForm, setShowInstitutionForm] = useState(false)

  const [showInstitutionDeleteDialog, setShowInstitutionDeleteDialog] = useState(false)
  const [showSubscriptionDeleteDialog, setShowSubscriptionDeleteDialog] = useState(false)

  const [selectedInstitution, setSelectedInstitution] = useState(null) // Reference to selected Institution when editing UserDetails
  const [selectedSubscription, setSelectedSubscription] = useState(null) // Reference to selected subscription when editing subscriptions
  
  const [hasSecondEmail, setHasSecondEmail] = useState(false)
  const [checkingFirebase, setCheckingFirebase] = useState(false)
  const [noFirebaseAccount, setNoFirebaseAccount] = useState(false)

  const functions = useContext(FunctionContext)

  // Effect to load the initial form data to populate select fields
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    // useEffect can only return a function (not an async function),
    // So, we create an async function and call it immediately to avoid errors
    async function fetchFormData() {
      const response = await getInitialFormData(user, functions.openDialog)

      if (response.success) {
        const { statusList, handlersList, packages } = response

        if (isMounted) {
          setUserStatuses(statusList)
          setHandlers(handlersList)
          setAllPackages(packages)
        }
      }
    }

    fetchFormData()

    return () => {
      isMounted = false
    }
  }, [functions, user])

  // Effect to update the form with the new values during an edit action
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updatePersonData() {
      if (currentValues) {
        const currentData = mapCurrentValuesToForm(currentValues)

        if (isMounted) {
          setInitialValues(currentData)
          if (currentValues.secondaryEmail) setHasSecondEmail(true)
        }
      }
    }

    updatePersonData()

    return () => {
      isMounted = false
    }
  }, [currentValues])

  const getFirebaseDetailsOnBlur = async (primaryEmail) => {
    setCheckingFirebase(true)

    const details = await getFirebaseDetails(primaryEmail, user)

    if (details.success) {
      functions.openSnackbar(details.message)
      setCheckingFirebase(false)

      return details.uid // Return the uid of the user to set it in formik
    }

    setNoFirebaseAccount(true) // Set the fact that the user has no firebase account, to notify the user and allow a re-check
    setCheckingFirebase(false)

    functions.openSnackbar(details.error)
  }

  // For adding and ediitng Institutions associated with this user
  const getUpdatedInsitutions = (institutionData, formikInstitutions, selectedValue) =>
    addOrEditDataInFormPages(institutionData, formikInstitutions, selectedValue)

  // For deleting institutions associated with this user
  const handleInsitutionDelete = (institutionData, formikInstitutions) =>
    deleteData(institutionData, formikInstitutions)

  // For adding and ediitng Subscriptions associated with this user
  const getUpdatedSubscriptions = async (subscriptionData, formikSubscriptions) => {
    // subscription data from backend takes the shape:
    // { products: [ { id, name, deletedAt, Subscription: { mailingPreference, emailType, institutionId, packageId } } ] }
    const newSubscription = await formatSubscriptionData(subscriptionData, user, functions.openDialog) // format subscriptionData to match backend

    if (newSubscription.success) return addOrEditData(newSubscription.subscription, formikSubscriptions)
  }

  // For deleting subscriptions associated with this user
  const handleSubscriptionDelete = (subscriptionData, formikSubscriptions) =>
    deleteData(subscriptionData, formikSubscriptions)

  // Function to create a new user status
  const handleUserStatusAdd = async formData => {
    const response = await addNewStatus(formData, user, functions.openDialog)

    if (response.success) {
      setUserStatuses([...userStatuses, response.newStatus])
      setShowUserStatusForm(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
      validationSchema={validationSchema}
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={4} justifyContent="space-between">
                <Grid item>
                  <SingleLineTextField
                    fullWidth
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    handleChange={formik.handleChange}
                    touched={formik.touched.name}
                    errors={formik.errors.name}
                  />
                </Grid>

                <Grid item>
                  <SingleLineTextField
                    fullWidth
                    name="designation"
                    label="Designation"
                    value={formik.values.designation}
                    handleChange={formik.handleChange}
                    touched={formik.touched.designation}
                    errors={formik.errors.designation}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={1} justifyContent="space-between">
                <Grid item>
                  <SingleLineTextField
                    fullWidth
                    name="primaryEmail"
                    label="Primary Email"
                    value={formik.values.primaryEmail}
                    handleChange={({ target: { value: primaryEmail } }) => {
                      formik.setFieldValue('primaryEmail', primaryEmail)
                      formik.setFieldValue('firebaseuid', '') // Reset firebaseuid whenever the primaryEmail is changed
                      setNoFirebaseAccount(false) // Reset noFirebaseAccount boolean to false whenever primary email changes
                    }}
                    touched={formik.touched.primaryEmail}
                    errors={formik.errors.primaryEmail}
                    handleBlur={async () => {
                      const firebaseuid = await getFirebaseDetailsOnBlur(formik.values.primaryEmail)
                      formik.setFieldValue('firebaseuid', firebaseuid || '') // Only set the firebaseuid if one exists
                    }}
                    endAdornment
                    endAdornmentIcon={
                      <VerifiedIcon
                        firebaseuid={formik.values.firebaseuid}
                        checkingFirebase={checkingFirebase}
                        noFirebaseAccount={noFirebaseAccount}
                      />
                    }
                    handleEndAdornmentClick={
                      // Allow user to re-check for firebase account if there isn't one already
                      noFirebaseAccount
                        ? () => {
                            getFirebaseDetailsOnBlur(formik.values.primaryEmail)
                          }
                        : undefined
                    }
                  />

                  <FormHelperText className={classes.field}>
                    The primary email should be the same as what the users Athena login address is.
                  </FormHelperText>
                </Grid>

                <Grid item>
                  {hasSecondEmail ? (
                    <SingleLineTextField
                      fullWidth
                      name="secondaryEmail"
                      label="Secondary Email"
                      value={formik.values.secondaryEmail}
                      handleChange={formik.handleChange}
                      touched={formik.touched.secondaryEmail}
                      errors={formik.errors.secondaryEmail}
                      endAdornment
                      endAdornmentIcon={<RemoveIcon color="secondary" />}
                      handleEndAdornmentClick={() => {
                        formik.setFieldValue('secondaryEmail', '')
                        setHasSecondEmail(false)
                      }}
                    />
                  ) : (
                    <Button
                      variant="text"
                      onClick={() => {
                        setHasSecondEmail(true)
                      }}
                      className={classes.field}
                    >
                      <AddIcon color="primary" />
                      <Typography color="primary" variant="caption" style={{ marginTop: '4px' }}>
                        Secondary email
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <SingleLineTextField
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                value={formik.values.phoneNumber}
                handleChange={formik.handleChange}
                touched={formik.touched.phoneNumber}
                errors={formik.errors.phoneNumber}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                <Grid item xs={11}>
                  <SelectField
                    fullWidth
                    name="status"
                    label="Status"
                    items={userStatuses}
                    value={formik.values.status}
                    handleChange={selectedOption => {
                      formik.setFieldValue('status', selectedOption || '')
                    }}
                    touched={formik.touched.status}
                    errors={formik.errors.status}
                  />
                </Grid>

                <Grid item xs={1}>
                  <Tooltip title="Add a new Status">
                    <IconButton onClick={() => setShowUserStatusForm(true)}>
                      <AddIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={2}>
                <Grid item>
                  <SelectField
                    fullWidth
                    name="handler"
                    label="Handler"
                    items={handlers}
                    value={formik.values.handler}
                    handleChange={selectedOption => {
                      formik.setFieldValue('handler', selectedOption || '')
                    }}
                    touched={formik.touched.handler}
                    errors={formik.errors.handler}
                  />

                  <FormHelperText className={classes.field}>
                    Select an internal user as the handler for this user
                  </FormHelperText>
                </Grid>

                <Grid item>
                  <CheckboxField
                    name="starred"
                    label="Starred"
                    handleChange={formik.handleChange}
                    checked={formik.values.starred}
                    touched={formik.touched.starred}
                    errors={formik.errors.starred}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <MultiLineTextField
                fullWidth
                name="notes"
                label="Notes"
                value={formik.values.notes}
                handleChange={formik.handleChange}
                touched={formik.touched.notes}
                errors={formik.errors.notes}
              />
            </Grid>
          </Grid>

          <Divider />
          
          <div className={classes.sectionDivider}>
            <Typography variant="h2">Institutions</Typography>

            <Button
              variant="contained"
              className={classes.actionButton}
              onClick={() => {
                setShowInstitutionForm(true)
              }}
            >
              Add Institution
            </Button>
          </div>

          <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12}>
              {formik.values.institutions.map(institution => (
                <CustomAccordion
                  key={institution.id}
                  title={institution.name}
                  classes={classes}
                  data={institution}
                  removedStatusPath="PackageDetails.deletedAt"
                  customChips={[{ label: 'Contact Point', path: 'UserDetails.contactPoint' }]}
                  handleEdit={() => {
                    setSelectedInstitution(institution)
                    setShowInstitutionForm(true)
                  }}
                  handleDelete={() => {
                    setSelectedInstitution(institution)
                    setShowInstitutionDeleteDialog(true)
                  }}
                  userRole={userRole}
                />
              ))}
            </Grid>
          </Grid>

          <Divider />
          
          <div className={classes.sectionDivider}>
            <Typography variant="h2">Subscriptions</Typography>

            {formik.values.institutions.length > 0 ? (
              <Button
                variant="contained"
                className={classes.actionButton}
                onClick={() => {
                  setShowSubscriptionForm(true)
                }}
              >
                Add Subscription
              </Button>
            ) : (
              <Alert severity="info">Please add an Institution to this user to add a subscription</Alert>
            )}
          </div>

          <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12}>
              {formik.values.subscriptions.map((subscription, index) => (
                <CustomAccordion
                  key={index}
                  title={subscription.name}
                  classes={classes}
                  data={subscription}
                  removedStatusPath="Subscription.deletedAt"
                  customChips={[
                    {
                      label: _.capitalize(subscription.Subscription.mailingPreference),
                      path: 'Subscription.mailingPreference'
                    },
                    {
                      label: `${_.capitalize(subscription.Subscription.emailType)} Email`,
                      path: 'Subscription.emailType'
                    },
                    {
                      label: getDataFromId(subscription.Subscription.institutionId, formik.values.institutions).name,
                      path: 'Subscription.institutionId'
                    },
                    {
                      label: allPackages.length && getDataFromId(subscription.Subscription.packageId, allPackages).name,
                      path: 'Subscription.packageId'
                    }
                  ]}
                  handleEdit={() => {
                    setSelectedSubscription(subscription)
                    setShowSubscriptionForm(true)
                  }}
                  handleDelete={() => {
                    setSelectedSubscription(subscription)
                    setShowSubscriptionDeleteDialog(true)
                  }}
                />
              ))}
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
              Submit
            </Button>
          </div>

          {/* Form to add Institutions to the user */}
          <FormDialog
            open={showInstitutionForm}
            handleClose={() => {
              setShowInstitutionForm(false)
            }}
            classes={classes}
            title="Add a new Institution to this User"
          >
            <UserDetailsForm
              user={user}
              handleCancel={() => {
                setShowInstitutionForm(false)
              }}
              currentValues={selectedInstitution}
              currentInstitutions={formik.values.institutions}
              handleSubmit={formData => {
                const newData = getUpdatedInsitutions(formData, formik.values.institutions, selectedInstitution)
                formik.setFieldValue('institutions', newData)
                setShowInstitutionForm(false)
              }}
              classes={classes}
            />
          </FormDialog>

          {/* Form to add a new User Status */}
          <FormDialog
            open={showUserStatusForm}
            handleClose={() => {
              setShowUserStatusForm(false)
            }}
            classes={classes}
            title="Add a new User Status"
          >
            <UserStatusForm handleSubmit={handleUserStatusAdd} classes={classes} />
          </FormDialog>

          {/* Form to add a new subscription */}
          <FormDialog
            open={showSubscriptionForm}
            handleClose={() => {
              setShowSubscriptionForm(false)
              setSelectedSubscription(null)
            }}
            classes={classes}
            title="Add a new Subscription"
          >
            <SubscriptionForm
              handleSubmit={async formData => {
                const newData = await getUpdatedSubscriptions(formData, formik.values.subscriptions)
                formik.setFieldValue('subscriptions', newData)
                setSelectedSubscription(null)
                setShowSubscriptionForm(false)
              }}
              currentInstitutions={formik.values.institutions}
              currentProducts={formik.values.subscriptions}
              hasSecondaryEmail={!!formik.values.secondaryEmail}
              currentValues={selectedSubscription}
              classes={classes}
              loggedInUser={user}
            />
          </FormDialog>

          <ConfirmationDialog
            open={showInstitutionDeleteDialog}
            closeDialog={() => {
              setShowInstitutionDeleteDialog(false)
            }}
            text={`Are you sure you want to delete "${selectedInstitution && selectedInstitution.name}" from the user ${
              formik.values.name
            }?`}
            handleConfirm={() => {
              const newInstitutionData = handleInsitutionDelete(selectedInstitution, formik.values.institutions)
              formik.setFieldValue('institutions', newInstitutionData)
              setShowInstitutionDeleteDialog(false)
            }}
          />

          <ConfirmationDialog
            open={showSubscriptionDeleteDialog}
            closeDialog={() => {
              setShowSubscriptionDeleteDialog(false)
            }}
            text={`Are you sure you want to delete "${selectedSubscription &&
              selectedSubscription.name}" from the user ${formik.values.name}?`}
            handleConfirm={() => {
              const newSubscriptionData = handleSubscriptionDelete(selectedSubscription, formik.values.subscriptions)
              formik.setFieldValue('subscriptions', newSubscriptionData)
              setSelectedSubscription(null)
              setShowSubscriptionDeleteDialog(false)
            }}
          />
        </form>
      )}
    </Formik>
  )
}

export default withStyles(styles)(PersonForm)
