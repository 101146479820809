import React from 'react'

import { ButtonGroup, Button } from '@material-ui/core'

export default function AlphabeticalPaginationBar({ paginationButtons, handlePaginationSelect }) {
  const paginationButtonsToDisplay = ['All', '#', ...paginationButtons]

  return (
    <ButtonGroup variant="text" color="primary">
      {paginationButtonsToDisplay.map(button => (
        <Button
          onClick={event => {
            handlePaginationSelect(event, button)
          }}
          key={`pagination-button-${button}`}
        >
          {button}
        </Button>
      ))}
    </ButtonGroup>
  )
}
