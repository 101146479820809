export default function loginStyles(theme) {
  return {
    loginPage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000000',
      maxWidth: '100vw',
      minHeight: '100vh'
    },
    loginFormContainer: {
      background: '#ffffff',
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
      borderRadius: '20px'
    },
    companyLogo: {
      width: '50%'
    },
    forgotPassword: {
      color: 'grey',
      cursor: 'pointer'
    },
    submitButtonSection: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3)
    }
  }
}
