import React, { Component } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'

class ConfirmationDialog extends Component {
  state = {
    confirmationText: ''
  }

  handleExtraSecurityDisable = () => {
    const { confirmationText } = this.state

    if (confirmationText === 'DELETE') return false

    return true
  }

  handleChange = e => {
    const { value } = e.target

    this.setState({ confirmationText: value })
  }

  render() {
    const { open, closeDialog, text, handleConfirm, extraSecurity } = this.props
    const { confirmationText } = this.state

    return (
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>

          {/* If we don't specify extraSecurity, then the action is not a destructive one and CAN be reversed. */}
          {!extraSecurity && (
            <Alert severity="info" className="alert">
              This action is reversible.
            </Alert>
          )}

          {/* If we do specify extraSecurity, then the action is destructive and CANNOT be reversed. */}
          {extraSecurity && (
            <>
              <Alert severity="warning" className="alert">
                This action is <strong>not reversible</strong>.
              </Alert>

              <DialogContentText style={{ paddingBottom: '10px' }}>
                As an added security measure, please type 'DELETE' below to confirm that you want to delete this item.
              </DialogContentText>

              <TextField
                fullWidth
                variant="outlined"
                name="confirmationText"
                value={confirmationText}
                onChange={this.handleChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary" variant="outlined">
            Cancel
          </Button>

          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            disabled={extraSecurity ? this.handleExtraSecurityDisable() : false}
          >
            I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ConfirmationDialog
