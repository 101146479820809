import React from 'react'

import { withStyles, TextField, InputAdornment, IconButton } from '@material-ui/core'

import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

const SingleLineTextField = ({
  name,
  label,
  value,
  disabled,
  handleChange,
  touched,
  errors,
  fullWidth,
  classes,
  handleBlur,
  endAdornment,
  handleEndAdornmentClick,
  endAdornmentIcon
}) => (
  <TextField
    className={classes.field}
    variant="outlined"
    margin="dense"
    fullWidth={fullWidth}
    id={name}
    name={name}
    label={label}
    value={value}
    disabled={disabled}
    onChange={handleChange}
    error={touched && Boolean(errors)}
    helperText={touched && errors}
    onBlur={handleBlur}
    InputProps={{
      endAdornment: endAdornment && (
        <InputAdornment position="end">
          <IconButton onClick={handleEndAdornmentClick}>{endAdornmentIcon}</IconButton>
        </InputAdornment>
      )
    }}
  />
)

export default withStyles(styles)(SingleLineTextField)
