import React from 'react'

import { CircularProgress, Tooltip } from '@material-ui/core'

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import NotInterestedIcon from '@material-ui/icons/NotInterested'

const VerifiedIcon = ({ firebaseuid, checkingFirebase, noFirebaseAccount }) => {
  // Based on the status of the firebaseuid, we set the endAdornment on the primaryEmail box

  // If we're checking firebase, render the loading icon
  if (checkingFirebase) {
    return <CircularProgress />
  }

  // If there is a firebase user found, render the Verified user icon
  if (firebaseuid) {
    return (
      <Tooltip title="Athena account exists">
        <VerifiedUserIcon />
      </Tooltip>
    )
  }

  // If there is no firebase account (after checking firebase) then render a button to notify user and allow a re-check
  if (noFirebaseAccount) {
    return (
      <Tooltip title="No Athena Account. Click to check again">
        <NotInterestedIcon />
      </Tooltip>
    )
  }

  return null // If there is no firebase user / if there is no primaryEmail entered, render null
}

export default VerifiedIcon
