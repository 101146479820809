import React from 'react'

import { Container, Button, withStyles, Typography, CircularProgress } from '@material-ui/core'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { extractErrorData } from '../../helpers'
import { AddUsersForm } from '../../forms'

import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

class AddUsersToProduct extends React.Component {
  static contextType = FunctionContext

  state = {
    users: null,
    subscribedUsers: null,
    product: null
  }

  componentDidMount() {
    this.getUserData()
    this.getProductData()
  }

  getUserData = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user
    } = this.props

    try {
      const data = await databaseQueryInterface.get(`${settings.baseURL}/api/users-new`, user)
      // console.log(data.map(user => ({
      //   objectID: user.id,
      //   name: user.name,
      //   email: user.primaryEmail,
      //   type: 'person',
      // })))

      const tempUsers = data.map(({ id, name, primaryEmail, institutions, deletedAt }) => ({
        id,
        deletedAt,
        userId: id,
        institutionId: institutions.length > 0 ? institutions[0].id : null,
        packageId: 53,
        mailingPreference: 'bcc',
        emailType: 'primary',
        name,
        primaryEmail,
      }))

      this.setState({ users: tempUsers }, () => {
        openSnackbar(`Retrieved data for all clients`)
      })
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve user records`,
        data
      })
    }
  }

  getProductData = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params }
    } = this.props

    try {
      const data = await databaseQueryInterface.get(`${settings.baseURL}/api/products/${params.id}`, user)

      const tempSubscribedUsers = data.users.map(({
        id, name, primaryEmail, deletedAt,
        Subscription: { emailType, mailingPreference, institutionId }
      }) => ({
        id,
        deletedAt,
        userId: id,
        institutionId,
        packageId: 53,
        mailingPreference,
        emailType,
        name,
        primaryEmail,
      }))

      this.setState({
        product: data,
        subscribedUsers: tempSubscribedUsers.filter(user => user.deletedAt === null) // get rid of removed users
      }, () => {
        openSnackbar(`Retrieved data for ${data.name}`)
      })
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve product data`,
        data
      })
    }
  }

  handleSubmit = async formData => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params },
      history
    } = this.props

    try {
      const data = await databaseQueryInterface.put(`${settings.baseURL}/api/products/${params.id}/add-users`, user, {
        ...formData
      })

      openSnackbar(`Added users to product`)
      history.push(`/products/view/${params.id}`)
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `add users to product`, data })
    }
  }

  render() {
    const { user, classes, history } = this.props
    const { users, subscribedUsers, product } = this.state

    return (
      <Container>
        {
          subscribedUsers ?
            <>
              <Typography variant="h1">Add subscribers to {product && product.name}</Typography>

              <br />

              <AddUsersForm
                classes={classes}
                user={user}
                users={users}
                handleSubmit={this.handleSubmit}
                currentValues={subscribedUsers}
              />

              <div className={classes.buttonContainer}>
                <Button variant="outlined" color="secondary" onClick={history.goBack} className={classes.actionButton}>
                  Cancel
                </Button>
              </div>

            </> : <CircularProgress size={20} thickness={5} />
        }
      </Container>
    )
  }
}

export default withStyles(styles)(AddUsersToProduct)
