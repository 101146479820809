import React, { useEffect } from 'react'
import { Formik } from 'formik'

import { withStyles, Button, Grid } from '@material-ui/core'

import { validationSchema, initialFormData } from './formSetup'

import { formStyles } from '../../../styles'
import { SingleLineTextField, SelectField } from '../../blocks'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { extractErrorData, formatDataForAutocomplete } from '../../helpers'
import { mapCurrentValuesToForm } from '../../lib/productSubcategory'

const styles = theme => ({ ...formStyles(theme) })

const ProductSubcategoryForm = ({
  classes,
  user,
  openDialog,
  handleSubmit,
  handleEditSubmit,
  currentValues,
  handleCancel
}) => {
  const [allCategories, setAllCategories] = React.useState(null)
  const [initialValues, setInitialValues] = React.useState(initialFormData)

  // Effect to load the list of categories to populate parent category select field
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    // useEffect can only return a function (not an async function),
    // So, we create an async function and call it immediately to avoid errors
    async function fetchCategoriesList(url, callback) {
      try {
        const data = await databaseQueryInterface.get(url, user)

        const categoriesList = formatDataForAutocomplete(data)

        if (isMounted) callback(categoriesList)
      } catch (err) {
        const data = extractErrorData(err)
        openDialog({
          failedAction: `retrieve list of Categories for Parent Category field`,
          data
        })
      }
    }

    fetchCategoriesList(`${settings.baseURL}/api/product-categories`, setAllCategories)

    return () => {
      isMounted = false
    }
  }, [openDialog, user])

  // Effect to update the form with the new values during an edit action
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updateSubcategoryData() {
      if (currentValues) {
        const currentData = mapCurrentValuesToForm(currentValues)

        if (isMounted) setInitialValues(currentData)
      }
    }

    updateSubcategoryData()

    return () => {
      isMounted = false
    }
  }, [currentValues])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
      validationSchema={validationSchema}
      onSubmit={values => {
        if (currentValues) {
          handleEditSubmit(values, currentValues.id)
        } else {
          handleSubmit(values)
        }
      }}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12}>
              <SingleLineTextField
                name="name"
                label="Name"
                fullWidth
                value={formik.values.name}
                handleChange={formik.handleChange}
                touched={formik.touched.name}
                errors={formik.errors.name}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectField
                name="categoryId"
                label="Category"
                fullWidth
                value={formik.values.categoryId}
                handleChange={selectedOption => {
                  formik.setFieldValue('categoryId', selectedOption || '')
                }}
                touched={formik.touched.categoryId}
                errors={formik.errors.categoryId}
                items={allCategories || []}
              />
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
              Submit
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                handleCancel('subcategoryForm')
              }}
              className={classes.actionButton}
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default withStyles(styles)(ProductSubcategoryForm)
