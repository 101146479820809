import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Container, Paper, IconButton, Typography, Button, Chip } from '@material-ui/core'
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab'

import { AdminButtons, CustomList } from '../../blocks'

const DetailsPane = ({
  classes,
  handleClose,
  selectedItem,
  userRole,
  handleEdit,
  handleAddUsers,
  handleRemove,
  handleDelete,
  handleDownload,
  handleDownloadSimpleList,
  history
}) => {
  const getStarredUsers = listOfUsers => listOfUsers.filter(user => user.starred)

  return (
    <Paper className={classes.paperStyles}>
      <>
        <div className={classes.rightAlignedButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={`/products/view/${selectedItem && selectedItem.id}`}
          >
            View All Details
          </Button>

          <AdminButtons
            userRole={userRole}
            classes={classes}
            item={selectedItem && selectedItem}
            handleEditClick={handleEdit}
            handleDownloadClick={handleDownload}
            handleAddUsersClick={handleAddUsers}
            handleRemoveClick={handleRemove}
            handleDeleteClick={handleDelete}
            handleDownloadSimpleListClick={handleDownloadSimpleList}
          />

          <IconButton onClick={handleClose}>
            <KeyboardTabIcon />
          </IconButton>
        </div>

        <Typography variant="h2">
          {selectedItem && selectedItem.name}

          {selectedItem && selectedItem.deletedAt ? (
            <Chip label="Removed" color="secondary" variant="outlined" style={{ marginLeft: '10px' }} />
          ) : null}
        </Typography>

        <Container className={classes.sectionDiv}>
          <Typography variant="h4">{selectedItem && selectedItem.users.length} subscribers</Typography>

          {selectedItem && (
            <CustomList
              itemsToList={selectedItem.users}
              urlModel="/person/view/"
            />
          )}
        </Container>
      </>
    </Paper>
  )
}

export default DetailsPane
