import algoliasearch from 'algoliasearch'

// select app id on Algolia depending on environment
const appID = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_ALGOLIA_DEV_APP_ID
  : process.env.REACT_APP_ALGOLIA_PROD_APP_ID

const algoliaClient = (apiKey) => algoliasearch(
  appID,
  apiKey,
)

export default algoliaClient