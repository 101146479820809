import Papa from 'papaparse'

export default async function parseUserImportListCSV(files, showErrorDialog, callback) {
  const userData = []

  Papa.parse(files[0], {
    header: true,
    transformHeader: header => header.toLowerCase(),
    skipEmptyLines: 'greedy',
    transform: (value, header) => (header === 'email' ? value.toLowerCase() : value),
    complete: results => {
      const { data, errors } = results

      if (errors.length > 0) {
        showErrorDialog(errors)
      } else {
        data.forEach(row => {
          userData.push({
            name: row.name,
            email: row.email,
            password: row.password,
            role: row.role,
            categories: row.categories ? row.categories.split(';') : []
          })
        })

        callback(userData)
      }
    },
    error: error => {
      alert(error.message)
    }
  })
}
