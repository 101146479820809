import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Container, Paper, IconButton, Typography, Button } from '@material-ui/core'
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab'

import { AdminButtons, CustomList } from '../../blocks'

import { formatAsCurrency } from '../../helpers'

const DetailsPane = ({
  classes,
  handleClose,
  selectedPackage,
  userRole,
  history,
  handleEdit,
  handleRemove,
  handleDelete
}) => (
  <Paper className={classes.paperStyles}>
    <div className={classes.rightAlignedButtonContainer}>
      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to={`/packages/view/${selectedPackage && selectedPackage.id}`}
      >
        View All Details
      </Button>

      <AdminButtons
        userRole={userRole}
        classes={classes}
        item={selectedPackage && selectedPackage}
        handleEditClick={handleEdit}
        handleRemoveClick={handleRemove}
        handleDeleteClick={handleDelete}
      />

      <IconButton onClick={handleClose}>
        <KeyboardTabIcon />
      </IconButton>
    </div>

    <Typography variant="h2">{selectedPackage && selectedPackage.name}</Typography>

    <Container className={classes.sectionDiv}>
      <Typography variant="h4">Active products:</Typography>

      {
        selectedPackage && (
          <CustomList
            itemsToList={selectedPackage.products}
            urlModel="/products/view/"
          />
        )
      }
    </Container>
  </Paper>
)

export default DetailsPane
