import { createTheme } from '@material-ui/core/styles'
import settings from './settings'

const theme = createTheme({
  typography: {
    fontFamily: ['Assistant', 'sans-serif'].join(', '),
    fontColor: '#212121'
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(', '),
        fontWeight: 600,
        fontSize: '1.7rem',
        lineHeight: '4rem'
      },
      h2: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(', '),
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: '3rem'
      },
      h3: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(', '),
        fontWeight: 600,
        fontSize: '1.3rem',
        lineHeight: '2rem'
      },
      h4: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(', '),
        fontWeight: 600,
        fontSize: '1.1rem',
        lineHeight: '1rem'
      }
    },
    MuiDialogActions: {
      root: {
        padding: '16px'
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: ['Assistant', 'sans-serif'].join(', '),
        fontWeight: 600
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '.9rem'
      }
    },
    MuiTableCell: {
      root: { padding: '5px' }
    },
    MuiAlert: {
      root: { margin: '16px' }
    }
  },
  palette: {
    primary: { main: '#701516' },
    secondary: { main: '#CB2026' },
    type: settings.theme.type
  },
})

export default theme
