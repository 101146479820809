import React, { useEffect } from 'react'
import { Formik } from 'formik'

import { withStyles, Button } from '@material-ui/core'

import { validationSchema, initialFormData } from './formSetup'

import { SingleLineTextField /* CheckboxField */ } from '../../blocks'
import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

const UserStatusForm = ({ handleSubmit, currentValues, classes }) => {
  const [initialValues, setInitialValues] = React.useState(initialFormData)

  // Effect to update the form with the new values during an edit action
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updateStatusData() {
      if (currentValues) {
        if (isMounted) setInitialValues({ name: currentValues.name })
      }
    }

    updateStatusData()

    return () => {
      isMounted = false
    }
  }, [currentValues])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
      validationSchema={validationSchema}
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <SingleLineTextField
            fullWidth
            name="name"
            label="Name"
            value={formik.values.name}
            handleChange={formik.handleChange}
            touched={formik.touched.name}
            errors={formik.errors.name}
          />

          <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
            Submit
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default withStyles(styles)(UserStatusForm)
