import React from 'react'
import _ from 'lodash'

import { withStyles, TextField, FormHelperText, Chip } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

const MultiSelectField = ({
  name, // name of the field
  label, // label of the field
  value, // return value of the field
  handleChange, // function to decide what happens when the field value is changed
  touched, // determines if the field has been touched (i.e. a user clicked the field and then moved a different field)
  errors, // list of errors to be displayed, if any
  fullWidth, // determines if the field should be rendered to take up the full width of its parent
  items, // List of items to be selected from
  includeNone, // Boolean to decide whether to include a "None" option as well
  classes // CSS Classes passed from parent component
}) => {
  const itemsWithEmpty = [{ name: 'None', value: '' }, ...items]
  const itemsToIterate = includeNone ? itemsWithEmpty : items

  // IMPORTANT:
  // The Autocomplete component from M-UI takes 'null' as the empty value, rather than an empty string ''
  return (
    <>
      <Autocomplete
        className={classes.field}
        variant="outlined"
        fullWidth={fullWidth}
        id={name}
        name={name}
        multiple
        value={value}
        onChange={(e, selectedOption) => {
          handleChange(selectedOption)
        }}
        getOptionSelected={(option, selectedValue) => option.name === selectedValue.name}
        getOptionDisabled={option => option.deletedAt !== null} // Disable options for items that have been deleted.
        renderTags={(tagValue, getTagProps) =>
          // Since some options may later be soft-deleted, we disable the option from being removed
          tagValue.map((option, index) => (
            <Chip
              label={option.primaryEmail ? option.primaryEmail : option.name}
              color={option.deletedAt ? 'secondary' : 'default'}
              {...getTagProps({ index })}
              disabled={option.deletedAt !== null}
              onDelete={undefined}
            />
          ))
        }
        disabled={items.length === 0}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            name={name}
            helperText={touched && errors}
            error={touched && Boolean(errors)}
          />
        )}
        options={_.sortBy(itemsToIterate, 'name')}
        getOptionLabel={option => option.primaryEmail ? option.primaryEmail : option.name}
      />
      {items.length === 0 ? <FormHelperText className={classes.helperText}>Disabled</FormHelperText> : null}
    </>
  )
}

export default withStyles(styles)(MultiSelectField)
