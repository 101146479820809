async function setHeaders(user) {
  const token = await user.getIdToken()

  return {
    'x-auth-token': token,
    'Content-Type': 'application/json'
  }
}

export default setHeaders
