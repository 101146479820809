import React, { Component } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  withStyles,
  CircularProgress,
  InputAdornment,
  Tooltip,
  Radio,
  RadioGroup,
  Grid,
  Checkbox
} from '@material-ui/core'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import axios from 'axios'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'

import { extractErrorData } from '../../helpers'
import { userStyles } from '../../../styles'

const styles = theme => ({ ...userStyles(theme) })

class UserForm extends Component {
  static contextType = FunctionContext

  state = {
    uid: '',
    displayName: '',
    email: '',
    password: '',
    isPerformingAuthAction: false,
    emailVerified: false,
    disabled: false,
    customClaims: {
      role: '',
      categories: []
    },
    newChanges: false
  }

  static getDerivedStateFromProps(props, state) {
    const { selectedUser } = props
    const { uid } = state

    if (selectedUser && selectedUser.uid !== uid) {
      return selectedUser
    }

    return null
  }

  handleExited = () => {
    this.setState({
      uid: '',
      displayName: '',
      email: '',
      password: '',
      isPerformingAuthAction: false,
      emailVerified: false,
      disabled: false,
      customClaims: {
        role: '',
        categories: []
      },
      newChanges: false
    })
  }

  handleKeyPress = event => {
    const { key, altKey, ctrlKey, metaKey, shiftKey } = event

    if (altKey || ctrlKey || metaKey || shiftKey) {
      return
    }

    if (key === 'Enter') {
      event.preventDefault()

      this.editUser()
    }
  }

  handleInputChange = event => {
    const {
      target: { name, value }
    } = event

    this.setState({
      newChanges: true,
      [name]: value
    })
  }

  handleRadioChange = event => {
    const {
      target: { value }
    } = event

    this.setState(prevState => ({
      newChanges: true,
      customClaims: {
        ...prevState.customClaims,
        role: value
      }
    }))
  }

  handleCheckBoxChange = event => {
    const {
      target: { checked, value }
    } = event

    const { customClaims } = this.state

    const categoryArray = customClaims.categories || []

    if (checked) {
      categoryArray.push(value)
      this.setState({
        newChanges: true,
        customClaims: {
          ...customClaims,
          categories: categoryArray
        }
      })
    } else {
      const index = categoryArray.indexOf(value)

      if (index > -1) {
        categoryArray.splice(index, 1)
        this.setState({
          newChanges: true,
          customClaims: {
            ...customClaims,
            categories: categoryArray
          }
        })
      }
    }
  }

  getUserIdToken = async () => {
    const { openSnackbar } = this.context
    const { user } = this.props

    try {
      const token = await user.getIdToken()
      return token
    } catch (err) {
      const data = extractErrorData(err)
      openSnackbar(data)
    }
  }

  makeBackendCall = async (backendURL, httpMethod, dataObject, snackBarMessage) => {
    const { openSnackbar } = this.context
    const { onClose, updateUsersOnUI } = this.props

    try {
      const authToken = await this.getUserIdToken()

      let res

      if (httpMethod === 'post') {
        res = await axios.post(backendURL, dataObject, {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': authToken
          }
        })
      }

      if (httpMethod === 'put') {
        res = await axios.put(backendURL, dataObject, {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': authToken
          }
        })
      }

      onClose(() => {
        if (httpMethod === 'post') openSnackbar(res.data)

        if (httpMethod === 'put') {
          openSnackbar(snackBarMessage)
          updateUsersOnUI(res.data)
        }
      })
    } catch (err) {
      const data = extractErrorData(err)
      openSnackbar(data)
    }
  }

  createUser = () => {
    const {
      displayName,
      email,
      password,
      customClaims: { role, categories }
    } = this.state

    this.setState({ isPerformingAuthAction: true }, async () => {
      const dataObject = {
        name: displayName,
        email,
        password,
        role,
        categories
      }

      await this.makeBackendCall(`${settings.baseURL}/api/users/user`, 'post', dataObject)

      this.setState({ isPerformingAuthAction: false })
    })
  }

  editUser = () => {
    const {
      uid,
      displayName,
      email,
      disabled,
      customClaims: { role, categories }
    } = this.state

    this.setState({ isPerformingAuthAction: true }, async () => {
      const dataObject = {
        uid,
        name: displayName,
        email,
        role,
        categories,
        disabled
      }

      const snackBarMessage = `User ${displayName} updated.`

      await this.makeBackendCall(`${settings.baseURL}/api/users/user?uid=${uid}`, 'put', dataObject, snackBarMessage)

      this.setState({ isPerformingAuthAction: false })
    })
  }

  bulkEditUsers = () => {
    const { uids, options } = this.props
    const {
      customClaims: { role, categories }
    } = this.state

    this.setState({ isPerformingAuthAction: true }, async () => {
      const dataObject = {
        uids,
        role,
        categories,
        options
      }

      await this.makeBackendCall(`${settings.baseURL}/api/users/bulk-edit`, 'put', dataObject, `Users updated.`)

      this.setState({ isPerformingAuthAction: false })
    })
  }

  render() {
    const { classes, open, onClose, selectedUser, options, type } = this.props

    const {
      displayName,
      email,
      password,
      customClaims: { role, categories },
      emailVerified,
      isPerformingAuthAction,
      newChanges
    } = this.state

    const submitFunctions = {
      create: this.createUser,
      edit: this.editUser,
      bulkEdit: this.bulkEditUsers
    }

    const accessLevels = [
      { value: 'client', label: 'Client' },
      { value: 'clientAdmin', label: 'Client Admin' },
      { value: 'remoteMember', label: 'Remote Member Managing Client Data' },
      { value: 'remoteMailing', label: 'Remote Member Mailing Only' },
      { value: 'remoteNews', label: 'Remote Member Uploading News to Athena' },
      { value: 'member', label: 'Internal Team Member' },
      { value: 'editor', label: 'Editor' },
      { value: 'dev', label: 'Developer' }
    ]

    const userCategories = [
      'Most Important Decider',
      'Other Key Deciders',
      'Very Senior within Organization',
      'Senior within Organization',
      'Very Senior in Lanka',
      'Senior in Lanka',
      'Junior within Organization',
      'Unknown within Organization'
    ]

    return (
      <Dialog 
        open={open} 
        onClose={onClose} 
        onExited={this.handleExited} 
        onKeyPress={this.handleKeyPress}
      >
        <DialogTitle>{`Edit ${selectedUser ? selectedUser.displayName : 'User'}`}</DialogTitle>

        <DialogContent>
          <form>
            <FormLabel component="p">Basic Info</FormLabel>
            {options.displayName ? (
              <TextField
                required
                variant="outlined"
                autoComplete="name"
                type="text"
                name="displayName"
                fullWidth
                helperText="First and Second name separated by a space"
                margin="dense"
                onChange={this.handleInputChange}
                placeholder="John Wick"
                value={displayName}
                InputProps={{
                  endAdornment: (
                    <>
                      {selectedUser ? (
                        <InputAdornment position="end">
                          <Tooltip title={emailVerified ? 'Verified user' : 'Not verified'}>
                            <VerifiedUserIcon className={emailVerified ? classes.positiveIcon : classes.negativeIcon} />
                          </Tooltip>
                        </InputAdornment>
                      ) : null}
                    </>
                  )
                }}
              />
            ) : null}

            {options.email ? (
              <TextField
                required
                variant="outlined"
                autoComplete="email"
                type="email"
                name="email"
                fullWidth
                helperText="Make sure this is an active email address"
                margin="dense"
                onChange={this.handleInputChange}
                placeholder="babayka@example.com"
                value={email}
              />
            ) : null}

            {options.password ? (
              <TextField
                required
                variant="outlined"
                type="text"
                name="password"
                fullWidth
                helperText="Set something simple, the User should reset their password"
                margin="dense"
                onChange={this.handleInputChange}
                placeholder="********"
                value={password}
              />
            ) : null}

            {options.roles ? (
              <FormControl component="fieldset" className={classes.subSection}>
                <FormLabel component="p">Access Levels</FormLabel>
                <FormGroup className={classes.accessLevels}>
                  <RadioGroup
                    aria-label="access-levels"
                    name="accessLevels"
                    value={role}
                    onChange={this.handleRadioChange}
                  >
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      {accessLevels.map((levelObject, index) => (
                        <Grid item xs={4} key={index}>
                          <FormControlLabel value={levelObject.value} control={<Radio />} label={levelObject.label} />
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                </FormGroup>
                <FormHelperText>Be mindful of the privileges you assign. Admins can remove other Admins</FormHelperText>
              </FormControl>
            ) : null}

            {options.categories ? (
              <FormControl component="fieldset" className={classes.subSection}>
                <FormLabel component="p">User Categories</FormLabel>
                <FormGroup className={classes.accessLevels}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    {userCategories.map((category, index) => (
                      <Grid item xs={6} key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={category}
                              checked={categories && categories.includes(category)}
                              name={category}
                              onChange={this.handleCheckBoxChange}
                            />
                          }
                          label={category}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
                <FormHelperText>Select all relevant user categories</FormHelperText>
              </FormControl>
            ) : null}
          </form>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!newChanges || isPerformingAuthAction}
            onClick={() => submitFunctions[type]()}
          >
            {isPerformingAuthAction ? (
              <CircularProgress size={24} />
            ) : (
              `${type === 'create' ? 'Create' : 'Edit'} ${type === 'bulkEdit' ? 'Users' : 'User'}`
            )}
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(UserForm)
