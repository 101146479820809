import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Tooltip,
  Checkbox,
  TablePagination,
  TextField,
  InputAdornment,
  Chip,
  Menu,
  MenuItem,
  CircularProgress
} from '@material-ui/core'

import { grey } from '@material-ui/core/colors'
import MoreOutlinedIcon from '@material-ui/icons/MoreOutlined'
import BlockIcon from '@material-ui/icons/Block'
import SearchIcon from '@material-ui/icons/Search'
import axios from 'axios'
import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/firestore'

import settings from '../../../settings'
import { extractErrorData } from '../../helpers'
import { FunctionContext } from '../../contexts'
import { PermissibleRender } from '../../utilities'
import { userStyles, commonStyles } from '../../../styles'

import UserForm from './UserForm'

const styles = theme => ({ ...userStyles(theme), ...commonStyles(theme) })

class Users extends Component {
  static contextType = FunctionContext

  state = {
    users: [],
    nonClientUsers: [],
    checkingNonClientUsers: false, // loading animation to display while checking
    userForm: {
      create: false,
      edit: false
    },
    bulkEditUsersOption: '',
    anchorElForBulkEditMenu: '',
    selectedUser: null,
    checkedUsers: [],
    pageTokens: ['null'],
    page: 0,
    rowsPerPage: 10,
    count: 0,
    searchValue: '',
    searchedUser: null
  }

  componentDidMount() {
    const { rowsPerPage } = this.state

    this.getUserCount()
    this.getUsers(rowsPerPage)
  }

  getUserCount = async () => {
    const { openDialog } = this.context
    const { user } = this.props

    try {
      const token = await user.getIdToken()

      const { data } = await axios.get(`${settings.baseURL}/api/users/count-all-users`, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json'
        }
      })

      this.setState({ count: data.numberOfUsers })
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `retrieve user count`, data })
    }
  }

  getUsers = async count => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { pageTokens, page } = this.state

    try {
      const token = await user.getIdToken()

      const { data } = await axios.get(`${settings.baseURL}/api/users/`, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
          'x-user-count': count,
          'x-page-token': pageTokens[page]
        }
      })

      const users = _.sortBy(data.users, ['displayName'])

      this.setState(
        { users, pageTokens: pageTokens.includes(data.pageToken) ? pageTokens : [...pageTokens, data.pageToken] },
        () => {
          openSnackbar(`Retrieved ${users.length} user records`)
        }
      )
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({ failedAction: `retrieve user records`, data })
    }
  }

  checkUserRoles = async () => {
    const { openDialog } = this.context
    const nonClientUserInfo = [] // to collect non-clients from loop

    // start loading animation
    this.setState({ checkingNonClientUsers: true })

    const collectionRef = firebase.firestore().collection('userData').doc('accounts').collection('active')

    try {
			const snapshot = await collectionRef
				.where('customClaims.role', '!=', 'client')
				.orderBy('customClaims.role')
				.get()

			snapshot.forEach((doc) => {
				const {
					displayName,
					email,
					customClaims: { role },
				} = doc.data()

				nonClientUserInfo.push({
					name: displayName,
					email: email,
					role: role,
				})
			})
		} catch (error) {
			console.log(error)
      const data = extractErrorData(error)
      openDialog({
        failedAction: `Couldn't retrieve user records`, data 
      })
		}

    this.setState(
      {
        nonClientUsers: nonClientUserInfo,
        checkingNonClientUsers: false
      },
      () => {
        const { nonClientUsers } = this.state

        // display non-client users in a dialog box
        const dialogContent = nonClientUsers.map((nonClientUser, index) => (
          <p key={index}>
            {nonClientUser.email} [<strong>{nonClientUser.name}</strong>] - {nonClientUser.role}
          </p>
        ))

        openDialog({
          title: 'Users with non-client roles assigned:', 
          content: dialogContent
        })
      }
    )
  }

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage
      },
      () => {
        const { rowsPerPage } = this.state
        this.getUsers(rowsPerPage)
      }
    )
  }

  handleChangeRowsPerPage = event => {
    this.setState(
      {
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
        pageTokens: ['null']
      },
      () => {
        const { rowsPerPage } = this.state
        this.getUsers(rowsPerPage)
      }
    )
  }

  handleCheckboxChange = uid => {
    const { checkedUsers } = this.state
    if (checkedUsers.includes(uid)) {
      const index = checkedUsers.indexOf(uid) // Find the item to be removed
      checkedUsers.splice(index, 1) // Remove the item

      // Assign the new set of items to state
      this.setState({
        checkedUsers
      })
    } else {
      this.setState({
        checkedUsers: [...checkedUsers, uid]
      })
    }
  }

  openUserForm = (event, type, userRecord) => {
    this.setState(prevState => ({
      userForm: {
        ...prevState.userForm,
        [type]: true
      },
      selectedUser: userRecord || null
    }))
  }

  closeUserForm = callback => {
    this.setState(
      {
        userForm: {
          create: false,
          edit: false
        },
        selectedUser: null
      },
      () => {
        if (callback && typeof callback === 'function') {
          callback()
        }
      }
    )
  }

  handleBulkEditMenuOpen = e => {
    this.setState({ anchorElForBulkEditMenu: e.currentTarget })
  }

  handleBulkEditUsersMenu = value => {
    const bulkEditUserOptions = {}

    if (value === 'editRoles' || value === 'editBoth') bulkEditUserOptions.roles = true
    if (value === 'editCategories' || value === 'editBoth') bulkEditUserOptions.categories = true

    this.setState({
      bulkEditUsersOption: bulkEditUserOptions
    })

    this.setState({ anchorElForBulkEditMenu: null })
  }

  closeBulkEditUser = callback => {
    this.setState(
      {
        bulkEditUsersOption: ''
      },
      () => {
        if (callback && typeof callback === 'function') {
          callback()
        }
      }
    )
  }

  toggleDisabledStatus = async (uid, disabled, name) => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props

    try {
      const token = await user.getIdToken()

      const { data } = await axios.put(
        `${settings.baseURL}/api/users/toggle-disabled-status`,
        {
          uid,
          disabled
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token
          }
        }
      )

      openSnackbar(`User ${disabled ? 'enabled' : 'disabled'}: ${name}.`)
      this.updateUserDataOnUI(data)
    } catch (err) {
      // The response is the opposite of what the disabled variable is, as we set the disabled property to !disabled
      openDialog({
        failedAction: `${disabled ? 'enable' : 'disable'} user`,
        data: err.message
      })
    }
  }

  handleSearchSubmit = async e => {
    e.preventDefault()

    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { searchValue } = this.state

    if (searchValue === '') {
      this.setState(
        {
          pageTokens: ['null'],
          searchedUser: null
        },
        () => {
          const { rowsPerPage } = this.state
          this.getUsers(rowsPerPage)
        }
      )
    } else {
      try {
        const token = await user.getIdToken()

        const { data: selectedUser } = await axios.get(`${settings.baseURL}/api/users/user?email=${searchValue}`, {
          headers: {
            'x-auth-token': token,
            'Content-Type': 'application/json'
          }
        })

        this.setState({ searchedUser: [selectedUser] }, () => {
          openSnackbar(`Retrieved user record for ${selectedUser.displayName}`)
        })
      } catch (err) {
        const data = extractErrorData(err)
        openDialog({ failedAction: `retrieve user records`, data })
      }
    }
  }

  updateUserDataOnUI = data => {
    const { users } = this.state
    const { uid, uids } = data

    // copy of users state
    const updatedUsers = users

    // Function to update user data
    function updateUserData(id) {
      const { name, email, role, categories, disabled } = data

      const selectedUser = _.find(updatedUsers, ['uid', id])
      const selectedUserIndex = _.findIndex(updatedUsers, ['uid', id])

      if (selectedUser) {
        if (name || email || disabled !== undefined) {
          selectedUser.displayName = name || selectedUser.displayName
          selectedUser.email = email || selectedUser.email
          selectedUser.disabled = disabled === undefined ? selectedUser.disabled : disabled
        }

        if (role) selectedUser.customClaims.role = role
        if (categories) selectedUser.customClaims.categories = categories

        updatedUsers[selectedUserIndex] = selectedUser
      }
    }

    // for single user edit
    if (uid) updateUserData(uid)

    // for bulk user edit
    if (uids) {
      uids.forEach(eachUid => {
        updateUserData(eachUid)
      })
    }

    // reassign copy to users in component state
    this.setState({ users: updatedUsers })
  }

  render() {
    const { classes, user, userRole } = this.props
    const {
      users,
      userForm,
      anchorElForBulkEditMenu,
      bulkEditUsersOption,
      selectedUser,
      checkedUsers,
      page,
      rowsPerPage,
      count,
      searchValue,
      searchedUser,
      checkingNonClientUsers
    } = this.state

    const usersToMap = searchedUser || users

    return (
      <Container className={classes.mainContainer} xs={12} maxWidth="lg">
        {
          usersToMap.length < 1
            ? <CircularProgress size={20} thickness={5} />
            : (
              <>
                <PermissibleRender
                  userPermission={userRole}
                  requiredPermissions={['dev', 'editor']}
                >
                  <>
                    <Container className={classes.buttonContainer}>
                      <div>
                        <Button
                          className={classes.button}
                          onClick={e => {
                            this.openUserForm(e, 'create')
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Add New User
                        </Button>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          component={RouterLink}
                          to="/users/import"
                        >
                          Import users in bulk
                        </Button>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          disabled={checkedUsers.length === 0}
                          onClick={this.handleBulkEditMenuOpen}
                        >
                          Edit users in bulk
                        </Button>
                      </div>
                      <div className={classes.checkUserRolesContainer}>
                        {checkingNonClientUsers ? <CircularProgress size={20} thickness={3} /> : null}
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          disabled={count === 0 || checkingNonClientUsers}
                          onClick={this.checkUserRoles}
                        >
                          Check user roles
                        </Button>
                      </div>
                    </Container>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElForBulkEditMenu}
                        keepMounted
                        open={Boolean(anchorElForBulkEditMenu)}
                    >
                      <MenuItem onClick={() => this.handleBulkEditUsersMenu('editRoles')}>Edit Roles Only</MenuItem>
                      <MenuItem onClick={() => this.handleBulkEditUsersMenu('editCategories')}>Edit Categories Only</MenuItem>
                      <MenuItem onClick={() => this.handleBulkEditUsersMenu('editBoth')}>
                        Edit Both Roles and Categories
                      </MenuItem>
                    </Menu>
                  </>
                </PermissibleRender>

                <Paper className={classes.table}>
                  <form onSubmit={this.handleSearchSubmit}>
                    <TextField
                      variant="filled"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                      fullWidth
                      placeholder="Type the exact email of a user to search for them."
                      value={searchValue}
                      onChange={e => this.setState({ searchValue: e.target.value })}
                    />
                  </form>
                  <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Access Level</TableCell>
                        <TableCell>Categories</TableCell>
                        <PermissibleRender userPermission={userRole} requiredPermissions={['dev', 'editor']}>
                          <TableCell align="right">Actions</TableCell>
                        </PermissibleRender>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersToMap.map(userRecord => (
                        <TableRow key={userRecord.uid} style={userRecord.disabled ? { backgroundColor: grey[300] } : null}>
                          <TableCell>
                            <Checkbox
                              checked={checkedUsers.includes(userRecord.uid)}
                              onChange={() => {
                                this.handleCheckboxChange(userRecord.uid)
                              }}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {userRecord.displayName}
                          </TableCell>
                          <TableCell>{userRecord.email}</TableCell>
                          <TableCell>{userRecord.customClaims ? userRecord.customClaims.role : '-'}</TableCell>
                          <TableCell>
                            {userRecord.customClaims &&
                            userRecord.customClaims.categories &&
                            userRecord.customClaims.categories.length !== 0
                              ? userRecord.customClaims.categories.map(category => (
                                  <Chip
                                    key={`category-chip-${category}`}
                                    label={category}
                                    variant="outlined"
                                    size="small"
                                    className={classes.categoryChip}
                                  />
                                ))
                              : '-'}
                          </TableCell>
                          <PermissibleRender userPermission={userRole} requiredPermissions={['dev', 'editor']}>
                            <TableCell align="right">
                              <Tooltip className={classes.actionButton} title="user details">
                                <IconButton
                                  aria-label="user details"
                                  color="primary"
                                  size="small"
                                  onClick={e => {
                                    this.openUserForm(e, 'edit', userRecord)
                                  }}
                                >
                                  <MoreOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                className={classes.actionButton}
                                title={userRecord.disabled ? 'Enable user' : 'Block user'}
                              >
                                <IconButton
                                  aria-label="block user"
                                  color={userRecord.disabled ? 'secondary' : 'primary'}
                                  size="small"
                                  onClick={() =>
                                    this.toggleDisabledStatus(userRecord.uid, userRecord.disabled, userRecord.displayName)
                                  }
                                >
                                  <BlockIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </PermissibleRender>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>

                <UserForm
                  open={userForm.create}
                  onClose={this.closeUserForm}
                  user={user}
                  type="create"
                  options={{ displayName: true, email: true, password: true, roles: true, categories: true }}
                />

                <UserForm
                  open={userForm.edit}
                  selectedUser={selectedUser}
                  onClose={this.closeUserForm}
                  user={user}
                  updateUsersOnUI={this.updateUserDataOnUI}
                  type="edit"
                  options={{ displayName: true, email: true, roles: true, categories: true }}
                />

                <UserForm
                  open={Boolean(bulkEditUsersOption)}
                  type="bulkEdit"
                  options={bulkEditUsersOption}
                  user={user}
                  uids={checkedUsers}
                  onClose={this.closeBulkEditUser}
                  updateUsersOnUI={this.updateUserDataOnUI}
                />
              </>
            )
        }
      </Container>
    )
  }
}

export default withStyles(styles)(Users)
