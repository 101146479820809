export default function commonStyles(theme) {
  return {
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minHeight: '100vh',
      padding: theme.spacing(0, 4, 0, 4)
    },
    hiddenInput: {
      display: 'none'
    },
    csvButton: {
      marginTop: theme.spacing(1),
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    paperStyles: {
      padding: '24px',
    },
    rightAlignedButtonContainer: {
      // width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    spaceBetweenAlignedButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    adminButtons: {
      marginBottom: theme.spacing(1)
    },
    adminButton: {
      marginLeft: theme.spacing(.5)
    },
    dialogStyles: {
      padding: '15px',
      maxHeight: '90vh'
    },
    sectionDiv: {
      padding: '10px',
      marginBottom: '15px',
      marginTop: '15px',
      borderRadius: '2px',
      background: '#f5f5f5'
    }
  }
}
