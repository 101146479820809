import React, { Component } from 'react'

import { Container, withStyles, Button, Typography } from '@material-ui/core'

import { commonStyles, formStyles } from '../../../styles'
import { PackageForm } from '../../forms'

import { extractErrorData, algoliaDataUpdate } from '../../helpers'
import { FunctionContext } from '../../contexts'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'

const styles = theme => ({ ...commonStyles(theme), ...formStyles(theme) })

class CreatePackage extends Component {
  static contextType = FunctionContext

  handleSubmit = async formData => {
    const { openDialog, openSnackbar } = this.context
    const { user, history } = this.props

    const productIds = formData.products.map(item => item.value)

    try {
      const data = await databaseQueryInterface.post(`${settings.baseURL}/api/packages/`, user, {
        ...formData,
        products: productIds
      })

      const { id, name } = data
      
      algoliaDataUpdate({
        objectID: id,
        name,
        type: 'packages',
        index: 'packages',
        operation: 'add'
      })

      openSnackbar(`Created new package - ${data.name}`)
      history.push('/packages')
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `create package`,
        data
      })
    }
  }

  render() {
    const { user, classes, history } = this.props
    return (
      <Container>
        <Typography variant="h1">Create a new Package</Typography>

        <br />

        <PackageForm user={user} handleSubmit={this.handleSubmit} />

        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="secondary" onClick={history.goBack} className={classes.actionButton}>
            Cancel
          </Button>
        </div>
      </Container>
    )
  }
}

export default withStyles(styles)(CreatePackage)
