import React, { Component } from 'react'

import { Container, withStyles, Button, Typography } from '@material-ui/core'

import { commonStyles, formStyles } from '../../../styles'
import { PackageForm } from '../../forms'

import { extractErrorData, algoliaDataUpdate } from '../../helpers'
import { FunctionContext } from '../../contexts'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'

const styles = theme => ({ ...commonStyles(theme), ...formStyles(theme) })

class EditPackage extends Component {
  static contextType = FunctionContext

  state = {
    selectedPackage: null
  }

  componentDidMount() {
    this.getPackageData()
  }

  getPackageData = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params }
    } = this.props

    try {
      const data = await databaseQueryInterface.get(
        `${settings.baseURL}/api/packages/${params.id}?showDeleted=true&showDeletedProducts=true`,
        user
      )

      this.setState({ selectedPackage: data }, () => openSnackbar(`Retrieved package - ${data.name}`))
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve package records`,
        data
      })
    }
  }

  handleSubmit = async formData => {
    const { openDialog, openSnackbar } = this.context
    const { user, history } = this.props
    const { selectedPackage } = this.state

    const productIds = formData.products.map(item => item.value)

    try {
      const data = await databaseQueryInterface.put(`${settings.baseURL}/api/packages/${selectedPackage.id}`, user, {
        ...formData,
        products: productIds
      })

      const { id, name } = data

      algoliaDataUpdate({
        objectID: id,
        name,
        type: 'packages',
        index: 'packages',
        operation: 'edit'
      })

      openSnackbar(`Edited Package - ${data.name}`)
      history.push(`/packages/view/${selectedPackage.id}`)
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `edit package`,
        data
      })
    }
  }

  render() {
    const { user, classes, history } = this.props
    const { selectedPackage } = this.state

    return (
      <Container>
        <Typography variant="h1">Edit {selectedPackage && selectedPackage.name}</Typography>

        <br />

        <PackageForm user={user} handleSubmit={this.handleSubmit} currentValues={selectedPackage} />

        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="secondary" onClick={history.goBack} className={classes.actionButton}>
            Cancel
          </Button>
        </div>
      </Container>
    )
  }
}

export default withStyles(styles)(EditPackage)
