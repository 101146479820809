import React, { Component } from 'react'

import { withStyles, Container, Grid, Paper, Slide } from '@material-ui/core'

import DetailsPane from './DetailsPane'

import { AlphabeticalPaginationBar, CustomList, AdminButtons, ShowRemovedSwitch } from '../../blocks'
import { ConfirmationDialog } from '../../Dialogs'

import { commonStyles } from '../../../styles'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import {
  extractErrorData,
  findParentCompany,
  getDataForPage,
  algoliaDataUpdate,
  getPaginationButtons
} from '../../helpers'
import { updateInstitutionList } from '../../lib/institution'
import { ShowSkeleton } from '../../utilities'

const styles = theme => ({ ...commonStyles(theme) })

class Institutions extends Component {
  static contextType = FunctionContext

  state = {
    institutions: [], // Array of institutions - retrieved from backend
    institutionsToDisplay: [], // Array of institutions to display
    paginationButtons: [], // List of labels for the alphabetical pagination bar at the top of the page
    selectedInstitution: null, // Institution to display in the details pane
    showRemoved: false, // Boolean to show removed items
    openRemoveDialog: false, // Boolean to show dialog to remove Institution
    openDeleteDialog: false // Boolean to show dialog to permanently delete Institution
  }

  componentDidMount() {
    this.getInstitutionData()
  }

  getInstitutionData = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props

    try {
      const data = await databaseQueryInterface.get(`${settings.baseURL}/api/institutions/?showDeleted=true`, user)

      const paginationButtons = getPaginationButtons(data)

      this.setState({ institutions: data, institutionsToDisplay: data, paginationButtons }, () =>
        openSnackbar(`Retrieved ${data.length} institutions`)
      )
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve Institution records`,
        data
      })
    }
  }

  handleInsitutionSelect = (event, institution) => {
    this.setState({ selectedInstitution: institution })

    // scroll to top (200px from top)
    document.body.scrollTop = 200; // For Safari
    document.documentElement.scrollTop = 200; // For Chrome, Firefox, IE and Opera
  }

  handleCloseDetailsPane = () => this.setState({ selectedInstitution: null })

  handleShowRemovedSwitchChange = () => this.setState(prevState => ({ showRemoved: !prevState.showRemoved }))

  handlePaginationSelect = (event, buttonValue) => {
    const { institutions } = this.state

    const institutionsToDisplay = getDataForPage(buttonValue, institutions)

    this.setState({ institutionsToDisplay })
  }

  handleAdd = () => {
    const { history } = this.props

    history.push('/institutions/create')
  }

  handleEdit = () => {
    const { history } = this.props
    const { selectedInstitution } = this.state

    history.push(`/institutions/edit/${selectedInstitution.id}`)
  }

  handleRemove = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { selectedInstitution } = this.state

    try {
      const data = await databaseQueryInterface.put(
        `${settings.baseURL}/api/institutions/${selectedInstitution.id}/remove`, // This route acts as a toggle
        user
      )

      this.setState({ openRemoveDialog: false })

      openSnackbar(`${data.deletedAt ? 'Removed' : 'Restored'} Institution: ${data.name}`)

      this.updateUi(data)
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `${data.deletedAt === null ? 'remove' : 'restore'} institution`,
        data
      })
    }
  }

  handleDelete = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { selectedInstitution } = this.state

    try {
      const data = await databaseQueryInterface.destroy(
        `${settings.baseURL}/api/institutions/${selectedInstitution.id}`,
        user
      )

      this.setState({ openDeleteDialog: false, selectedInstitution: null })

      algoliaDataUpdate({
        objectID: data.id,
        index: 'institutions',
        operation: 'delete'
      })

      openSnackbar(`Permanently deleted institution: ${data.name}`)
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `delete institution`,
        data
      })
    }
  }

  handleRemoveDialogOpen = () => this.setState({ openRemoveDialog: true })

  handleRemoveDialogClose = () => this.setState({ openRemoveDialog: false })

  handleDeleteDialogOpen = () => this.setState({ openDeleteDialog: true })

  handleDeleteDialogClose = () => this.setState({ openDeleteDialog: false })

  updateUi = newInstitutionData => {
    const { institutions, institutionsToDisplay, selectedInstitution } = this.state

    const { listOfInstitutions, listOfInstitutionsToDisplay } = updateInstitutionList(
      institutions,
      newInstitutionData,
      institutionsToDisplay
    )

    this.setState({
      institutions: listOfInstitutions,
      institutionsToDisplay: listOfInstitutionsToDisplay,
      selectedInstitution: selectedInstitution.id === newInstitutionData.id ? newInstitutionData : selectedInstitution
    })
  }

  render() {
    const { classes, userRole, history } = this.props
    const {
      paginationButtons,
      institutionsToDisplay,
      selectedInstitution,
      institutions,
      showRemoved,
      openDeleteDialog,
      openRemoveDialog
    } = this.state

    return (
      <Container>
        <Grid container direction="column" justifyContent="space-around" alignItems="center" spacing={5}>
          <AdminButtons userRole={userRole} classes={classes} handleAddClick={this.handleAdd} />

          <Grid item xs={12}>
            <AlphabeticalPaginationBar
              paginationButtons={paginationButtons}
              handlePaginationSelect={this.handlePaginationSelect}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={3} justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={12}>
            <ShowRemovedSwitch
              checked={showRemoved}
              handleChange={this.handleShowRemovedSwitchChange}
              name="showRemoved"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Display a skeleton if we are still loading institution data */}
            <ShowSkeleton variant="rect" height={400} condition={institutions.length === 0}>
              <Paper className={classes.paperStyles}>
                <CustomList
                  itemsToList={institutionsToDisplay}
                  handleItemSelect={this.handleInsitutionSelect}
                  showRemoved={showRemoved}
                />
              </Paper>
            </ShowSkeleton>
          </Grid>

          <Slide direction="left" timeout={300} in={!!selectedInstitution} mountOnEnter unmountOnExit>
            <Grid item xs={12} md={6}>
              <DetailsPane
                classes={classes}
                userRole={userRole}
                selectedInstitution={selectedInstitution}
                handleClose={this.handleCloseDetailsPane}
                findParentCompany={parentId => findParentCompany(parentId, institutions)}
                history={history}
                handleEdit={this.handleEdit}
                handleRemove={this.handleRemoveDialogOpen}
                handleDelete={this.handleDeleteDialogOpen}
              />
            </Grid>
          </Slide>
        </Grid>

        <ConfirmationDialog
          open={openRemoveDialog}
          closeDialog={this.handleRemoveDialogClose}
          text={`Are you sure you want to ${
            selectedInstitution && selectedInstitution.deletedAt === null ? 'remove' : 'restore'
          } "${selectedInstitution && selectedInstitution.name}"?`}
          handleConfirm={this.handleRemove}
        />

        <ConfirmationDialog
          open={openDeleteDialog}
          closeDialog={this.handleDeleteDialogClose}
          text={`Are you sure you want to delete "${selectedInstitution && selectedInstitution.name}"?`}
          handleConfirm={this.handleDelete}
          extraSecurity
        />
      </Container>
    )
  }
}

export default withStyles(styles)(Institutions)
