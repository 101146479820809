import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import {
  Container,
  InputBase,
  Card,
  CardContent,
  Link
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { withStyles } from '@material-ui/core/styles'

import { InstantSearch, connectSearchBox, connectHits, Index } from 'react-instantsearch-dom'

import { searchStyles } from '../../../styles'
import { algoliaClient } from '../../services'

const styles = theme => ({ ...searchStyles(theme) })

// custom search box
const SearchBox = ({ currentRefinement, refine, classes }) => (
  <div className={classes.searchBox}>
    <div className={classes.searchIcon}>
      <SearchIcon />
    </div>
    <InputBase
      autoFocus
      placeholder="Search…"
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
      }}
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
    />
  </div>
)

// custom results container (hits)
const Hits = ({ indexName, hits, classes }) => (
  hits.length > 0 
  ? <>
      <h2>{indexName}</h2>
      <Container maxWidth="lg" className={classes.hits}>
        {
          hits.map(({ objectID, name, email, type }) => (
            <Link
              to={`/${type}/view/${objectID}`}
              component={RouterLink}
              color="inherit"
              key={objectID}
              className={classes.link}
            >
              <Card className={classes.hit}>
                <CardContent>{ `${name}${email ? ` -- ${email}` : ''}` }</CardContent>
              </Card>
            </Link>
          ))
        }
      </Container>
    </>
  : null
)

// connect the custom search box and 'hits' component using the connectors
const CustomSearchBox = connectSearchBox(SearchBox)
const CustomHits = connectHits(Hits)

// items for the dropdown
// 'value' represents the Algolia index name 
const indexList = [
  { name: "Products", value: "products" },
  { name: "Packages", value: "packages" },
  { name: "People", value: "people" },
  { name: "Institutions", value: "institutions" },
]

// select search API key for the correct app on Algolia depending on environment
const apiKey = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_ALGOLIA_DEV_SEARCH_API_KEY
  : process.env.REACT_APP_ALGOLIA_PROD_SEARCH_API_KEY

class Search extends Component {
  render() {
    const { classes } = this.props

    return (
      <Container maxWidth="lg">
        <InstantSearch
          searchClient={algoliaClient(apiKey)}
          indexName={'products'}
        >
          <Container className={classes.inputs}>
            <CustomSearchBox
              searchAsYouType={true}
              showLoadingIndicator={true}
              translations={{ placeholder: 'Search...' }}
              classes={classes}
            />
          </Container>
          {
            indexList.map(({ name, value }, key) => (
              <Index indexName={value} key={key}>
                <CustomHits 
                  indexName={name}
                  key={key}
                  classes={classes}
                />
              </Index>
            ))
          }
        </InstantSearch>        
      </Container>
    )
  }
}

export default withStyles(styles)(Search)