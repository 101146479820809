import React from 'react'

import { withStyles, Container, Typography, Button } from '@material-ui/core'

import { InstitutionForm } from '../../forms'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { extractErrorData, algoliaDataUpdate } from '../../helpers'
import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

class CreateInstitution extends React.Component {
  static contextType = FunctionContext

  handleSubmit = async formData => {
    const { openDialog, openSnackbar } = this.context
    const { user, history } = this.props
    try {
      const data = await databaseQueryInterface.post(`${settings.baseURL}/api/institutions`, user, { ...formData })

      const { id, name } = data

      algoliaDataUpdate({
        objectID: id,
        name,
        type: 'institutions',
        index: 'institutions',
        operation: 'add'
      })

      openSnackbar(`Created Institution: ${data.name}`)
      history.push('/institutions')
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({
        failedAction: `create institution`,
        data
      })
    }
  }

  render() {
    const { user, classes, history } = this.props

    return (
      <Container>
        <Typography variant="h1">Create a new Institution</Typography>

        <br />

        <InstitutionForm user={user} handleSubmit={this.handleSubmit} />

        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="secondary" onClick={history.goBack} className={classes.actionButton}>
            Cancel
          </Button>
        </div>
      </Container>
    )
  }
}

export default withStyles(styles)(CreateInstitution)
