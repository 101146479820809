export default function formatDataForAutocomplete(data) {
  const tempArray = []

  data.forEach(item => {
    tempArray.push({
      name: item.deletedAt ? `${item.name} (Removed)` : item.name,
      value: item.id,
      deletedAt: item.deletedAt
    })
  })

  return tempArray
}
