import Papa from 'papaparse'
import setHeaders from '../../database/setQueryHeaders'

export default async function parseMailingListCSV({ file, url, user, showErrorDialog, setCsvData, mailingListName }) {
  Papa.parse(file ? file : url, {
    download: url && url,
    downloadRequestHeaders: url && await setHeaders(user),
    header: true,
    skipEmptyLines: 'greedy',
    transform(value, header) {
      return header === 'Company' || header === 'Group' ? value : value.replace(/\s/g, '').split(';')
    },
    complete(results) {
      const { data, errors } = results

      if (errors.length > 0) {
        showErrorDialog(errors)
      } else {
        setCsvData(data, file ? file.name : mailingListName)
      }
    },
    error(error) {
      alert(error.message)
    }
  })
}
