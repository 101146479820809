import React from 'react'

import { Container, Button, withStyles, Typography } from '@material-ui/core'

import { ProductForm } from '../../forms'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { extractErrorData, algoliaDataUpdate } from '../../helpers'

import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

class CreateProduct extends React.Component {
  static contextType = FunctionContext

  handleSubmit = async formData => {
    const { openDialog, openSnackbar } = this.context
    const { user, history } = this.props
    try {
      const data = await databaseQueryInterface.post(`${settings.baseURL}/api/products`, user, {
        ...formData
      })

      const { id, name } = data
      
      algoliaDataUpdate({
        objectID: id,
        name,
        type: 'products',
        index: 'products',
        operation: 'add'
      })

      openSnackbar(`Created Product: ${data.name}`)
      history.push('/products')
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({
        failedAction: `create product`,
        data
      })
    }
  }

  render() {
    const { user, classes, history } = this.props

    return (
      <Container>
        <Typography variant="h1">Create a new Product</Typography>

        <br />

        <ProductForm user={user} handleSubmit={this.handleSubmit} />

        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="secondary" onClick={history.goBack} className={classes.actionButton}>
            Cancel
          </Button>
        </div>
      </Container>
    )
  }
}

export default withStyles(styles)(CreateProduct)
