import ColorHash from 'color-hash'

export default function generateColor(text) {
  const colorHash = new ColorHash({
    lightness: [0.7, 0.8, 0.9],
    saturation: [0.5, 0.6, 0.7],
    hue: [{ min: 30, max: 90 }, { min: 180, max: 210 }, { min: 270, max: 285 }]
  })

  return colorHash.hex(text)
}
