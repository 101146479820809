import React from 'react'
import _ from 'lodash'

import { withStyles, TextField, FormHelperText } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

const SelectField = ({
  name, // name of the field
  label, // label of the field
  value, // return value of the field
  handleChange, // function to decide what happens when the field value is changed
  touched, // determines if the field has been touched (i.e. a user clicked the field and then moved a different field)
  errors, // list of errors to be displayed, if any
  fullWidth, // determines if the field should be rendered to take up the full width of its parent
  items, // List of items to be selected from
  includeNone, // Boolean to decide whether to include a "None" option as well
  disabledOptionIds, // Array of ids of options to render as disabled
  disabled, // Boolean to determine if component should be disabled
  classes // CSS Classes passed from parent component
}) => {
  const itemsWithEmpty = [{ name: 'None', value: '' }, ...items]
  const itemsToIterate = includeNone ? itemsWithEmpty : items

  // Used to find the selected value, if it exists within the options given
  const findValue = valueToFind => _.find(itemsToIterate, ['value', valueToFind])

  // Set the disabledOptionIds to [] if no such prop is supplied
  const disabledOptions = disabledOptionIds || []

  // eslint-disable-next-line no-unused-vars
  const styleRemovedItems = (option, optionState) => {
    const { name: optionName, deletedAt } = option

    return <span style={{ color: deletedAt ? '#de2c2c' : 'inherit' }}>{optionName}</span>
  }
  // Since some options may later be soft-deleted, we disable the option from being removed

  // IMPORTANT:
  // The Autocomplete component from M-UI takes 'null' as the empty value, rather than an empty string ''
  return (
    <>
      <Autocomplete
        className={classes.field}
        variant="outlined"
        fullWidth={fullWidth}
        id={name}
        name={name}
        // We display null if the value is not part of the given list - itemsToIterate
        value={findValue(value) ? findValue(value) : null}
        onChange={(e, selectedOption) => {
          // If there is a selectedOption, then we set that value. If not, we set an empty string
          handleChange(selectedOption ? selectedOption.value : '')
        }}
        getOptionDisabled={option => disabledOptions.includes(option.value)}
        getOptionSelected={(option, selectedValue) => option.name === selectedValue.name}
        disabled={disabled || items.length === 0}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            margin="dense"
            label={label}
            name={name}
            helperText={touched && errors}
            error={touched && Boolean(errors)}
          />
        )}
        renderOption={styleRemovedItems}
        options={_.orderBy(itemsToIterate, ['deletedAt', 'name'], ['desc', 'asc'])}
        getOptionLabel={option => (option.name ? option.name : '')}
      />
      {items.length === 0 ? <FormHelperText className={classes.helperText}>Disabled</FormHelperText> : null}
    </>
  )
}

export default withStyles(styles)(SelectField)
