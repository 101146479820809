import React, { Component } from 'react'

import { withStyles, Container, Typography, Button } from '@material-ui/core'

import { PersonForm } from '../../forms'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import { FunctionContext } from '../../contexts'
import settings from '../../../settings'
import { extractErrorData, algoliaDataUpdate } from '../../helpers'
import { commonStyles, formStyles } from '../../../styles'

const styles = theme => ({ ...commonStyles(theme), ...formStyles(theme) })

class EditPerson extends Component {
  static contextType = FunctionContext

  state = {
    userDetails: null
  }

  componentDidMount() {
    this.getUserData()
  }

  getUserData = async () => {
    const {
      user,
      match: { params }
    } = this.props

    const { openSnackbar, openDialog } = this.context

    try {
      const data = await databaseQueryInterface.get(`${settings.baseURL}/api/users-new/${params.id}`, user)

      this.setState({ userDetails: data }, () => {
        openSnackbar(`Retrieved data for ${data.name}`)
      })
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve user records`,
        data
      })
    }
  }

  handleSubmit = async formData => {
    const {
      user,
      history,
      match: { params }
    } = this.props

    const { openSnackbar, openDialog } = this.context

    try {
      const userData = await databaseQueryInterface.put(`${settings.baseURL}/api/users-new/${params.id}`, user, {
        ...formData
      })

      const { id, name, primaryEmail } = userData
      
      algoliaDataUpdate({
        objectID: id,
        name,
        email: primaryEmail,
        type: 'person', // 'type' attribute is used to add links to search results -> /person/view/:id
        index: 'people',
        operation: 'edit'
      })

      await databaseQueryInterface.put(`${settings.baseURL}/api/users-new/${userData.id}/edit-subscriptions`, user, {
        subscriptions: [...formData.subscriptions]
      })

      openSnackbar(`Successfully edited user - ${userData.name}`)
      history.push(`/person/view/${params.id}`)
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      console.log(data)
      openDialog({
        failedAction: `edit user record`,
        data
      })
    }
  }

  render() {
    const { user, history, classes, userRole } = this.props
    const { userDetails } = this.state

    return (
      <Container>
        <Typography variant="h1">Edit user - {userDetails && userDetails.name}</Typography>

        <br />

        <PersonForm user={user} userRole={userRole} handleSubmit={this.handleSubmit} currentValues={userDetails} />

        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="secondary" onClick={history.goBack} className={classes.actionButton}>
            Cancel
          </Button>
        </div>
      </Container>
    )
  }
}

export default withStyles(styles)(EditPerson)
