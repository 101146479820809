export default function createQueryString(queriesObject) {
  const arrayOfQueryStrings = []

  // Create query strings in correct format and add to array
  Object.keys(queriesObject).forEach(key => {
    if (queriesObject[key]) return arrayOfQueryStrings.push(`${key}=${queriesObject[key]}`)
  })

  // Create concatenated query string
  let finalQuery = ''

  arrayOfQueryStrings.forEach((query, index) => {
    if (query !== '') {
      if (index === 0) return (finalQuery = `?${finalQuery}${query}`)

      return (finalQuery = `${finalQuery}&${query}`)
    }
  })

  return finalQuery
}
