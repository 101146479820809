import * as Yup from 'yup'

export const validationSchema = Yup.object({
  name: Yup.string()
    .max(255, 'Must be 255 characters or less')
    .required('Required')
})

export const initialFormData = {
  name: '' // Name of the product category
}
