export function mapCurrentValuesToForm(currentValues) {
  return {
    institutionId: currentValues.id,
    contactPoint: currentValues.UserDetails.contactPoint
  }
}

export function formatUserDetailFormSubmit(selectedInstitution, submitValues) {
  return {
    id: selectedInstitution.value,
    name: selectedInstitution.name,
    deletedAt: selectedInstitution.deletedAt,
    UserDetails: {
      contactPoint: submitValues.contactPoint
    }
  }
}
