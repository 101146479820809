import React, { Component } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'

class CustomMessageDialog extends Component {
  render() {
    const { dialog, closeDialog } = this.props

    return (
      <Dialog
        open={dialog.open}
        onClose={() => {
          closeDialog()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
        <DialogContent>{dialog.content}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeDialog()
            }}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default CustomMessageDialog
