import React, { Component } from 'react'

import {
  withStyles,
  Container,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Chip
} from '@material-ui/core'

import { AlphabeticalPaginationBar, AdminButtons, CustomList, ShowRemovedSwitch } from '../../blocks'
import { ConfirmationDialog } from '../../Dialogs'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import {
  extractErrorData,
  getPaginationButtons,
  getDataForPage,
  algoliaDataUpdate,
  filterRemovedItems
} from '../../helpers'
import { formatUsers, countActiveUsers } from '../../lib/product'
import { FunctionContext } from '../../contexts'
import settings from '../../../settings'
import { commonStyles } from '../../../styles'

const styles = theme => ({ ...commonStyles(theme) })

class Product extends Component {
  static contextType = FunctionContext

  state = {
    productData: null,
    paginationButtons: [],
    usersToDisplay: null,
    filteredUsers: [],
    openRemoveDialog: false,
    openDeleteDialog: false,
    showRemovedUsers: false
  }

  componentDidMount() {
    this.getProductData()
  }

  getProductData = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params }
    } = this.props

    try {
      const data = await databaseQueryInterface.get(`${settings.baseURL}/api/products/${params.id}`, user)

      this.setState({ productData: data, usersToDisplay: filterRemovedItems(data.users, 'Subscription.deletedAt', false) }, () => {
        openSnackbar(`Retrieved data for ${data.name}`)
        this.filterUsers()
      })
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve product data`,
        data
      })
    }
  }

  handlePaginationSelect = (event, buttonValue) => {
    const { filteredUsers } = this.state

    const usersToDisplay = getDataForPage(buttonValue, filteredUsers)

    this.setState({ usersToDisplay })
  }

  filterUsers = () => {
    const { productData, showRemovedUsers } = this.state

    const filteredUsers = filterRemovedItems(productData.users, 'Subscription.deletedAt', showRemovedUsers)

    const paginationButtons = getPaginationButtons(filteredUsers)

    this.setState({ filteredUsers, paginationButtons, usersToDisplay: filteredUsers })
  }

  removeProduct = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params }
    } = this.props

    try {
      const data = await databaseQueryInterface.put(`${settings.baseURL}/api/products/${params.id}/remove`, user)

      this.setState({ openRemoveDialog: false }, () => {
        openSnackbar(
          `The product "${data.name}" was successfully ${
            data.deletedAt ? 'removed' : 'restored'
          }. This action is reversible`
        )
        this.getProductData()
      })
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `remove/restore product`,
        data
      })
    }
  }

  deleteProduct = async () => {
    const { openDialog } = this.context
    const { user, history } = this.props
    const { productData } = this.state

    try {
      const data = await databaseQueryInterface.destroy(`${settings.baseURL}/api/products/${productData.id}`, user)

      this.setState({ openDeleteDialog: false })

      algoliaDataUpdate({
        objectID: data.id,
        index: 'products',
        operation: 'delete'
      })

      history.goBack()
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `delete product`, data })
    }
  }

  handleRemoveDialogOpen = () => this.setState({ openRemoveDialog: true })

  handleRemoveDialogClose = () => this.setState({ openRemoveDialog: false })

  handleDeleteDialogOpen = () => this.setState({ openDeleteDialog: true })

  handleDeleteDialogClose = () => this.setState({ openDeleteDialog: false })

  handleRemovedSwitchChange = () => {
    this.setState(prevState => ({ showRemovedUsers: !prevState.showRemovedUsers }), () => this.filterUsers())
  }

  handleDownload = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { productData } = this.state

    try {
      const downloadRoute = `${settings.baseURL}/api/products/${productData.id}/download/mailing`

      const csv = await databaseQueryInterface.get(downloadRoute, user)

      // create an <a> tag and trigger a click to download file
      const link = document.createElement('a')

      link.href = window.URL.createObjectURL(new Blob([csv]))
      link.setAttribute('download', `${productData.name} mailing list.csv`)
      
      document.body.appendChild(link)
      link.click()
      link.remove()

      openSnackbar(`Downloaded mailing list for product.`)
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `download product details`,
        data
      })
    }
  }

  render() {
    const { classes, userRole, history } = this.props
    const {
      productData,
      openRemoveDialog,
      openDeleteDialog,
      paginationButtons,
      usersToDisplay,
      showRemovedUsers
    } = this.state

    return (
      <Container>
        {productData ? (
          <Grid container justifyContent="space-between" alignItems="flex-start" spacing={3}>
            {/* Product info row */}
            <Grid item xs={12}>
              <Paper className={classes.paperStyles}>
                <div className={classes.rightAlignedButtonContainer}>
                  <AdminButtons
                    userRole={userRole}
                    classes={classes}
                    item={productData}
                    handleDownloadClick={this.handleDownload}
                    handleEditClick={() => history.push(`/products/edit/${productData.id}`)}
                    handleAddUsersClick={() => history.push(`/products/add-users/${productData.id}`)}
                    handleRemoveClick={() => this.handleRemoveDialogOpen()}
                    handleDeleteClick={() => this.handleDeleteDialogOpen()}
                  />
                </div>

                <Typography variant="h1">
                  {productData.name}

                  {productData.deletedAt ? (
                    <Chip label="Removed" color="secondary" variant="outlined" style={{ marginLeft: '10px' }} />
                  ) : null}
                </Typography>

                <Typography variant="overline">Under Sub-Category: {productData.subcategory.name}</Typography>

                {productData.description && <Typography>{productData.description}</Typography>}

                <Container className={classes.sectionDiv}>
                  <Typography variant="overline">Active Subscribers: {countActiveUsers(productData.users)}</Typography>
                </Container>
              </Paper>
            </Grid>

            {/* People info column */}
            <Grid item xs={12}>
              <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={3}>
                <Grid item>
                  <Paper className={classes.paperStyles}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={3}>
                      <ShowRemovedSwitch
                        checked={showRemovedUsers}
                        handleChange={this.handleRemovedSwitchChange}
                        name="showRemoved"
                      />

                      <Grid item>
                        <Grid container direction="column" justifyContent="space-between" alignItems="center" spacing={3}>
                          <Grid item>
                            <AlphabeticalPaginationBar
                              paginationButtons={paginationButtons}
                              handlePaginationSelect={this.handlePaginationSelect}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <CustomList
                          itemsToList={formatUsers(usersToDisplay)}
                          urlModel="/person/view/"
                          customChips={[{ path: 'email' }, { path: 'mailingPreference' }]}
                          showRemoved
                          removedAssociationPath='subscription.deletedAt'
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={3}>
                <Grid item>
                  <Paper className={classes.paperStyles}>
                    <Typography variant="h2">Notes</Typography>

                    <Typography>{productData.notes}</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <ConfirmationDialog
              open={openRemoveDialog}
              closeDialog={this.handleRemoveDialogClose}
              text={`Are you sure you want to ${productData.deletedAt ? 'restore' : 'remove'} "${productData.name}"?`}
              handleConfirm={this.removeProduct}
            />

            <ConfirmationDialog
              open={openDeleteDialog}
              closeDialog={this.handleDeleteDialogClose}
              text={`Are you sure you want to ${productData.deletedAt ? 'restore' : 'remove'} "${productData.name}"?`}
              handleConfirm={this.deleteProduct}
              extraSecurity
            />
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </Container>
    )
  }
}

export default withStyles(styles)(Product)
