export default function navbarStyles(theme) {
  return {
    mainContainer: {
      top: '0px'
    },
    menuButton: {
      marginRight: theme.spacing(1)
    },
    list: {
      width: '20vw',
      '& a': {
        textDecoration: 'none',
        color: 'black'
      }
    },
    nestedMenuItem: {
      paddingLeft: theme.spacing(4)
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(1, 0),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    }
  }
}
