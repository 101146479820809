export default function changelogStyles(theme) {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    chip: {
      margin: theme.spacing(0, 1, 1, 0)
    },
    button: {
      margin: theme.spacing(3, 0)
    }
  }
}
