import * as Yup from 'yup'

export const validationSchema = Yup.object({
  name: Yup.string()
    .max(255, 'Must be 255 characters or less')
    .required('Required'),
  industry: Yup.string().max(255, 'Must be 255 characters or less'),
  size: Yup.number(),
  address: Yup.string().max(255, 'Must be 255 characters or less'),
  taxNumber: Yup.string().max(255, 'Must be 255 characters or less'),
  joinDate: Yup.date(),
  parentId: Yup.number(),
  notes: Yup.string()
})

export const initialFormData = {
  name: '', // Name of the Institution
  industry: '', // Industry of the Institution
  size: '', // No. of employees of the Institution
  address: '', // Address of the Institution
  taxNumber: '', // Tax Number of the Institution
  joinDate: new Date(), // Date that the Institution was added to our lists
  parentId: '', // Name of the Parent Institution
  packages: [], // Array of packages subscribed to by the Institution
  notes: '' // Additional Notes
}
