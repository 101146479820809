import React from 'react'

import { withStyles, FormHelperText } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

const DatePickerField = ({ name, label, value, handleChange, touched, errors, fullWidth, disabled, classes }) => (
  <>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        className={classes.field}
        disabled={disabled}
        inputVariant="outlined"
        variant="inline"
        format="DD/MM/yyyy"
        fullWidth={fullWidth}
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        error={touched && Boolean(errors)}
        helperText={touched && errors}
      />
    </MuiPickersUtilsProvider>

    {disabled && <FormHelperText className={classes.field}>Disabled</FormHelperText>}
  </>
)

export default withStyles(styles)(DatePickerField)
