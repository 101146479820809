export default function searchStyles(theme) {
  return {
    inputs: {
      display: 'flex',
      justifyContent: 'space-around'
    },
    searchBox: {
      width: '50%',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#CCCCCC',
      "&:hover": {
        backgroundColor: '#AAAAAA',
      },
      display: 'flex',
      alignItems: 'center'
    },
    searchIcon: {
      padding: theme.spacing(2),
      pointerEvents: "none",
      display: "flex",
    },
    inputRoot: {
      width: '90%'
    },
    inputInput: {
      padding: theme.spacing(2),
      color: theme.palette.common.black,
      width: "100%",
    },
    hits: {
      display: 'flex',
      flexFlow: 'row wrap'
    },
    hit: {
      margin: theme.spacing(1)
    },
    link: {
      transition: '.2s',
      '&:hover': { 
        textDecoration: 'none',
        fontWeight: 'bold'
      }
    }
  }
}
