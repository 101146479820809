import _ from 'lodash'

// Function to check bad Formats - used in filterMailingListCSV function below
function checkBadFormats(email, validEmailFormat, badFormatsArray, index, record, column) {
  if (email && !validEmailFormat.test(email)) {
    badFormatsArray.push({
      type: 'bad format error',
      line: index + 2,
      company: record.Company || record.Group,
      error: email,
      column
    })
  }
}

// Function to check for duplicates - used in filterMailingListCSV function below
function checkDuplicates(uniqueCheckList, email, duplicatesArray, index, record) {
  // Check if uniqueCheckList includes this email
  const emailInUniqueCheckList = _.find(uniqueCheckList, ['email', email])
  if (emailInUniqueCheckList) {
    // If it does, then check if duplicatesArray already includes it
    if (_.find(duplicatesArray, ['email', email])) {
      // If so, then increment the count for that entry
      const idx = _.findIndex(duplicatesArray, ['email', email])
      duplicatesArray[idx] = {
        ...duplicatesArray[idx],
        errors: { email, count: duplicatesArray[idx].errors.count + 1 }
      }
    } else {
      // If not, add it to the duplicates array
      duplicatesArray.push({
        type: 'duplicate error',
        line: emailInUniqueCheckList.row, // We use the row from emailInUniqueCheckList, since that was the first time we encountered
        company: record.Company || record.Group,
        errors: { email, count: 2 }, // count starts as 2, as this is the second time we are encountering this email
        email
      })
    }
  } else {
    // If it is not in uniqueCheckList, then add the email to it
    uniqueCheckList.push({ email, row: index + 2 })
  }
}

export default function filterMailingListCSV(csvData, ccEmail, csvFieldsOnData, validEmailFormat) {
  // Format of csvFieldsOnData is {Company: "", 'To and Cc': "", BCc: ""} - each key has the mapped header from the uploaded CSV

  const badFormatsArray = []
  const duplicatesArray = []

  const uniqueCheckList = [] // Used to check if an email is duplicated across rows of the CSV

  csvData.forEach((record, index) => {
    let filteredCcList = []
    let filteredBccList = []

    if (csvFieldsOnData['To and Cc'] !== 'None') {
      filteredCcList = record[csvFieldsOnData['To and Cc']].filter(
        selectedEmail => selectedEmail !== ccEmail && selectedEmail.length > 1
      )

      // check for badly formatted and duplicate emails
      filteredCcList.forEach(email => {
        checkBadFormats(email, validEmailFormat, badFormatsArray, index, record, 'To and Cc')
        checkDuplicates(uniqueCheckList, email, duplicatesArray, index, record)
      })
    }

    if (csvFieldsOnData.BCc !== 'None') {
      filteredBccList = record[csvFieldsOnData.BCc].filter(
        selectedEmail => selectedEmail !== ccEmail && selectedEmail.length > 1
      )

      filteredBccList.forEach(email => {
        checkBadFormats(email, validEmailFormat, badFormatsArray, index, record, 'BCc')
        checkDuplicates(uniqueCheckList, email, duplicatesArray, index, record)
      })
    }

    // reassign cleaned lists to csvData - this overwrites csvData
    _.assign(record, { Company: record[csvFieldsOnData.Company], 'To and Cc': filteredCcList, BCc: filteredBccList })
  })

  return { badFormats: badFormatsArray, duplicates: duplicatesArray }
}
