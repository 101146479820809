import * as Yup from 'yup'

export const validationSchema = Yup.object({
  institutionId: Yup.number(),
  contactPoint: Yup.boolean()
})

export const initialFormData = {
  institutionId: '',
  contactPoint: false
}
