import React, { Component } from 'react'
import { Formik } from 'formik'
import { Container, TextField, CircularProgress, Button, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import logo from '../../../images/frontier-logo.png'

import { loginStyles } from '../../../styles'

const styles = theme => ({ ...loginStyles(theme) })

class Login extends Component {
  state = {
    initialValues: {
      email: '',
      password: ''
    }
  }

  handleFormSubmit = ({ email, password }) => {
    const { signIn } = this.props

    signIn(email, password)
  }

  render() {
    const { initialValues } = this.state
    const { isPerformingAuthAction, openResetPasswordDialog, classes } = this.props

    return (
      <Container className={classes.loginPage}>
        <Grid
          container
          item
          className={classes.loginFormContainer}
          direction="column"
          md={4}
          sm={6}
          xs={10}
          justifyContent="center"
          alignItems="center"
        >
          <Formik initialValues={initialValues} onSubmit={values => this.handleFormSubmit(values)}>
            {formik => (
              <form onSubmit={formik.handleSubmit}>
                <Grid item container direction="column" justifyContent="center" alignItems="center">
                  <img src={logo} alt="Frontier Logo" className={classes.companyLogo} />

                  <TextField
                    required
                    autoFocus
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    label="Email"
                    margin="normal"
                    variant="outlined"
                  />

                  <TextField
                    required
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    label="Password"
                    margin="normal"
                    variant="outlined"
                  />

                  <small className={classes.forgotPassword} onClick={() => openResetPasswordDialog()}>
                    Forgot Password?
                  </small>

                  {isPerformingAuthAction ? (
                    <CircularProgress className={classes.submitButtonSection} />
                  ) : (
                    <Button
                      className={classes.submitButtonSection}
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={!(formik.values.email.length > 2 && formik.values.password.length > 2)}
                    >
                      Login
                    </Button>
                  )}
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Container>
    )
  }
}

export default withStyles(styles)(Login)
