import * as Yup from 'yup'

export const validationSchema = Yup.object({
  mailingPreference: Yup.string()
    .oneOf(['cc', 'bcc', 'googleGroups'], 'Mailing Preference should be one of Cc, BCc, or Google Groups')
    .required('Required'),
  emailType: Yup.string()
    .oneOf(['primary', 'secondary'], 'Email Type must be either Primary or Secondary email')
    .required('Required'),
  institutionId: Yup.number().required('Required'),
  packageId: Yup.number().required('Required'),
  productId: Yup.number().required('Required')
})

export const initialFormData = {
  mailingPreference: 'bcc',
  emailType: 'primary',
  packageId: '',
  institutionId: '',
  productId: ''
}
