import React from 'react'
import _ from 'lodash'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  Chip,
  Divider,
  Button
} from '@material-ui/core'

import { PermissibleRender } from '../utilities'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function CustomAccordion({
  data, // Data to be rendered in the accordion - is an Object with keys
  title, // Title to be rendered in AccordionSummary
  userRole,
  customChips, // Array of paths and label for custom data to be rendered as Chip components
  removedStatusPath, // Object path to deletedAt property for this accordions data. Used to determine if an item is removed or not
  classes, // CSS Classes passed from parent component
  handleEdit, // Function to handle edits to the data
  handleDelete, // Function to handle deleting the data
  children // Child components to be rendered under the Accordion Details.
}) {
  // isRemoved will be null if removedStatusPath is null, otherwise it will correspond to the deletedAt date
  const isRemoved = _.at(data, removedStatusPath)[0]
  // console.log(userRole)

  return (
    <Accordion key={data.id} style={{ backgroundColor: isRemoved ? '#ececec' : 'inherit' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="package-detail-content"
        id="package-detail-header"
      >
        <div className={classes.accordionSummaryColumn}>
          {/* Only show the name if the data has a name property */}
          {title && <Typography>{title}</Typography>}

          {isRemoved && <Chip label="Removed" color="secondary" variant="outlined" />}
        </div>
        {customChips &&
          customChips.map(
            chipProperty =>
              // _.at() gets us the value at the given path, where chipProperty is an object that takes the following shape:
              // { path: 'string.path.to.value', label: 'string to display on Chip component' }
              _.at(data, chipProperty.path)[0] && (
                <div className={classes.accordionSummaryColumn} key={`${data.id}-${chipProperty.path}`}>
                  <Chip label={chipProperty.label} variant="outlined" />
                </div>
              )
          )}
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetailContainer}>{children}</AccordionDetails>

      <Divider />

      <PermissibleRender userPermission={userRole} requiredPermissions={['dev', 'editor', 'remoteMember']}>
        <AccordionActions>
          <Button
            size="small"
            onClick={() => {
              handleEdit()
            }}
            disabled={!!isRemoved}
          >
            Edit
          </Button>
          <Button
            size="small"
            color="primary"
            disabled={!!data.deletedAt}
            onClick={() => {
              handleDelete()
            }}
          >
            {isRemoved ? 'Restore' : 'Delete'}
          </Button>
        </AccordionActions>
      </PermissibleRender>
    </Accordion>
  )
}
