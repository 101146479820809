import React, { useContext, useEffect } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'

import { withStyles, Grid, Button } from '@material-ui/core'

import { validationSchema, initialFormData } from './formSetup'

import { SelectField, CheckboxField } from '../../blocks'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import { extractErrorData, formatDataForAutocomplete } from '../../helpers'
import { formatUserDetailFormSubmit, mapCurrentValuesToForm } from '../../lib/userDetail'
import { FunctionContext } from '../../contexts'
import settings from '../../../settings'
import { formStyles } from '../../../styles'

const styles = theme => ({ ...formStyles(theme) })

const UserDetailsForm = ({ handleSubmit, currentInstitutions, currentValues, user, classes }) => {
  const [initialValues, setInitialValues] = React.useState(initialFormData)
  const [allInstitutions, setAllInstitutions] = React.useState([])

  const functions = useContext(FunctionContext)

  // Effect to load the list of institutions to populate Institution Select field
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    // useEffect can only return a function (not an async function),
    // So, we create an async function and call it immediately to avoid errors
    async function fetchInstitutionsList() {
      try {
        const data = await databaseQueryInterface.get(`${settings.baseURL}/api/institutions`, user)

        const institutionsList = formatDataForAutocomplete(data)

        if (isMounted) setAllInstitutions(institutionsList)
      } catch (err) {
        const data = extractErrorData(err)
        functions.openDialog({
          failedAction: `retrieve list of Institutions for adding Institutions`,
          data
        })
      }
    }

    fetchInstitutionsList()

    return () => {
      isMounted = false
    }
  }, [functions, user])

  // Effect to update the form with the new values during an edit action
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updateInstitutionData() {
      if (currentValues) {
        const currentData = mapCurrentValuesToForm(currentValues)

        if (isMounted) setInitialValues(currentData)
      }
    }

    updateInstitutionData()

    return () => {
      isMounted = false
    }
  }, [currentValues])

  const formatSubmitData = submitValues => {
    const selectedInstitution = _.find(allInstitutions, ['value', submitValues.institutionId])
    return formatUserDetailFormSubmit(selectedInstitution, submitValues)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
      validationSchema={validationSchema}
      onSubmit={values => {
        handleSubmit(formatSubmitData(values))
      }}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column" spacing={4} justifyContent="space-between" alignItems="stretch">
            <Grid item xs={12}>
              <SelectField
                fullWidth
                name="institutionId"
                label="Institution"
                items={allInstitutions}
                value={formik.values.institutionId}
                handleChange={selectedOption => {
                  formik.setFieldValue('institutionId', selectedOption || '')
                }}
                disabledOptionIds={currentInstitutions.map(institution => institution.id)}
                touched={formik.touched.institutionId}
                errors={formik.errors.institutionId}
              />
            </Grid>

            <Grid item xs={12}>
              <CheckboxField
                name="contactPoint"
                label="Contact Point"
                handleChange={formik.handleChange}
                checked={formik.values.contactPoint}
                touched={formik.touched.contactPoint}
                errors={formik.errors.contactPoint}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
                {currentValues ? 'Save Changes' : 'Add Institution'}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default withStyles(styles)(UserDetailsForm)
