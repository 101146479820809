import UnauthorizedImage from '../../images/assets/undraw_access_denied_6w73_low_opacity.svg'

export default function unauthorizedStyles(theme) {
  return {
    background: {
      marginTop: theme.spacing(4),
      backgroundImage: `url(${UnauthorizedImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        height: '80vh'
      }
    },
    text: {
      marginBottom: theme.spacing(2),
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        fontSize: '2rem'
      }
    },
    textHeading: {
      marginBottom: theme.spacing(2),
      textAlign: 'right',
      fontSize: '12rem',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        marginTop: '20vh'
      }
    },
    emoji: {
      marginLeft: theme.spacing(2)
    }
  }
}
