import _ from 'lodash'

export default function deleteData(dataToDelete, collection) {
  const copyOfCollection = _.cloneDeep(collection) // make a copy of the formik data
  const dataExists = _.find(copyOfCollection, ['id', dataToDelete.id])

  if (dataExists) {
    const dataIndex = _.findIndex(copyOfCollection, ['id', dataToDelete.id])

    copyOfCollection.splice(dataIndex, 1)

    return copyOfCollection
  }
}
