import React from 'react'

import Skeleton from '@material-ui/lab/Skeleton'

export default class ShowSkeleton extends React.Component {
  render() {
    const {
      condition, // if condition is true, show skeleton, otherwise show children
      children, // children to be displayed if condition is false
      variant, // Skeleton variant
      width, // Skeleton width
      height // Skeleton height
    } = this.props

    if (!children) {
      return null
    }

    if (!condition) {
      return children
    }

    return <Skeleton variant={variant} width={width} height={height} />
  }
}
