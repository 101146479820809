import React, { Component } from 'react'

import {
  Container,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Chip,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import InfoIcon from '@material-ui/icons/Info'
import ErrorIcon from '@material-ui/icons/Error'
import HelpIcon from '@material-ui/icons/Help'
import DateRangeIcon from '@material-ui/icons/DateRange';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { withStyles } from '@material-ui/core/styles'

import axios from 'axios'
import _ from 'lodash'

import settings from '../../../settings'
import { extractErrorData } from '../../helpers'
import { Unauthorized } from '..'
import { PermissibleRender } from '../../utilities'

import { logsStyles } from '../../../styles'

const styles = theme => ({ ...logsStyles(theme) })

class Logs extends Component {
  state = {
    logs: [], // all logs
    level: 'all',
    dateRange: [new Date(), new Date()],
    filteredLogs: []
  }

  componentDidMount() {
    this.getLogs()
  }

  getLogs = async () => {
    const { user, openDialog } = this.props

    try {
      const token = await user.getIdToken()

      const {
        data: { logs }
      } = await axios.get(`${settings.baseURL}/api/logs`, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json'
        }
      })

      // sets combined logs read from file to both state.logs and state.filteredLogs
      this.setState({ logs, filteredLogs: logs })
    } catch (err) {
      const data = extractErrorData(err)
      openDialog(
        `Couldn't retrieve logs`,
        `We're facing some issues retriving the logs, please try reloading this page. If the issue persists, contact the tech team with the following message: ${data}`
      )
    }
  }

  handleLevel = e => {
    const { filteredLogs } = this.state
    let filtered = []
    const level = e.target.value

    // if 'All' selected, set state.filteredLogs to initial combined logs array
    if (level === 'all') filtered = filteredLogs
    // If 'No level specified' selected, we set state.filteredLogs to items without a level attribute.
    else if (level === '') filtered = filteredLogs.filter(log => !log.level)
    // if a level is selected, filter just those logs from combined logs and set to state.filteredLogs
    else filtered = filteredLogs.filter(log => log.level === level)

    this.setState({ level, filteredLogs: filtered })
  }

  handleDateRange = range => {
    const { filteredLogs } = this.state
    let filtered = []

    filtered = filteredLogs.filter(log => {
      if (log.timestamp) {
        // get only the date part from log timestamp
        const logTimestamp = log.timestamp.split(' ')[0]
        
        // get only the date part from date range picker value
        const start = range[0].toISOString().split('T')[0]
        const end = range[1].toISOString().split('T')[0]

        return logTimestamp >= start && logTimestamp <= end
      }
    })

    this.setState({ dateRange: range, filteredLogs: filtered })
  }

  handleClear = () => {
    const { logs } = this.state

    // set to all logs
    this.setState({ filteredLogs: logs })
  }

  render() {
    const { classes, userRole } = this.props
    const { logs, filteredLogs, level, dateRange } = this.state

    const levelFontColor = levelName => {
      if (levelName === 'info') return '#3cb04d'
      if (levelName === 'error') return '#c94747'

      return '#4aa3b5' // We return a default colour for items that don't meet the above conditions
    }

    const levelIcon = levelName => {
      const chipStyles = { color: levelFontColor(levelName), padding: '5px' }

      if (levelName === 'info') return <InfoIcon style={{ ...chipStyles }} />
      if (levelName === 'error') return <ErrorIcon style={{ ...chipStyles }} />

      return <HelpIcon style={{ ...chipStyles }} /> // We return a default icon for items that don't meet the above conditions
    }

    return (
      <Container maxWidth="lg" className={classes.mainContainer}>
        <PermissibleRender
          userPermission={userRole}
          requiredPermissions={['editor', 'dev']}
          renderOtherwise={<Unauthorized />}
        >
          {
            logs.length < 1
              ? <CircularProgress size={20} thickness={5} />
              : (
                  <>
                    <Container className={classes.formControls}>
                      <FormControl className={classes.formControl}>
                        <DateRangePicker
                          className={classes.formControl}
                          onChange={this.handleDateRange}
                          format={"y-MM-dd"}
                          rangeDivider="to"
                          value={dateRange}
                          calendarIcon={<DateRangeIcon />}
                          clearIcon={null}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="level-select-label">Select level</InputLabel>
                        <Select value={level} onChange={this.handleLevel} labelId="level-select-label" id="level-select">
                          <MenuItem value="all" selected>
                            All
                          </MenuItem>
                          <MenuItem value="">No level specified</MenuItem>
                          <MenuItem value="info">Info</MenuItem>
                          <MenuItem value="error">Error</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl>
                        <Button
                          className={classes.button}
                          onClick={this.handleClear}
                          variant="contained"
                          color="primary"
                        >
                          Clear filters
                        </Button>
                      </FormControl>
                    </Container>

                    <Paper className={classes.logsContainer}>
                      {
                        filteredLogs.length < 1
                          ? <Alert severity="error">No logs filtered. Clear filters to start over.</Alert>
                          : 
                              filteredLogs.map((log, index) => (
                                <div key={index}>
                                  <Chip
                                    label={log.level ? `${_.upperCase(log.level)}` : 'No level specified'}
                                    icon={levelIcon(log.level)}
                                    variant="outlined"
                                    style={{
                                      color: levelFontColor(log.level),
                                      borderColor: levelFontColor(log.level),
                                      margin: '10px'
                                    }}
                                  />{' '}
                                  <Typography style={{ display: 'inline-block', padding: '10px' }}>
                                    {log.timestamp && <span>{`[${log.timestamp}] `}</span>}

                                    {/* some error messages don't have a 'message' key, for those we print the whole JSON */}
                                    {log.message ? <span>{log.message}</span> : JSON.stringify(log)}
                                    {log.code && (
                                      <span>
                                        {' '}
                                        <span style={{ color: '#dbb63d' }}>Code:</span> {log.code}
                                      </span>
                                    )}
                                  </Typography>
                                  <Divider />
                                </div>
                              ))
                          
                      }
                    </Paper>
                  </>
              )
          }
        </PermissibleRender>
      </Container>
    )
  }
}

export default withStyles(styles)(Logs)
