import { grey, green } from '@material-ui/core/colors'

export default function userStyles(theme) {
  return {
    mainContainer: {
      minHeight: '70vh', // appropiate height so the loading spinner is on the center
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3)
    },
    checkUserRolesContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    button: {
      margin: theme.spacing(1)
    },
    linkButton: {
      textDecoration: 'none',
      alignSelf: 'flex-end',
      marginBottom: theme.spacing(1),
      '&hover': {
        textDecoration: 'none'
      }
    },
    listContainer: {
      backgroundColor: theme.palette.background.paper
    },
    emailDetailsForm: {
      display: 'flex',
      flexDirection: 'column'
    },
    csvReviewList: {
      height: '500px'
    },
    infoAlert: {
      marginBottom: '20px',
      fontSize: '.9rem'
    },
    table: {
      minWidth: '90%'
    },
    actionButton: {
      opacity: '50%',
      '&:hover': {
        opacity: '100%'
      }
    },
    subSection: {
      marginTop: theme.spacing(2)
    },
    accessLevels: {
      display: 'flex',
      width: '100%'
    },
    positiveIcon: { color: green[500] },
    negativeIcon: { color: grey[500] },
    categoryChip: {
      marginRight: '3px',
      marginTop: '2px'
    }
  }
}
