import React, { Component } from 'react'
import _ from 'lodash'

import { withStyles, Container, Grid, Paper, Typography, Chip } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import StarIcon from '@material-ui/icons/Star'

import { CustomList, AdminButtons, CustomAccordion, ShowRemovedSwitch } from '../../blocks'
import { ConfirmationDialog, FormDialog } from '../../Dialogs'
import { SubscriptionForm } from '../../forms'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { extractErrorData, getDataFromId, generateColor, filterRemovedItems, algoliaDataUpdate } from '../../helpers'
import { formatSubscriptionData } from '../../lib/subscription'
import { commonStyles, formStyles } from '../../../styles'

const styles = theme => ({ ...commonStyles(theme), ...formStyles(theme) })

class Person extends Component {
  static contextType = FunctionContext

  state = {
    userDetails: null,
    openRemoveDialog: false,
    openDeleteDialog: false,
    openSubscriptionRemoveDialog: false,
    showRemovedSubscriptions: false,
    filteredSubscriptions: [],
    selectedSubscription: null,
    showSubscriptionForm: false
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    const { openDialog, openSnackbar } = this.context
    const {
      user,
      match: { params }
    } = this.props

    try {
      const data = await databaseQueryInterface.get(
        `${settings.baseURL}/api/users-new/${params.id}?showRemovedProducts=true`,
        user
      )

      this.setState(
        {
          userDetails: data,
          filteredSubscriptions: data.products.filter(product => product.Subscription.deletedAt === null)
        },
        () => {
          openSnackbar(`Retrieved data for ${data.name}`)
        }
      )
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve user records`,
        data
      })
    }
  }

  getInstitutionData = subscription => {
    const { userDetails } = this.state
    return getDataFromId(subscription.Subscription.institutionId, userDetails.institutions)
  }

  handleSubscriptionDialogOpen = subscription =>
    this.setState({ showSubscriptionForm: true, selectedSubscription: subscription })

  handleSubscriptionDialogClose = () => this.setState({ showSubscriptionForm: false })

  handleSubscriptionEdit = async newSubscriptionData => {
    const { user } = this.props
    const { openDialog, openSnackbar } = this.context
    const { userDetails } = this.state

    const formattedSubscription = await formatSubscriptionData(newSubscriptionData, user, openDialog)

    if (formattedSubscription.success) {
      await databaseQueryInterface.put(`${settings.baseURL}/api/users-new/${userDetails.id}/edit-subscription`, user, {
        subscription: formattedSubscription.subscription
      })

      const { products } = await databaseQueryInterface.get(
        `${settings.baseURL}/api/users-new/${userDetails.id}/subscriptions`,
        user
      )

      this.setState({ userDetails: { ...userDetails, products: [...products] } }, () => {
        this.handleSubscriptionDialogClose()
        this.filterSubscriptions()
        openSnackbar(`Subscription to ${formattedSubscription.subscription.name} updated.`)
      })
    }
  }

  handleRemovedSwitchChange = () => {
    this.setState(
      prevState => ({ showRemovedSubscriptions: !prevState.showRemovedSubscriptions }),
      () => {
        this.filterSubscriptions()
      }
    )
  }

  filterSubscriptions = () => {
    const { showRemovedSubscriptions, userDetails } = this.state

    const filteredSubscriptions = filterRemovedItems(
      userDetails.products,
      'Subscription.deletedAt',
      showRemovedSubscriptions
    )

    this.setState({ filteredSubscriptions })
  }

  handleSubscriptionRemoveDialogOpen = subscription =>
    this.setState({ selectedSubscription: subscription, openSubscriptionRemoveDialog: true })

  handleSubscriptionRemoveDialogClose = () =>
    this.setState({ selectedSubscription: null, openSubscriptionRemoveDialog: false })

  removeSubscription = async () => {
    const { user } = this.props
    const { userDetails, selectedSubscription } = this.state

    const { openDialog, openSnackbar } = this.context

    try {
      const data = await databaseQueryInterface.put(
        `${settings.baseURL}/api/users-new/${userDetails.id}/remove-subscription`,
        user,
        { productId: selectedSubscription.id }
      )

      const { products } = await databaseQueryInterface.get(
        `${settings.baseURL}/api/users-new/${userDetails.id}/subscriptions`,
        user
      )

      this.setState({ userDetails: { ...userDetails, products: [...products] } }, () => {
        this.handleSubscriptionRemoveDialogClose()
        this.filterSubscriptions()
        openSnackbar(data)
      })
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `retrieve user records`,
        data
      })
    }
  }

  handleRemoveDialogOpen = () => this.setState({ openRemoveDialog: true })

  handleRemoveDialogClose = () => this.setState({ openRemoveDialog: false })

  handleDeleteDialogOpen = () => this.setState({ openDeleteDialog: true })

  handleDeleteDialogClose = () => this.setState({ openDeleteDialog: false })

  handleRemove = async () => {
    const { user, history } = this.props
    const { userDetails } = this.state

    const { openDialog, openSnackbar } = this.context

    try {
      const data = await databaseQueryInterface.put(
        `${settings.baseURL}/api/users-new/${userDetails.id}/toggle-remove`,
        user
      )

      openSnackbar(`${data.deletedAt ? 'Removed' : 'Restored'} User: ${data.name}`)
      this.handleRemoveDialogClose()
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `remove user`,
        data
      })
    }
  }

  handleDelete = async () => {
    const { user, history } = this.props
    const { userDetails } = this.state

    const { openDialog, openSnackbar } = this.context

    try {
      const data = await databaseQueryInterface.destroy(`${settings.baseURL}/api/users-new/${userDetails.id}`, user)

      algoliaDataUpdate({
        objectID: data.id,
        index: 'people',
        operation: 'delete'
      })

      openSnackbar(`Deleted User: ${data.name}`)
      history.goBack()
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `delete user`,
        data
      })
    }
  }

  handleDownload = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { userDetails } = this.state

    try {
      const downloadRoute = `${settings.baseURL}/api/users-new/${userDetails.id}/download`

      const csv = await databaseQueryInterface.get(downloadRoute, user)

      // create an <a> tag and trigger a click to download file
      const link = document.createElement('a')

      link.href = window.URL.createObjectURL(new Blob([csv]))
      link.setAttribute('download', `${userDetails.name}.csv`)

      document.body.appendChild(link)
      link.click()
      link.remove()

      openSnackbar(`Downloaded user data.`)
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `download user details`,
        data
      })
    }
  }

  render() {
    const { classes, history, userRole, user } = this.props
    const {
      userDetails,
      openRemoveDialog,
      openDeleteDialog,
      openSubscriptionRemoveDialog,
      showSubscriptionForm,
      selectedSubscription,
      showRemovedSubscriptions,
      filteredSubscriptions
    } = this.state

    return (
      <Container>
        <Grid container spacing={5} justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={12} md={8}>
            <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={3}>
              <Grid item>
                <Paper className={classes.paperStyles}>
                  <Typography variant="h2">Subscriptions</Typography>

                  <ShowRemovedSwitch
                    checked={showRemovedSubscriptions}
                    handleChange={this.handleRemovedSwitchChange}
                    name="showRemoved"
                  />

                  {userDetails &&
                    filteredSubscriptions.map(subscription => (
                      <CustomAccordion
                        key={subscription.id}
                        userRole={userRole}
                        title={subscription.name}
                        classes={classes}
                        data={subscription}
                        removedStatusPath="Subscription.deletedAt"
                        customChips={[
                          {
                            label: _.capitalize(subscription.Subscription.mailingPreference),
                            path: 'Subscription.mailingPreference'
                          },
                          {
                            label: `${_.capitalize(subscription.Subscription.emailType)} Email`,
                            path: 'Subscription.emailType'
                          }
                        ]}
                        handleEdit={() => {
                          this.handleSubscriptionDialogOpen(subscription)
                        }}
                        handleDelete={() => {
                          this.handleSubscriptionRemoveDialogOpen(subscription)
                        }}
                      >
                        <div className={classes.accordionDetailColumn}>
                          <Typography>
                            <strong>Institution:</strong> {this.getInstitutionData(subscription).name}{' '}
                            {this.getInstitutionData(subscription).deletedAt && '(Removed)'}
                          </Typography>
                        </div>
                      </CustomAccordion>
                    ))}
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={3}>
              <Grid item>
                <Paper className={classes.paperStyles}>
                  <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                      <Typography variant="h2">Basic Info</Typography>
                    </Grid>

                    {userDetails && userDetails.deletedAt && (
                      <Grid item>
                        <Chip label="Removed" color="secondary" variant="outlined" />
                      </Grid>
                    )}

                    {userDetails && (
                      <Grid item>
                        <Chip
                          label={userDetails.status.name}
                          style={{ backgroundColor: generateColor(userDetails.status.name) }}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <AdminButtons
                    userRole={userRole}
                    item={userDetails}
                    handleDownloadClick={this.handleDownload}
                    handleEditClick={() => history.push(`/person/${userDetails && userDetails.id}/edit`)}
                    handleDuplicateClick={() => history.push(`/person/${userDetails && userDetails.id}/duplicate`)}
                    handleRemoveClick={this.handleRemoveDialogOpen}
                    handleDeleteClick={this.handleDeleteDialogOpen}
                    classes={classes}
                  />

                  {userDetails && (
                    <>
                      <Typography>
                        <strong>Name:</strong> {userDetails.name}
                        {' '}
                        {userDetails.starred && <StarIcon color="primary" fontSize="small" />}
                      </Typography>

                      <Typography><strong>Primary Email:</strong> {userDetails.primaryEmail}</Typography>

                      {userDetails.secondaryEmail && <Typography><strong>Secondary Email:</strong> {userDetails.secondaryEmail}</Typography>}

                      {userDetails.designation && <Typography><strong>Designation:</strong> {userDetails.designation}</Typography>}

                      <Typography><strong>Phone Number:</strong> {userDetails.phoneNumber}</Typography>
                    </>
                  )}
                </Paper>
              </Grid>

              <Grid item>
                <Paper className={classes.paperStyles}>
                  <Typography variant="h2">Institutions</Typography>

                  {userDetails && (
                    <CustomList
                      itemsToList={userDetails.institutions}
                      urlModel="/institutions/view/"
                      showRemoved
                      customChips={[{ path: 'UserDetails.contactPoint', label: 'Contact Point' }]}
                    />
                  )}
                </Paper>
              </Grid>

              {
                userDetails && userDetails.notes &&
                <Grid item>
                  <Paper className={classes.paperStyles}>
                    <Typography variant="h2">Notes</Typography>
                    <Typography variant='p'>{userDetails.notes}</Typography>
                  </Paper>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>

        <ConfirmationDialog
          open={openRemoveDialog}
          closeDialog={this.handleRemoveDialogClose}
          text={`Are you sure you want to remove "${userDetails && userDetails.name}"?`}
          handleConfirm={this.handleRemove}
        />

        <ConfirmationDialog
          open={openDeleteDialog}
          closeDialog={this.handleDeleteDialogClose}
          text={`Are you sure you want to delete "${userDetails && userDetails.name}"?`}
          handleConfirm={this.handleDelete}
          extraSecurity
        />

        <ConfirmationDialog
          open={openSubscriptionRemoveDialog}
          closeDialog={this.handleSubscriptionRemoveDialogClose}
          text={`Are you sure you want to remove "${selectedSubscription &&
            selectedSubscription.name}" from this user?`}
          handleConfirm={this.removeSubscription}
        />

        {/* Form to add a new subscription */}
        <FormDialog
          open={showSubscriptionForm}
          handleClose={this.handleSubscriptionDialogClose}
          classes={classes}
          title="Edit Subscription"
        >
          <SubscriptionForm
            handleSubmit={formData => {
              this.handleSubscriptionEdit(formData)
            }}
            hasSecondaryEmail={userDetails && !!userDetails.secondaryEmail}
            currentValues={selectedSubscription}
            classes={classes}
            loggedInUser={user}
          />
        </FormDialog>
      </Container>
    )
  }
}

export default withStyles(styles)(Person)
