import React, { useEffect, useState, useContext } from 'react'

import { Container, Grid, makeStyles } from '@material-ui/core'

import { SelectField, AdminButtons } from '../../blocks'
import { FormDialog, ConfirmationDialog } from '../../Dialogs'
import { ProductSubcategoryForm } from '../../forms'

import { FunctionContext } from '../../contexts'

import { extractErrorData, formatDataForAutocomplete, getDataFromId, addOrEditData, deleteData } from '../../helpers'
import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { commonStyles, formStyles } from '../../../styles'

const useStyles = makeStyles(theme => ({ ...commonStyles(theme), ...formStyles(theme) }))

const SubcategorySelect = ({
  user, // user Object for auth
  userRole, // user role for role-based restrictions
  selectedCategory, // the category that the user selected to filter subcategories
  selectedSubcategory, // The subcategory that is selected
  handleSubcategorySelect, // Function to select the subcategory
  showRemoved // Boolean to decide when to show removed items
}) => {
  const classes = useStyles()
  const { openDialog, openSnackbar } = useContext(FunctionContext)

  const [subcategoryData, setSubcategoryData] = useState(null) // Full subcategory data
  const [subcategorySelectData, setSubcategorySelectData] = useState(null) // Data for the select component
  const [openForm, setOpenForm] = useState(false) // Boolean to open the add/edit form
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false) // Boolean to open dialog to confirm removing subcategory
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false) // Boolean to open dialog to confirm deleting subcategory

  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    // useEffect can only return a function (not an async function),
    // So, we create an async function and call it immediately to avoid errors
    async function fetchSubcategoriesList() {
      try {
        if (!selectedCategory) return setSubcategoryData([])

        const parentId = selectedCategory ? `?parentId=${selectedCategory.id}` : ''
        const getRemoved = showRemoved ? '&showRemoved=true' : ''

        const data = await databaseQueryInterface.get(
          `${settings.baseURL}/api/product-subcategories/${parentId}${getRemoved}`,
          user
        )

        if (isMounted) setSubcategoryData(data)
      } catch (err) {
        const data = extractErrorData(err)
        openDialog({
          failedAction: `retrieve list of Product Sub-Category`,
          data
        })
      }
    }

    fetchSubcategoriesList()

    return () => {
      isMounted = false
    }
  }, [openDialog, selectedCategory, showRemoved, user])

  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updateSelectList() {
      if (isMounted && subcategoryData) setSubcategorySelectData(formatDataForAutocomplete(subcategoryData))
    }

    updateSelectList()

    return () => {
      isMounted = false
    }
  }, [subcategoryData])

  const handleSubcategoryFormSubmit = async formData => {
    try {
      const data = await databaseQueryInterface.post(`${settings.baseURL}/api/product-subcategories`, user, {
        ...formData
      })

      // Close form and add new data to current arrays
      setOpenForm(false)
      setSubcategoryData(addOrEditData(data, subcategoryData))

      openSnackbar(`Created Product Sub-Category: ${data.name}`)
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({
        failedAction: `create Sub-Category`,
        data
      })
    }
  }

  const handleSubcategoryFormEditSubmit = async (formData, subcategoryId) => {
    try {
      const data = await databaseQueryInterface.put(
        `${settings.baseURL}/api/product-subcategories/${subcategoryId}`,
        user,
        {
          ...formData
        }
      )

      // Close form and add new data to current arrays
      setOpenForm(false)
      setSubcategoryData(addOrEditData(data, subcategoryData))

      openSnackbar(`Edited Product Sub-Category: ${data.name}`)
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `edit Sub-Category`, data })
    }
  }

  const handleRemove = async subcategoryId => {
    try {
      const { productSubcategory, productsToRemove } = await databaseQueryInterface.put(
        `${settings.baseURL}/api/product-subcategories/${subcategoryId}/remove`,
        user
      )

      for (const product of productsToRemove) {
        await databaseQueryInterface.put(`${settings.baseURL}/api/products/${product.id}/remove`, user)
      }

      // Close form and add new data to current arrays
      setOpenRemoveDialog(false)
      setSubcategoryData(addOrEditData(productSubcategory, subcategoryData))
      handleSubcategorySelect(null)

      openSnackbar(
        `${productSubcategory.deletedAt === null ? 'Restored' : 'Removed'} Product Sub-Category: ${
          productSubcategory.name
        }`
      )
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `${data.deletedAt === null ? 'remove' : 'restore'} Sub-Category`, data })
    }
  }

  const handleDelete = async subcategoryId => {
    try {
      const data = await databaseQueryInterface.destroy(
        `${settings.baseURL}/api/product-subcategories/${subcategoryId}`,
        user
      )

      // Close form and add new data to current arrays
      setOpenDeleteDialog(false)
      setSubcategoryData(deleteData(data, subcategoryData))
      handleSubcategorySelect(null)

      openSnackbar(`Deleted Product Sub-Category: ${data.name}`)
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `delete Sub-Category`, data })
    }
  }

  return (
    <Container>
      <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
        <Grid item xs={6}>
          {subcategorySelectData && (
            <SelectField
              name="subcategorySelect"
              label="Sub-Category"
              value={(selectedSubcategory && selectedSubcategory.id) || ''}
              handleChange={selectedOption => handleSubcategorySelect(getDataFromId(selectedOption, subcategoryData))}
              items={subcategorySelectData}
              disabled={!selectedCategory}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <AdminButtons
            userRole={userRole}
            classes={classes}
            item={selectedSubcategory}
            handleAddClick={() => {
              setOpenForm(true)
              handleSubcategorySelect(null)
            }}
          />

          {selectedSubcategory && (
            <AdminButtons
              userRole={userRole}
              classes={classes}
              item={selectedSubcategory}
              handleEditClick={() => setOpenForm(true)}
              handleRemoveClick={() => setOpenRemoveDialog(true)}
              handleDeleteClick={() => setOpenDeleteDialog(true)}
            />
          )}
        </Grid>
      </Grid>

      <FormDialog
        open={openForm}
        handleClose={() => setOpenForm(false)}
        classes={classes}
        title="Add a new Product Sub-Category"
      >
        <ProductSubcategoryForm
          handleCancel={() => setOpenForm(false)}
          handleSubmit={handleSubcategoryFormSubmit}
          handleEditSubmit={handleSubcategoryFormEditSubmit}
          currentValues={selectedSubcategory}
          user={user}
        />
      </FormDialog>

      {selectedSubcategory && (
        <>
          <ConfirmationDialog
            open={openRemoveDialog}
            closeDialog={() => setOpenRemoveDialog(false)}
            text={`Are you sure you want to ${selectedSubcategory.deletedAt === null ? 'remove' : 'restore'} "${
              selectedSubcategory.name
            }"?`}
            handleConfirm={() => handleRemove(selectedSubcategory.id)}
          />

          <ConfirmationDialog
            open={openDeleteDialog}
            closeDialog={() => setOpenDeleteDialog(false)}
            text={`Are you sure you want to delete "${selectedSubcategory.name}"?`}
            handleConfirm={() => handleDelete(selectedSubcategory.id)}
            extraSecurity
          />
        </>
      )}
    </Container>
  )
}

export default SubcategorySelect
