import _ from 'lodash'

export function mapCurrentValuesToForm(currentValues) {
  return {
    package: currentValues.id,
    numUsersAllowed: currentValues.PackageDetails.numUsersAllowed || '',
    discount: currentValues.PackageDetails.discount || '',
    startDate: currentValues.PackageDetails.startDate || new Date(),
    trial: currentValues.PackageDetails.trial || false,
    trialEndDate: currentValues.PackageDetails.trialEndDate || new Date(),
    notes: currentValues.PackageDetails.notes || ''
  }
}

export function getPackageData(packageId, allPackages) {
  const packageData = _.find(allPackages, ['value', packageId])

  if (packageData) return packageData
}

export function formatPackageDetailFormSubmit(packageData, formValues) {
  const { value, name, deletedAt } = packageData
  const { numUsersAllowed, startDate, trial, trialEndDate, notes, discount } = formValues

  return {
    id: value,
    name,
    deletedAt,
    PackageDetails: {
      numUsersAllowed,
      startDate,
      trial,
      trialEndDate,
      notes,
      discount
    }
  }
}
