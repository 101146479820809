import * as Yup from 'yup'

export const validationSchema = Yup.object({
  name: Yup.string()
    .max(255, 'Must be 255 characters or less')
    .required('Required'),
  description: Yup.string().min(10, 'Must be 10 characters or more'),
  price: Yup.number(),
  products: Yup.array()
    .of(Yup.object())
    .min(1)
    .required('Required'),
  notes: Yup.string().max(255, 'Must be 255 characters or less')
})

export const initialFormData = {
  name: '', // Name of the Package
  description: '', // Description of the Package
  price: 0, // Price of the Package
  products: [], // Array of products inclued in this Package
  notes: '' // Additional Notes
}
