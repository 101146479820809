import axios from 'axios'

import setHeaders from './setQueryHeaders'

async function get(url, user, dataObject) {
  const headers = await setHeaders(user)

  const { data } = await axios.get(url, {
    headers,
    ...dataObject
  })

  return data
}

async function post(url, user, dataObject) {
  const headers = await setHeaders(user)

  const { data } = await axios.post(url, {
    headers,
    ...dataObject
  })

  return data
}

async function put(url, user, dataObject) {
  const headers = await setHeaders(user)

  const { data } = await axios.put(url, {
    headers,
    ...dataObject
  })

  return data
}

async function destroy(url, user) {
  const headers = await setHeaders(user)

  const { data } = await axios.delete(url, {
    headers
  })

  return data
}

const queryInterface = {
  get,
  post,
  put,
  destroy
}

export default queryInterface
