import React, { useEffect } from 'react'
import { Formik } from 'formik'

import { withStyles, Button, Grid } from '@material-ui/core'

import { validationSchema, initialFormData } from './formSetup'

import { formStyles } from '../../../styles'
import { SingleLineTextField } from '../../blocks'
import { mapCurrentValuesToForm } from '../../lib/productCategory'

const styles = theme => ({ ...formStyles(theme) })

const ProductCategoryForm = ({ classes, handleSubmit, handleEditSubmit, currentValues, handleCancel }) => {
  const [initialValues, setInitialValues] = React.useState(initialFormData)

  // Effect to update the form with the new values during an edit action
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updateCategoryData() {
      if (currentValues) {
        const currentData = mapCurrentValuesToForm(currentValues)

        if (isMounted) setInitialValues(currentData)
      }
    }

    updateCategoryData()

    return () => {
      isMounted = false
    }
  }, [currentValues])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
      validationSchema={validationSchema}
      onSubmit={values => {
        if (currentValues) {
          handleEditSubmit(values, currentValues.id)
        } else {
          handleSubmit(values)
        }
      }}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <SingleLineTextField
                fullWidth
                name="name"
                label="Name"
                value={formik.values.name}
                handleChange={formik.handleChange}
                touched={formik.touched.name}
                errors={formik.errors.name}
              />
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
              Submit
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                handleCancel('categoryForm')
              }}
              className={classes.actionButton}
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default withStyles(styles)(ProductCategoryForm)
