import _ from 'lodash'

export default function getDataFromId(id, collection) {
  // if block to catch the error when a connection is removed
  // eg: when an institution is removed from a user, the code can't find the institution name to display in subscription accordions
  if (_.find(collection, collectionItem => collectionItem.id === id))
    return _.find(collection, collectionItem => collectionItem.id === id)
  else return {
    name: 'Connection removed'
  }
}
