import React, { Component } from 'react'
import _ from 'lodash'

import { withStyles, Container, Grid, Paper, Button, Slide } from '@material-ui/core'

import DetailsPane from './DetailsPane'

import CategorySelect from './CategorySelect'
import SubcategorySelect from './SubcategorySelect'

import { ConfirmationDialog } from '../../Dialogs'
import { ShowRemovedSwitch, CustomList, AdminButtons, AlphabeticalPaginationBar } from '../../blocks'
import {
  extractErrorData,
  getPaginationButtons,
  getDataForPage,
  createQueryString,
  algoliaDataUpdate
} from '../../helpers'
import { FunctionContext } from '../../contexts'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { commonStyles } from '../../../styles'

const styles = theme => ({ ...commonStyles(theme) })

class Products extends Component {
  static contextType = FunctionContext

  state = {
    allProducts: [], // Array of all product data
    productsToDisplay: [], // Array of product data to display - filtered by pagination and subcategory
    selectedCategory: null, // the category that is selected
    selectedSubcategory: null, // the subcategory that is selected
    selectedProduct: null, // The product that is selected
    paginationButtons: [], // Array of pagination buttons
    openRemoveDialog: false, // Boolean to open the remove product dialog
    openDeleteDialog: false, // Boolean to open the delete product dialog
    showRemoved: false // Boolean to show soft-deleted items
  }

  componentDidMount() {
    this.getProductData()
  }

  getProductData = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { showRemoved, selectedSubcategory } = this.state

    try {
      const queries = {
        subcategoryId: selectedSubcategory ? selectedSubcategory.id : null,
        showRemoved: showRemoved ? 'true' : null
      }

      const queryString = createQueryString(queries)

      const url = `${settings.baseURL}/api/products/${queryString}`
      const data = await databaseQueryInterface.get(url, user)

      const paginationButtons = getPaginationButtons(data)

      const allProducts = _.sortBy(data, ['name'])

      this.setState({ allProducts, productsToDisplay: allProducts, paginationButtons }, () =>
        openSnackbar(`Retrieved ${data.length} Products.`)
      )
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({ failedAction: `retrieve product records`, data })
    }
  }

  // Functions to select items
  handleCategorySelect = category => this.setState({ selectedCategory: category })

  handleSubcategorySelect = subcategory =>
    this.setState({ selectedSubcategory: subcategory }, () => this.getProductData())

  handleProductSelect = product => {
    this.setState({ selectedProduct: product })
  
    // scroll to top (200px from top)
    document.body.scrollTop = 200; // For Safari
    document.documentElement.scrollTop = 200; // For Chrome, Firefox, IE and Opera
  }

  handlePaginationSelect = (event, buttonValue) => {
    const { allProducts } = this.state

    const productsToDisplay = getDataForPage(buttonValue, allProducts)

    this.setState({ productsToDisplay })
  }

  // To close the details pane
  handleClose = () => this.setState({ selectedProduct: null })

  // Functions to open and close remove/delete dialogs
  handleRemoveDialogOpen = () => this.setState({ openRemoveDialog: true })

  handleRemoveDialogClose = () => this.setState({ openRemoveDialog: false })

  handleDeleteDialogOpen = () => this.setState({ openDeleteDialog: true })

  handleDeleteDialogClose = () => this.setState({ openDeleteDialog: false })

  // Functions for product CRUD operations
  handleProductAdd = () => {
    const { history } = this.props
    history.push('/products/create')
  }

  handleProductEdit = () => {
    const { history } = this.props
    const { selectedProduct } = this.state

    history.push(`/products/edit/${selectedProduct.id}`)
  }

  handleUsersAdd = () => {
    const { history } = this.props
    const { selectedProduct } = this.state

    history.push(`/products/add-users/${selectedProduct.id}`)
  }

  handleProductRemove = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { selectedProduct } = this.state

    try {
      const data = await databaseQueryInterface.put(
        `${settings.baseURL}/api/products/${selectedProduct.id}/remove`,
        user
      )      

      this.setState({ openRemoveDialog: false, selectedProduct: null }, () => this.getProductData())

      openSnackbar(`${data.deletedAt === null ? 'Restored' : 'Removed'} Product: ${data.name}`)
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `${data.deletedAt === null ? 'remove' : 'restore'} product`, data })
    }
  }

  handleProductDelete = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { selectedProduct } = this.state

    try {
      const data = await databaseQueryInterface.destroy(`${settings.baseURL}/api/products/${selectedProduct.id}`, user)

      this.setState({ openDeleteDialog: false, selectedProduct: null }, () => this.getProductData())

      algoliaDataUpdate({
        objectID: data.id,
        index: 'products',
        operation: 'delete'
      })

      openSnackbar(`Deleted Product: ${data.name}`)
    } catch (err) {
      const data = extractErrorData(err)
      openDialog({ failedAction: `delete product`, data })
    }
  }

  // Function to handle the showRemoved switch
  handleShowRemovedSwitchChange = () =>
    this.setState(prevState => ({ showRemoved: !prevState.showRemoved }), () => this.getProductData())

  handleDownloadAll = async () => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props

    try {
      const downloadRoute = `${settings.baseURL}/api/products/export/all`

      const zip = await databaseQueryInterface.get(downloadRoute, user, { responseType: 'arraybuffer' })

      // create an <a> tag and trigger a click to download file
      const link = document.createElement('a')

      link.href = window.URL.createObjectURL(new Blob([zip]))
      link.setAttribute('download', `all-mailing-lists.zip`)
      
      document.body.appendChild(link)
      link.click()
      link.remove()

      openSnackbar(`Downloaded all mailing lists.`)
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `download all mailing lists`,
        data
      })
    }
  }

  handleDownload = async (listType) => {
    const { openDialog, openSnackbar } = this.context
    const { user } = this.props
    const { selectedProduct } = this.state

    try {
      const downloadRoute = `${settings.baseURL}/api/products/${selectedProduct.id}/download/${listType}`

      const csv = await databaseQueryInterface.get(downloadRoute, user)

      // create an <a> tag and trigger a click to download file
      const link = document.createElement('a')
      const listName = listType === 'mailing' ? `${selectedProduct.name} mailing list` : `${selectedProduct.name} subscriber list`

      link.href = window.URL.createObjectURL(new Blob([csv]))
      link.setAttribute('download', `${listName}.csv`)
      
      document.body.appendChild(link)
      link.click()
      link.remove()
      
      const snackBarMessage = listType === 'mailing' ? 'Downloaded mailing list for product.' : 'Downloaded subscriber list for product.'

      openSnackbar(snackBarMessage)
    } catch (err) {
      console.log(err)
      const data = extractErrorData(err)
      openDialog({
        failedAction: `download product details`,
        data
      })
    }
  }

  render() {
    const { classes, userRole, user, history } = this.props
    const {
      productsToDisplay,
      selectedCategory,
      selectedSubcategory,
      selectedProduct,
      paginationButtons,
      openRemoveDialog,
      openDeleteDialog,
      showRemoved
    } = this.state

    return (
      <Container>
        <Grid container direction="column" justifyContent="space-around" alignItems="center" spacing={5}>
          <Grid container spacing={3} justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12} className={classes.spaceBetweenAlignedButtonContainer}>
              <ShowRemovedSwitch
                checked={showRemoved}
                handleChange={this.handleShowRemovedSwitchChange}
                name="showRemoved"
              />

              <Button 
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={this.handleDownloadAll}
              >
                Download All Mailing Lists
              </Button>
            </Grid>

            <Grid item xs={12}>
              <CategorySelect
                user={user}
                userRole={userRole}
                selectedCategory={selectedCategory}
                handleCategorySelect={this.handleCategorySelect}
                handleSubcategorySelect={this.handleSubcategorySelect}
                showRemoved={showRemoved}
              />
            </Grid>

            <Grid item xs={12}>
              <SubcategorySelect
                user={user}
                userRole={userRole}
                selectedCategory={selectedCategory}
                selectedSubcategory={selectedSubcategory}
                handleSubcategorySelect={this.handleSubcategorySelect}
                showRemoved={showRemoved}
              />
            </Grid>

            {/* Tabbed interface for each type of data, defaults to products */}
            <Grid container direction="column" justifyContent="space-around" alignItems="center" spacing={5}>
              <Grid item xs={12}>
                <AlphabeticalPaginationBar
                  paginationButtons={paginationButtons}
                  handlePaginationSelect={this.handlePaginationSelect}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>              
              <Paper className={classes.paperStyles}>
                <AdminButtons userRole={userRole} classes={classes} handleAddClick={this.handleProductAdd} />

                <CustomList
                  itemsToList={productsToDisplay}
                  handleItemSelect={(event, product) => this.handleProductSelect(product)}
                  showRemoved={showRemoved}
                />
              </Paper>
            </Grid>

            <Slide direction="left" timeout={300} in={!!selectedProduct} mountOnEnter unmountOnExit>
              <Grid item xs={12} md={6}>
                <DetailsPane
                  classes={classes}
                  handleClose={this.handleClose}
                  selectedItem={selectedProduct}
                  userRole={userRole}
                  history={history}
                  handleEdit={this.handleProductEdit}
                  handleAddUsers={this.handleUsersAdd}
                  handleRemove={this.handleRemoveDialogOpen}
                  handleDelete={this.handleDeleteDialogOpen}
                  handleDownload={() => this.handleDownload('mailing')}
                  handleDownloadSimpleList={() => this.handleDownload('simple')}
                />
              </Grid>
            </Slide>
          </Grid>
        </Grid>

        {selectedProduct && (
          <>
            <ConfirmationDialog
              open={openRemoveDialog}
              closeDialog={this.handleRemoveDialogClose}
              text={`Are you sure you want to ${selectedProduct.deletedAt === null ? 'remove' : 'restore'} "${
                selectedProduct.name
              }"?`}
              handleConfirm={this.handleProductRemove}
            />

            <ConfirmationDialog
              open={openDeleteDialog}
              closeDialog={this.handleDeleteDialogClose}
              text={`Are you sure you want to delete "${selectedProduct.name}"?`}
              handleConfirm={this.handleProductDelete}
              extraSecurity
            />
          </>
        )}
      </Container>
    )
  }
}

export default withStyles(styles)(Products)
