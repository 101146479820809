import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Container, Grid, Button, CircularProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined'

import axios from 'axios'
import CSVReviewList from './CSVReviewList'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'

import { parseUserImportListCSV } from '../../helpers'
import { commonStyles, userStyles } from '../../../styles'
import { AlertTitle } from '@material-ui/lab'

const styles = theme => ({ ...commonStyles(theme), ...userStyles(theme) })

class ImportUsers extends Component {
  static contextType = FunctionContext

  state = {
    users: [],
    csvInputResetKey: `csvResetKey${Date.now()}`,
    importing: false
  }

  handleCSVFileSelect = async event => {
    const {
      target: { files }
    } = event
    const { openDialog, openSnackbar } = this.context

    if (!files[0]) return

    const showErrorDialog = errors => {
      openDialog({
        title: 'Error found in csv file',
        content: `We ran into an unexpected '${
          errors[0].type
        } error' in this csv file. Please check this file for format errors`
      })
    }

    // We use a callback, since otherwise state is set before the parsing is finished
    const setUserState = userData => {
      this.setState({ users: userData }, () => {
        openSnackbar('CSV data was filtered automatically')
      })
    }

    // Parse CSV and pass callback to set state
    parseUserImportListCSV(files, showErrorDialog, setUserState)
  }

  handleImportAction = event => {
    event.preventDefault()

    const { openDialog } = this.context
    const { user } = this.props

    this.setState({ importing: true }, async () => {
      try {
        const token = await user.getIdToken()

        await this.importUsers(token)
      } catch (err) {
        openDialog({
          title: 'Authentication Error',
          data: err.message
        })
      }

      this.setState({ importing: false })
    })
  }

  importUsers = async authToken => {
    const { users } = this.state

    const { openDialog, openSnackbar } = this.context

    try {
      await axios.post(
        `${settings.baseURL}/api/users/import`,
        { users },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': authToken
          }
        }
      )

      openSnackbar(`All ${users.length} users imported successfully`)

      this.setState({
        users: [],
        csvInputResetKey: `csvResetKey${Date.now()}`
      })
    } catch (err) {
      const { data } = err.response
      openDialog({
        title: `Import failed!`,
        data
      })
    }
  }

  render() {
    const { classes } = this.props
    const { users, csvInputResetKey, importing } = this.state

    const csvDetailsAlert = (
      <Alert severity="info" className={classes.infoAlert}>
        <AlertTitle>To add users in bulk, simply upload a CSV with the following columns:</AlertTitle>          
        <ul>
          <li>
            <strong>Name</strong> - The name of the user
          </li>
          <li>
            <strong>Email</strong> - The email address of the user, this should be whatever email they use to
            subscribe to Frontier products
          </li>
          <li>
            <strong>Password</strong> - A temporary password for the user. They should be asked to change this
            password later.
          </li>
          <li>
            <strong>Role</strong> - The access level for the user. Such as, "client", "clientAdmin" etc.
          </li>
          <li>
            <strong>Categories</strong> - Level of influence a user has within their organization or in general. A
            user can belong in more than one category and they must be separated by a semicolon (;). Such as "Most
            Important Decider;Very Senior within Organization"
          </li>
        </ul>
        <strong>
          Make sure to type out the column headers <em>exactly</em> as they appear above.
        </strong>
      </Alert>
    )

    const customClaimsInfoAlert = (
      <Alert severity="info" className={classes.infoAlert}>
        <AlertTitle>The list of available options for roles and categories:</AlertTitle>
        <Grid container spacing={5} justifyContent="space-between" alignItems="flex-start" style={{ marginTop: '5px' }}>
          <Grid item>
            <strong>Roles</strong> -
            <ol>
              <li>client</li>
              <li>clientAdmin</li>
              <li>remoteMember</li>
              <li>remoteMailing</li>
              <li>remoteNews</li>
              <li>member</li>
              <li>editor</li>
              <li>dev</li>
            </ol>
          </Grid>

          <Grid item>
            <strong>Categories</strong> -
            <ol>
              <li>Most Important Decider</li>
              <li>Other Key Deciders</li>
              <li>Very Senior within Organization</li>
              <li>Senior within Organization</li>
              <li>Very Senior in Lanka</li>
              <li>Senior in Lanka</li>
              <li>Junior within Organization</li>
              <li>Unknown within Organization</li>
            </ol>
          </Grid>
        </Grid>
        <strong>
          Make sure to type them out <em>exactly</em> as they appear above.
        </strong>
      </Alert>
    )

    return (
      <Container className={classes.mainContainer} xs={12} maxWidth="lg">
        <Grid container spacing={3} justifyContent="space-evenly" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            {csvDetailsAlert}
          </Grid>

          <Grid item xs={12} md={6}>
            {customClaimsInfoAlert}
          </Grid>
        </Grid>

        <Grid container spacing={3} justifyContent="space-between">
          <Grid container item xs={12} justifyContent="center">
            <form className={classes.emailDetailsForm} autoComplete="off">
              <input
                accept=".csv"
                className={classes.hiddenInput}
                id="csv-upload-button"
                type="file"
                onChange={this.handleCSVFileSelect}
                key={csvInputResetKey}
              />
              <label htmlFor="csv-upload-button">
                <Button variant="contained" component="span" className={classes.csvButton}>
                  Upload csv file
                  <CloudUploadIcon className={classes.rightIcon} />
                </Button>
              </label>
            </form>
          </Grid>

          <Grid item xs={12} md={12} className={classes.csvReviewList}>
            <CSVReviewList csvData={users} classes={classes} />
          </Grid>

          <Grid container item xs={12} justifyContent="center">
            {importing ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" onClick={this.handleImportAction}>
                Import
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default withStyles(styles)(ImportUsers)
