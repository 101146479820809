export default function extractErrorData(err) {
  let data

  if (err.response) {
    data = err.response.data.message ? err.response.data.message : err.response.data
  } else {
    data = err
  }

  return data
}
