import React, { Component } from 'react'

import { Button, Paper, TextField, MenuItem } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import { MapHeadersToStandardFormat } from '../../helpers'

export default class CSVFieldSelectForm extends Component {
  state = {
    csvFieldsOnData: {
      Company: '', // String that notes which column from the uploaded CSV corresponds to the Company field
      'To and Cc': '', // String that notes which column from the uploaded CSV corresponds to the 'To and Cc' field
      BCc: '' // String that notes which column from the uploaded CSV corresponds to the BCc field
    }
  }

  componentDidMount() {
    const { csvFieldsOnInput } = this.props

    const mappedHeaders = MapHeadersToStandardFormat(csvFieldsOnInput)

    this.setState({ csvFieldsOnData: mappedHeaders })
  }

  handleCsvFormChange = event => {
    const { target } = event
    const { csvFieldsOnData } = this.state

    this.setState({
      csvFieldsOnData: {
        ...csvFieldsOnData,
        [target.name]: target.value
      }
    })
  }

  render() {
    const arrayOfValidFields = [
      { name: 'Company', includeNone: false },
      { name: 'To and Cc', includeNone: true },
      { name: 'BCc', includeNone: true }
    ]
    const { csvFieldsOnData } = this.state

    const { classes, handleCsvDiscard, handleCsvDone, csvFieldsOnInput } = this.props

    // Add empty option to the values supplied to the input
    // to make sure user triggers handleCsvFormChange to set a value when the MapHeadersToStandardFormat function doesn't set a value (for non-standard lists)
    const csvFieldsOnInputWithEmpty = ['', ...csvFieldsOnInput]

    return (
      <Paper className={classes.csvDetails}>
        <h4 className={classes.formHeader}>Map CSV headers to the fields below</h4>
        <small className={classes.formHelperText}>*Select 'None' to omit that field</small>

        {arrayOfValidFields.map(field => (
          <div className={classes.csvDetailFromField} key={field.name}>
            <div className={classes.csvFromLabel}>{field.name}</div>

            <p>:</p>

            <TextField
              name={field.name}
              select
              value={csvFieldsOnData[field.name]}
              onChange={this.handleCsvFormChange}
              className={classes.formSelect}
            >
              {csvFieldsOnInputWithEmpty.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}

              {field.includeNone && (
                <MenuItem key="None" value="None">
                  None
                </MenuItem>
              )}
            </TextField>
          </div>
        ))}

        <div className={classes.csvFormActions}>
          <div style={{ display: 'inline-block' }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DoneIcon />}
              onClick={() => {
                handleCsvDone(csvFieldsOnData)
              }}
            >
              Done
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              className={classes.csvFromButton}
              startIcon={<ClearIcon />}
              onClick={handleCsvDiscard}
            >
              Discard
            </Button>
          </div>
        </div>
      </Paper>
    )
  }
}
