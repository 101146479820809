import React from 'react'

import { ButtonGroup, Button, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import RestoreIcon from '@material-ui/icons/Restore'
import DestroyIcon from '@material-ui/icons/DeleteForever'
import FileCopy from '@material-ui/icons/FileCopy';
import AddUsers from '@material-ui/icons/GroupAdd';
import Download from '@material-ui/icons/GetApp';
import DownloadSimpleList from '@material-ui/icons/List';

import { PermissibleRender } from '../utilities'

export default function AdminButtons({
  userRole, // String of the users Role
  classes, // CSS Classes passed from parent component
  item,
  handleAddClick, // Function to handle clicking the add button
  handleEditClick, // Function to handle clicking the edit button
  handleRemoveClick, // Function to handle clicking the remove button
  handleDeleteClick, // Function to handle clicking the delete forever button
  handleDuplicateClick, // duplicate client profile
  handleAddUsersClick,
  handleDownloadClick,
  handleDownloadSimpleListClick
}) {
  return (
    <PermissibleRender userPermission={userRole} requiredPermissions={['dev', 'editor', 'member', 'remoteMember', 'remoteMailing']}>
      <div className={classes.adminButtons}>
        {handleDownloadClick && (
          <Tooltip title="Download mailing list as CSV">
            <Button onClick={handleDownloadClick} color="inherit" className={classes.adminButton}>
              <Download />
            </Button>
          </Tooltip>
        )}

        {handleDownloadSimpleListClick && (
          <Tooltip title="Download simple subscriber list as CSV">
            <Button onClick={handleDownloadSimpleListClick} color="inherit" className={classes.adminButton}>
              <DownloadSimpleList />
            </Button>
          </Tooltip>
        )}

        <PermissibleRender userPermission={userRole} requiredPermissions={['dev', 'editor', 'remoteMember']}>
          {handleAddClick && (
            <Tooltip title="Add">
              <Button onClick={handleAddClick} color="inherit" className={classes.adminButton}>
                <AddIcon />
              </Button>
            </Tooltip>
          )}

          {handleDuplicateClick && (
            <Tooltip title="Duplicate">
              <Button onClick={handleDuplicateClick} color="inherit" className={classes.adminButton}>
                <FileCopy />
              </Button>
            </Tooltip>
          )}

          {handleEditClick && (
            <Tooltip title="Edit">
              <Button onClick={handleEditClick} color="inherit" className={classes.adminButton}>
                <EditIcon />
              </Button>
            </Tooltip>
          )}

          {handleAddUsersClick && (
            <Tooltip title="Add Users">
              <Button onClick={handleAddUsersClick} color="inherit" className={classes.adminButton}>
                <AddUsers />
              </Button>
            </Tooltip>
          )}

          {handleRemoveClick && (
            <Tooltip title={item && item.deletedAt ? 'Restore' : 'Remove Temporarily'}>
              <Button onClick={handleRemoveClick} color="inherit" className={classes.adminButton}>
                {item && item.deletedAt ? <RestoreIcon /> : <DeleteIcon />}
              </Button>
            </Tooltip>
          )}

          {// only show 'delete forever' button if item is temporarily removed
            handleDeleteClick && (item && item.deletedAt) && (
              <Tooltip title="Delete Forever">
                <Button onClick={handleDeleteClick} color="inherit">
                  <DestroyIcon />
                </Button>
              </Tooltip>
            )}
        </PermissibleRender>
      </div>
    </PermissibleRender>
  )
}
