import React, { useEffect, useContext } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'

import { withStyles, Button, Grid } from '@material-ui/core'

import { validationSchema, initialFormData } from './formSetup'

import { formStyles } from '../../../styles'
import { SingleLineTextField, MultiLineTextField, SelectField } from '../../blocks'

import databaseQueryInterface from '../../../database/databaseQueryInterface'
import settings from '../../../settings'
import { FunctionContext } from '../../contexts'
import { extractErrorData, formatDataForAutocomplete } from '../../helpers'
import { mapCurrentValuesToForm } from '../../lib/product'

const styles = theme => ({ ...formStyles(theme) })

const ProductForm = ({ classes, user, handleSubmit, currentValues }) => {
  const [allCategories, setAllCategories] = React.useState(null)
  const [allSubCategories, setAllSubCategories] = React.useState(null)
  const [initialValues, setInitialValues] = React.useState(initialFormData)

  const { openDialog } = useContext(FunctionContext)

  // Function to get the list of subcategories based on the category selected
  // Used to populate Subcategory field on the form, after a category is selected
  const getSubcategories = categoryId => {
    const category = _.find(allCategories, ['value', categoryId])

    return formatDataForAutocomplete(category.subcategories)
  }

  // Effect to load the list of Categories to populate parent Category field
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    // useEffect can only return a function (not an async function),
    // So, we create an async function and call it immediately to avoid errors
    async function fetchCategoriesList() {
      try {
        const data = await databaseQueryInterface.get(`${settings.baseURL}/api/product-categories`, user)

        const categoriesList = []

        data.forEach(category => {
          categoriesList.push({ name: category.name, value: category.id, subcategories: category.productSubcategories })
        })

        if (isMounted) setAllCategories(categoriesList)
      } catch (err) {
        const data = extractErrorData(err)
        openDialog({
          failedAction: `retrieve list of Institutions for Parent Company field`,
          data
        })
      }
    }

    fetchCategoriesList()

    return () => {
      isMounted = false
    }
  }, [openDialog, user])

  // Effect to update the form with the new values during an edit action
  useEffect(() => {
    let isMounted = true // Used to ensure that this component can unmount without triggering this effect

    function updateProductData() {
      if (currentValues) {
        let category = null

        // We wrap this in an if block, because otherwise it fires before allCategories is populated.
        if (allCategories) {
          allCategories.forEach(mappedCategory => {
            const subcategory = _.find(mappedCategory.subcategories, ['id', currentValues.productSubcategoryId])

            if (subcategory) {
              category = mappedCategory

              const subcategories = formatDataForAutocomplete(category.subcategories)

              setAllSubCategories(subcategories)
            }
          })
        }

        const currentData = mapCurrentValuesToForm(currentValues, category)

        if (isMounted) setInitialValues(currentData)
      }
    }

    updateProductData()

    return () => {
      isMounted = false
    }
  }, [allCategories, currentValues])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize // Used to allow the form to update once values are obtained from the backend (for edit actions)
      validationSchema={validationSchema}
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} md={12}>
              <SingleLineTextField
                fullWidth
                name="name"
                label="Name"
                value={formik.values.name}
                handleChange={formik.handleChange}
                touched={formik.touched.name}
                errors={formik.errors.name}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SelectField
                name="categoryId"
                fullWidth
                label="Category"
                value={formik.values.categoryId}
                handleChange={selectedOption => {
                  // selectedOption = categoryId
                  formik.setFieldValue('categoryId', selectedOption || '')
                  formik.setFieldValue('subCategoryId', '') // Reset the Subcategory field when a new category is selected
                  setAllSubCategories(selectedOption ? getSubcategories(selectedOption) : null) // populate SubCategory select field
                }}
                touched={formik.touched.categoryId}
                errors={formik.errors.categoryId}
                items={allCategories || []}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SelectField
                name="subCategoryId"
                fullWidth
                label="SubCategory"
                value={formik.values.subCategoryId}
                handleChange={selectedOption => {
                  formik.setFieldValue('subCategoryId', selectedOption || '')
                }}
                touched={formik.touched.subCategoryId}
                errors={formik.errors.subCategoryId}
                items={allSubCategories || []}
                classes={classes}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MultiLineTextField
                fullWidth
                name="description"
                label="Description"
                value={formik.values.description}
                handleChange={formik.handleChange}
                touched={formik.touched.description}
                errors={formik.errors.description}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MultiLineTextField
                fullWidth
                name="notes"
                label="Notes"
                value={formik.values.notes}
                handleChange={formik.handleChange}
                touched={formik.touched.notes}
                errors={formik.errors.notes}
              />
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <Button type="submit" variant="contained" color="primary" className={classes.actionButton}>
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default withStyles(styles)(ProductForm)
